<div class="white-box table-responsive">
    <h4>{{ isAll ? 'All Cases' : 'Filtered Cases' }}</h4>
    <div class="table-filters">
        <div class="table-count">
            {{ totalCases }} Cases
        </div>
    </div>
    <table class="table">
        <thead>
        <tr>
            <th></th>
            <th>Case No</th>
            <th>Patient ID</th>
            <th>Opened</th>
            <th>Resolved</th>
            <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let case of cases" (click)="selectCase(case.id)" class="case-table-row">
            <td>
                <div class="members">
                    <div class="image profile-avatar member-avatar avatar-front"
                         *ngIf="case.recipient_branch.image; else recipientElse">
                        <span class="profile-img" [style.backgroundImage]="case.recipient_branch.image | attachment: 'profile_small' | backgroundImage"></span>
                    </div>
                    <div class="image profile-avatar member-avatar avatar-back"
                         *ngIf="case.sender_branch.image; else senderElse">
                        <span class="profile-img" [style.backgroundImage]="case.sender_branch.image | attachment: 'profile_small' | backgroundImage"></span>
                    </div>
                    <ng-template #recipientElse>
                        <div class="member-avatar profile-avatar-fallback avatar-front">
                            {{ case.recipient_branch.initials }}
                        </div>
                    </ng-template>
                    <ng-template #senderElse>
                        <div class="member-avatar profile-avatar-fallback avatar-back">
                            {{ case.sender_branch.initials }}
                        </div>
                    </ng-template>
                </div>
            </td>
            <td>{{ case.case_number }}</td>
            <td>{{ case.patient_id }}</td>
            <td>
                <ng-container *ngIf="case.opened_at">
                    {{ case.opened_at | dateFns: 'dd LLL y @ HH:mm' }}
                </ng-container>
                <ng-container *ngIf="!case.opened_at">
                    -
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="case.resolved_at">
                    {{ case.resolved_at | dateFns: 'dd LLL y @ HH:mm' }}
                </ng-container>
                <ng-container *ngIf="!case.resolved_at">
                    -
                </ng-container>
            </td>
            <td>
                <div class="status-pill case-status" [ngClass]="case.status.replace(' ', '_')">
                    {{ case.status }}
                </div>
<!--                <div class="status-pill resolved margin-right-10" *ngIf="case.opened_at === null">-->
<!--                    Not Opened-->
<!--                </div>-->
<!--                <div class="status-pill resolved margin-right-10" *ngIf="case.resolved_at === null">-->
<!--                    Not Resolved-->
<!--                </div>-->
            </td>
        </tr>
        </tbody>
    </table>
    <ng-content></ng-content>
</div>
