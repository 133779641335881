import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseResolvedEvent as ApiCaseResolvedEvent } from '@scaffold/mediccoms-api-client/models/case-resolved-event';

export class CaseResolvedEvent extends CaseEvent {
    type: string;
    resolvedAt: string;
    resolvedBy: User;

    public static mapFromApi(data: Partial<ApiCaseResolvedEvent>): Partial<CaseResolvedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            resolvedAt: data.resolved_at,
            // resolvedBy: User.createOne(data.resolved_by as any),
            resolvedBy: User.mapFromApi(data.resolved_by) as User,
        };
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            resolvedAt: this.resolvedAt,
            resolvedBy: this.resolvedBy ? this.resolvedBy.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<CaseResolvedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.resolvedAt !== undefined) {
            this.resolvedAt = data.resolvedAt;
        }
        if (data.resolvedBy !== undefined) {
            this.resolvedBy = data.resolvedBy;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public fillFromApi(data: Partial<ApiCaseResolvedEvent>): this {
        this.fill(CaseResolvedEvent.mapFromApi(data));
        return this;
    }

    public get sentBy(): User {
        return this.resolvedBy;
    }
}
