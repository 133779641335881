<app-header></app-header>

<div class="container-fluid">
    <div class="row">

        <app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

        <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
            <ng-container *ngIf="!isReady">
                Loading...
            </ng-container>
            <ng-container *ngIf="isReady">
                <app-breadcrumb>
                    <ng-container *ngIf="organisationId">
                        <li class="breadcrumb-item" (click)="goToPage('organisations')"
                            *can="'view'; target:'organisations'">
                            Organisations
                        </li>
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId)">
                            {{ organisation.name }}
                        </li>
                    </ng-container>
                    <ng-container *ngIf="branchId">
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId + '/branches')">
                            Locations
                        </li>
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
                            {{ branch.name }}
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!branchId && !organisationId">
                        <li class="breadcrumb-item">Members</li>
                    </ng-container>
                    <li class="breadcrumb-item active" aria-current="page">Create a Member</li>
                </app-breadcrumb>
                <header class="d-block">
                    <h1 class="h1">Create a Member</h1>
                </header>

                <app-flash-messages></app-flash-messages>

                <div class="row">
                    <div class="col-xl-5">
                        <div class="profile-options white-box">
                            <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="image">
                                <span class="add-icon">
                                    <img src="assets/icon/purple-edit-icon.svg" />
                                </span>
                                <span class="profile-img" [style.backgroundImage]="image | attachment: 'profile_small' | backgroundImage"></span>
                            </div>
                            <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar profile-avatar-fallback large"
                                 *ngIf="!image">
                                <span class="add-icon">
                                    <img src="assets/icon/purple-add-icon.svg" />
                                </span>
                                <img class="profile-img " src="/assets/icon/upload-photo-icon.svg">
                            </div>
                            <form class="base-form">
                                <input type="hidden" [(ngModel)]="userAssignCases" name="assign_cases">
                                <input type="hidden" [(ngModel)]="userViewFullCase" name="view_full_case">

                                <div class="form-group">
                                    <label for="user_title">Title</label>
                                    <input type="text" class="form-control" id="user_title"
                                           aria-describedby="user_title" placeholder="Enter Title"
                                           [(ngModel)]="userTitle" name="user_title">
                                </div>

                                <div class="form-group">
                                    <label for="user_first_name">First Name</label>
                                    <input type="text" class="form-control" id="user_first_name"
                                           aria-describedby="user_first_name" placeholder="Enter First Name"
                                           [(ngModel)]="userFirstName" name="user_first_name">
                                </div>

                                <div class="form-group">
                                    <label for="user_last_name">Last Name</label>
                                    <input type="text" class="form-control" id="user_last_name"
                                           aria-describedby="user_last_name" placeholder="Enter First Name"
                                           [(ngModel)]="userLastName" name="user_last_name">
                                </div>

                                <div class="form-group">
                                    <label for="user_email">Email</label>
                                    <input type="text" class="form-control" id="user_email"
                                           aria-describedby="user_email" placeholder="Enter Email"
                                           [(ngModel)]="userEmail" name="user_email">
                                </div>

                                <div class="form-group">
                                    <label for="user_contact_number">Mobile Number (MFA)</label>
                                    <input type="text" class="form-control" id="user_contact_number"
                                           aria-describedby="user_contact_number" placeholder="Enter Mobile Number"
                                           [(ngModel)]="userPhone" name="user_contact_number">
                                </div>

                                <div class="form-group">
                                    <label for="user_job_title">Job Title</label>
                                    <input type="text" class="form-control" id="user_job_title"
                                           aria-describedby="user_job_title" placeholder="Enter Job Title"
                                           [(ngModel)]="userJobTitle" name="user_job_title">
                                </div>

                                <div class="form-group">
                                    <label for="user_role">Role</label>
                                    <select class="form-control" id="user_role"
                                            [(ngModel)]="userRole"
                                            [ngModelOptions]="{ standalone: true }">
                                        <option disabled [value]="null">Select Role</option>
                                        <option *ngFor="let role of userRoles"
                                                [value]="role.key">{{ role.name }}</option>
                                    </select>
                                </div>

                                <ng-container *ngIf="isEndUser()">
                                    <ng-container *can="'view'; target:'organisations'">
                                        <div class="form-group">
                                            <label for="user_organisation">Organisation</label>
                                            <select [disabled]="isMedicComs()" class="form-control"
                                                    id="user_organisation" [(ngModel)]="userOrganisation"
                                                    [ngModelOptions]="{ standalone: true }">
                                                <option disabled [value]="null">Select Organisation</option>
                                                <option *ngFor="let organisation of organisations"
                                                        [value]="organisation.id">{{ organisation.name }}</option>
                                            </select>
                                        </div>
                                    </ng-container>

                                    <div class="form-group">
                                        <label for="user_branch-">Locations (* must be a member of at least one location)</label>
                                        <select [disabled]="!userOrganisation || !!branchId" class="form-control"
                                                id="user_branch-" [(ngModel)]="userBranch" multiple="true"
                                                [ngModelOptions]="{ standalone: true }">
                                            <option disabled [value]="null">Select Location</option>
                                            <option *ngFor="let branch of branches"
                                                    [value]="branch.id">{{ branch.name }}</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="user_branch">Locations (* must be a member of at least one location)</label>
                                        <ng-select [disabled]="!userOrganisation && !!branchId" [(ngModel)]="userBranch"
                                                   id="user_branch" [ngModelOptions]="{ standalone: true }" [multiple]="true"
                                                   placeholder="Select Location / Locations">
                                            <ng-option *ngFor="let branch of branches" [value]="branch.id">{{branch.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </ng-container>
                            </form>
                        </div>
                        <app-button (click)="createMember()" type="submit" class="active remove-member small">Create
                        </app-button>
                    </div>
                    <div class="col-xl-7">

                        <div class="user-preferences white-box">
                            <h2>
                                User Preferences
                            </h2>
                            <form class="base-form">
                                <div class="form-group-inline" *ngIf="isEndUser()">
                                    <label for="user_assign_case">Ability to assign cases</label>
                                    <div class="form-switch">
                                        <input type="checkbox" id="user_assign_case" (click)="toggleAssignCases()">
                                        <span class="slider round"></span>
                                    </div>
                                </div>
                                <div class="form-group-inline" *ngIf="isEndUser()">
                                    <label for="user_view_full_case">Ability to view case information</label>
                                    <div class="form-switch">
                                        <input type="checkbox" id="user_view_full_case" (click)="toggleViewFullCase()">
                                        <span class="slider round"></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
        </main>

    </div>
</div>

<app-modal modalTitle="Add User Image" class="modal fade" id="add-photo" tabindex="-1" role="dialog"
           aria-labelledby="add-photo" aria-hidden="true">
    <div class="modal-body image-upload-body">
        <img src="/assets/icon/drop-photos-here-icon.svg">
        <input #imageInput type="file" name="logo" accept="image/*" (change)="onImageAdded()">
        <div class="sep-text">
            or
        </div>
    </div>
    <div class="modal-footer">
        <button #closeModal style="display:none;" data-dismiss="modal">Close</button>
        <app-button class="active" (click)="clickImageUpload()">Upload Photo</app-button>
    </div>
</app-modal>
