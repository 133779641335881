import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CaseConversation } from '../../models/case-conversation';
import { CaseService } from '../../pages/cases/cases.service';
import { CaseEvent } from '../../models/case-conversation-events/case-event';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-case-messenger',
    templateUrl: './case-messenger.component.html',
    styleUrls: ['./case-messenger.component.scss']
})
export class CaseMessengerComponent implements OnInit, OnDestroy {

    @Input() conversation: CaseConversation;

    public events: CaseEvent[] = [];
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private caseService: CaseService,
    ) {
    }

    public ngOnInit(): void {
        this.caseService.getEvents().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(events => this.events = events);

        this.caseService.fetchAllEvents(this.conversation.id);
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

}
