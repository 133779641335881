<div class="page-centered">
	<form class="form">
		<div class="logo">
			<img src="/assets/icon/medic-coms-logo.svg" alt="medic-coms-logo" />
		</div>
		<div *ngIf="authType === 'login'" class="form-title">
			Log In
		</div>
		<div *ngIf="authType === 'mfa'" class="form-title">
			MFA
		</div>
		<div *ngIf="authType === 'new-password'" class="form-title">
			Change Temporary Password
		</div>
		<ng-container *ngIf="authType === 'login'">
			<div class="form-group">
				<label>Email</label>
				<div class="form-input" [ngClass]="{'error': hasError}">
					<img src="/assets/icon/member.svg" />
					<input (keyup.enter)="loginAction()" type="email" name="username" [(ngModel)]="username">
				</div>
			</div>
			<div class="form-group">
				<label>Password</label>
				<div class="form-input" [ngClass]="{'error': hasError}">
					<img src="/assets/icon/lock.svg" />
					<input (keyup.enter)="loginAction()" type="password" name="password" [(ngModel)]="password">
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="authType === 'new-password'">
			<div class="form-group">
				<label>New Password</label>
				<div class="form-input" [ngClass]="{'error': hasError}">
					<img src="/assets/icon/lock.svg" />
					<input (keyup.enter)="loginAction()" type="password" name="newPassword" [(ngModel)]="newPassword">
				</div>
			</div>
			<div class="form-group">
				<label>New Password Again</label>
				<div class="form-input" [ngClass]="{'error': hasError}">
					<img src="/assets/icon/lock.svg" />
					<input (keyup.enter)="loginAction()" type="password" name="newPasswordAgain" [(ngModel)]="newPasswordAgain">
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="authType === 'mfa'">
			<div class="form-group">
				<label>MFA Code</label>
				<div class="form-input" [ngClass]="{'error': hasError}">
					<img src="/assets/icon/lock.svg" />
					<input (keyup.enter)="loginAction()" type="mfaCode" name="mfaCode" [(ngModel)]="mfaCode">
				</div>
			</div>
		</ng-container>

		<div class="authentication-message">
			<span *ngFor="let error of errors">{{ error }}</span>
		</div>

		<div class="form-submit">
			<app-button class="active" [class.grey]="isLoading" (click)="loginAction()">{{ authType === 'login' ? 'Login' : 'Submit' }}</app-button>
		</div>
		<div *ngIf="authType === 'login'" class="forgot-password" [routerLink]="['/forgot-password']">
			Forgot Password?
		</div>
		<div *ngIf="authType !== 'login'" class="forgot-password" (click)="cancelLogin()">
			Cancel
		</div>
	</form>
</div>
