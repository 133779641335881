import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateUserRequest } from '@scaffold/mediccoms-api-client/requests';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Branch } from '../../models/branch';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BranchesService } from '../branches/branches.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { Organisation } from '../../models/organisation';
import { MembersService } from '../members/members.service';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { UserRole } from '../members/members';
import { FlashMessageService } from '../../services/flash-message.service';
import { MenuService } from '../../services/menu.service';
import { AttachmentService } from '../../services/attachment.service';


@Component({
    selector: 'app-add-member',
    templateUrl: './add-member.html',
    styleUrls: ['./add-member.scss']
})
export class AddMemberPage implements OnInit, OnDestroy, AfterViewInit {

    public branches: Branch[] = [];
    public organisations: Organisation[] = [];
    public userOrganisationID: string = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;

    public organisation: Organisation = null;
    public branch: Branch = null;
    public organisationId: string = null;
    public branchId: string = null;

    private currentUser: User = null;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    public userAssignCases = false;
    public userViewFullCase = false;
    public userTitle: string = null;
    private _userRole: string = null;
    public userFirstName: string = null;
    public userLastName: string = null;
    public userEmail: string = null;
    public userPhone: string = null;
    public userJobTitle: string = null;
    public _userOrganisation: string = null;
    public userBranch: string[] = null;
    public image: string = null;

    public notificationVisible = false;
    public notificationType: string = null;
    public notificationClass: string = null;

    private actionSheet: any;

    constructor(
        private api: ApiClientService,
        private attachmentService: AttachmentService,
        private branchService: BranchesService,
        private flashMessageService: FlashMessageService,
        private memberService: MembersService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
        this.route.queryParams.subscribe(params => {
            this.notificationType = params['notificationStatus'];
        });
    }

    public ngOnInit(): void {
        this.menuService.setActive('members');

        this.organisationService.getOrganisations().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisations => this.organisations = organisations);

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => {
            this.organisations = [organisation];
            this.organisation = organisation;
        });

        this.branchService.getBranches().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branches => this.branches = branches);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => {
            this.branches = [branch];
            this.branch = branch;
        });

        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                if (this.currentUser.organisation_id) {
                    this.userOrganisation = this.currentUser.organisation_id;
                }
            }
        });

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id') && params.hasOwnProperty('branch_id')) {
                const organisationId = params.organisation_id;
                const branchId = params.branch_id;
                this.organisationId = organisationId;
                this.userOrganisation = organisationId;
                this.branchId = branchId;
                this.userBranch = branchId;
                this.organisationService.fetchOrganisation(organisationId);
                this.branchService.fetchBranch(organisationId, branchId);
            } else if (params.hasOwnProperty('organisation_id')) {
                const organisationId = params.organisation_id;
                this.organisationId = organisationId;
                this.userOrganisation = organisationId;
                this.organisationService.fetchOrganisation(organisationId);
            } else {
                this.organisationService.fetchOrganisations();
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public get isReady() {
        if (this.branchId && this.organisationId) {
            return !!this.branch && !!this.organisation;
        } else if (this.organisationId) {
            return !!this.organisation;
        } else {
            return true;
        }
    }

    public get userOrganisation(): string {
        return this._userOrganisation;
    }

    public set userOrganisation(value: string) {
        if (value !== this.userOrganisation) {
            this._userOrganisation = value;
            this.fetchBranches(this.userOrganisation);
        }
    }

    private fetchBranches(organisationId: string) {
        this.branches = [];
        this.userBranch = null;
        this.branchService.fetchBranches(organisationId);
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

    // public ngOnDestroy(): void {
    // 	this.isDestroyed.next();
    // }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.image = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }


    public toggleAssignCases() {
        this.userAssignCases = !this.userAssignCases;
    }

    public toggleViewFullCase() {
        this.userViewFullCase = !this.userViewFullCase;
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public async createMember() {
        const request: CreateUserRequest = {
            title: this.userTitle,
            first_name: this.userFirstName,
            last_name: this.userLastName,
            job_title: this.userJobTitle,
            role: this.userRole,
            email: this.userEmail,
            phone_number: this.userPhone,
        };

        if (this.image) {
            request.image = this.image;
        }

        if (!this.isMedicComs()) {
            request.has_full_case_access = this.userViewFullCase;
            request.can_assign_cases = this.userAssignCases;
            request.organisation_id = this.userOrganisation;
            if (this.userBranch) {
                request.branches = this.userBranch;
            }
        }

        try {
            const response = await this.userService.createMember(request);
            if (response.user) {

                this.flashMessageService.addMessage('success', 'Member successfully created!');
                this.router.navigate(['/members/' + response.user.id]);

            } else {
                // this.showError();
                this.flashMessageService.showMessage('error', 'An Error Occurred.');
            }
        } catch (e) {
            // this.showError();
            this.flashMessageService.showMessage('error', 'An Error Occurred.');
        }
    }

    public get selectedRole(): UserRole {
        return this.userRoles.find(role => role.key === this.userRole);
    }

    public isMedicComs(): boolean {
        if (this.selectedRole?.level >= 40) {
            return true;
        }
        return false;
    }

    public get userRoles(): UserRole[] {
        const roles: UserRole[] = [];

        if (this.currentUser) {
            for (const role of environment.user_roles) {
                if (this.currentUser.can('seeUserRole', role)) {
                    if ((this.organisationId && role.level < 40)) {
                        roles.push(role);
                    } else if (!this.organisationId) {
                        roles.push(role);
                    }
                }
            }
        }

        return roles;
    }

    public get userRole(): string {
        return this._userRole;
    }

    public set userRole(userRole: string) {
        this._userRole = userRole;
    }

    public isEndUser() {
        return this.selectedRole && !this.isMedicComs();
    }
}
