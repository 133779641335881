import { Injectable } from '@angular/core';
import { FlashMessage } from '../models/flash-message';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FlashMessageService {

    private messages: FlashMessage[] = [];
    private messagesSubject: Subject<FlashMessage> = new Subject<FlashMessage>();
    private clearMessagesSubject: Subject<void> = new Subject<void>();

    constructor() {
    }

    public addMessage(type: 'success'|'error', message: string): void {
        window.scroll(0, 0);
        this.messages.push(FlashMessage.createOne({
            type,
            message,
        }));
    }

    public showMessage(type: 'success'|'error', message: string) {
        window.scroll(0, 0);
        this.clearMessagesSubject.next();
        this.messagesSubject.next(FlashMessage.createOne({
            type,
            message,
        }));
    }

    public getMessages(): void {
        for (const message of this.messages) {
            this.messagesSubject.next(message);
        }
        this.messages = [];
    }

    public getMessagesObservable(): Observable<FlashMessage> {
        for (const message of this.messages) {
            this.messagesSubject.next(message);
        }
        return this.messagesSubject.asObservable();
    }

    public getClearMessagesObservable(): Observable<void> {
        return this.clearMessagesSubject.asObservable();
    }

    public clearMessages(): void {
        this.messages = [];
        this.clearMessagesSubject.next();
    }
}
