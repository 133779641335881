import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlashMessageService } from '../../services/flash-message.service';
import { FlashMessage } from '../../models/flash-message';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-flash-messages',
    templateUrl: './flash-messages.component.html',
    styleUrls: ['./flash-messages.component.scss']
})
export class FlashMessagesComponent implements OnInit, OnDestroy {

    public messages: FlashMessage[] = [];
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private flashMessageService: FlashMessageService,
    ) {
    }

    public ngOnInit(): void {
        this.flashMessageService.getMessagesObservable().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(message => {
            this.messages.push(message);
        });
        this.flashMessageService.getClearMessagesObservable().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => this.clearMessages());

        this.flashMessageService.getMessages();
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public clearMessages(): void {
        this.messages = [];
    }
}
