import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models/user';
import { ReportParameters } from '../../pages/cases/cases.service';

@Component({
    selector: 'app-report-filters',
    templateUrl: './report-filters.component.html',
    styleUrls: ['./report-filters.component.scss']
})
export class ReportFiltersComponent implements OnInit {

    constructor() {
    }

    @Input() users: User[] = null;
    public status: 'all' | 'opened' | 'resolved' = 'all';
    public employeeId: string = null;
    public patientId: string = null;
    @Output() reportParameters: EventEmitter<ReportParameters> = new EventEmitter<ReportParameters>();

    public afterDate: string = null;
    public afterTime: string = null;
    public beforeDate: string = null;
    public beforeTime: string = null;

    ngOnInit(): void {
    }

    public selectStatus(status: 'all' | 'opened' | 'resolved'): void {
        this.status = status;
    }

    public runReport(): void {
        const values: ReportParameters = {
            caseStatus: this.status,
            userId: this.employeeId,
            patientId: this.patientId,
        };
        if (this.beforeTimestamp) {
            values.before = this.beforeTimestamp;
        }
        if (this.afterTimestamp) {
            values.after = this.afterTimestamp;
        }
        this.reportParameters.emit(values);
    }

    public isChecked(status: string): boolean {
        return status === this.status;
    }

    private get beforeTimestamp(): string {
        if (!this.beforeDate && !this.beforeTime) {
            return null;
        }
        if (this.beforeDate && this.beforeTime) {
            return new Date(Date.parse(`${this.beforeDate} ${this.beforeTime}`)).toISOString();
        }
        if (this.beforeDate) {
            return new Date(Date.parse(`${this.beforeDate}`)).toISOString();
        }
        if (this.beforeTime) {
            return new Date(Date.parse(`${this.beforeTime}`)).toISOString();
        }
    }

    private get afterTimestamp(): string {
        if (!this.afterDate && !this.afterTime) {
            return null;
        }
        if (this.afterDate && this.afterTime) {
            return new Date(Date.parse(`${this.afterDate} ${this.afterTime}`)).toISOString();
        }
        if (this.afterDate) {
            return new Date(Date.parse(`${this.afterDate}`)).toISOString();
        }
        if (this.afterTime) {
            return new Date(Date.parse(`${this.afterTime}`)).toISOString();
        }
    }
}
