import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BranchesService } from '../branches/branches.service';
import { MenuService } from '../../services/menu.service';
import { Organisation } from '../../models/organisation';
import { Branch } from '../../models/branch';
import { ConnectionService } from '../connections/connections.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { Connection } from '../../models/connection';
import { FlashMessageService } from '../../services/flash-message.service';

@Component({
    selector: 'app-connection',
    templateUrl: './connection.html',
    styleUrls: ['./connection.scss']
})
export class ConnectionPage implements OnInit, OnDestroy {

    public organisationId: string = null;
    public organisation: Organisation = null;
    public branchId: string = null;
    public branch: Branch = null;
    public connectionId: string = null;
    public connection: Connection = null;

    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private branchService: BranchesService,
        private connectionService: ConnectionService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public async removeConnection() {
        try {
            const response = await this.connectionService.removeConnection(this.connectionId);
            if (response.deleted) {
                this.flashMessageService.addMessage('success', `The connection with ${this.connection.branch.name} has been removed.`);
                if (this.branch) {
                    this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId]);
                } else {
                    this.router.navigate(['organisations', this.organisationId, 'connections']);
                }
            } else {
                throw new Error();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred removing this connection.');
        }
    }

    public ngOnInit(): void {
        this.menuService.setActive('connections');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.connectionService.getConnection().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(connection => this.connection = connection);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(this.organisationId);

                if (params.hasOwnProperty('branch_id')) {
                    this.branchId = params.branch_id;
                    this.branchService.fetchBranch(this.organisationId, this.branchId);
                }

                if (params.hasOwnProperty('connection_id')) {
                    this.connectionId = params.connection_id;
                    this.connectionService.fetchConnection(this.connectionId);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

}
