import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BranchesService } from '../branches/branches.service';
import { MenuService } from '../../services/menu.service';
import { Organisation } from '../../models/organisation';
import { Branch } from '../../models/branch';
import { ConnectionService } from '../connections/connections.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { Invite } from '../../models/invite';
import { FlashMessageService } from '../../services/flash-message.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';

@Component({
    selector: 'app-invite',
    templateUrl: './invite.html',
    styleUrls: ['./invite.scss']
})
export class InvitePage implements OnInit, OnDestroy {

    @ViewChild('errorModal', {static : true}) errorModal: ElementRef;

    public organisationId: string = null;
    public organisation: Organisation = null;
    public branchId: string = null;
    public branch: Branch = null;
    public inviteId: string = null;
    public token: string = null;
    public initiatingOrganisation: Organisation = null;
    public initiatingBranch: Branch = null;
    public branches: Branch[] = null;
    public branchesToConnect: string[] = null;
    private isDestroyed: Subject<void> = new Subject<void>();
    private currentUser: User = null;

    constructor(
        private branchService: BranchesService,
        private connectionService: ConnectionService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.menuService.setActive('connections');

        this.userService.getMe()
            .pipe(takeUntil(this.isDestroyed))
            .subscribe(me => {
                this.currentUser = me;
                if (!this.organisationId && this.inviteId && this.token) {
                    this.router.navigate(['organisations', this.currentUser.organisation_id, 'connections', 'invites', this.inviteId, this.token]);
                }
            });

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.connectionService.getInitiatingOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.initiatingOrganisation = organisation);

        this.connectionService.getInitiatingBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => {
            if (branch.organisation_id === this.organisationId) {
                this.flashMessageService.addMessage('error', 'Your cannot view this invite.');
                if (this.branch) {
                    this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId]);
                } else {
                    this.router.navigate(['organisations', this.organisationId, 'connections']);
                }
            } else {
                this.initiatingBranch = branch;
            }
        });

        this.connectionService.getInviteRejected().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => {
            this.flashMessageService.addMessage('success', 'Connection Rejected');
            if (this.branch) {
                this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId]);
            } else {
                this.router.navigate(['organisations', this.organisationId, 'connections']);
            }
        });

        this.connectionService.getInviteAccepted().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => {
            this.flashMessageService.addMessage('success', 'Connection Accepted');
            if (this.branch) {
                this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId]);
            } else {
                this.router.navigate(['organisations', this.organisationId, 'connections']);
            }
        });

        this.branchService.getAllBranches().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branches => this.branches = branches);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(this.organisationId);

                this.branchService.fetchAllBranches(this.organisationId);

                if (params.hasOwnProperty('branch_id')) {
                    this.branchId = params.organisation_id;
                    this.branchService.fetchBranch(this.organisationId, this.branchId);
                }

                if (params.hasOwnProperty('invite_id') && params.hasOwnProperty('token')) {
                    this.inviteId = params.invite_id;
                    this.token = params.token;
                    this.connectionService.fetchInvite(this.inviteId, this.token);
                } else if (params.hasOwnProperty('invite_id')) {
                    this.inviteId = params.invite_id;
                    this.connectionService.fetchInvite(this.inviteId);
                }
            } else if (params.hasOwnProperty('invite_id') && params.hasOwnProperty('token')) {
                this.inviteId = params.invite_id;
                this.token = params.token;

                // get current user organisation and redirect
                if (this.currentUser && this.currentUser.organisation_id) {
                    this.router.navigate(['organisations', this.currentUser.organisation_id, 'connections', 'invites', this.inviteId, this.token]);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public rejectInvite() {
        this.connectionService.rejectInvite(this.inviteId, this.token);
    }

    public acceptInvite() {
        if (this.branchesToConnect === null) {
            this.errorModal.nativeElement.click();
        }

        this.connectionService.acceptInvite(this.inviteId, this.branchesToConnect, this.token);
    }

    public get uniqueBranches(): Branch[] {
        const branches: Branch[] = [];

        for (const branch of this.branches) {
            if (!branches.some(item => item.id === branch.id)) {
                branches.push(branch);
            }
        }

        return branches;
    }

}
