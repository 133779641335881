/* tslint:disable:variable-name curly */
import { AbstractModel } from './abstract.model';
import { PrimaryContact } from './primary-contact';
import { Address } from './address';

export class Organisation extends AbstractModel {
    public name: string = null;
    public id: string = null;
    public address: Address = null;
    public enabled: boolean;
    public cases_enabled: boolean;
    public messaging_enabled: boolean;
    public image: string = null;
    public primary_contact: PrimaryContact = null;

    public fill(data: Partial<Organisation>) {
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.enabled) {
            this.enabled = data.enabled;
        }
        if (data.cases_enabled) {
            this.cases_enabled = data.cases_enabled;
        }
        if (data.messaging_enabled) {
            this.messaging_enabled = data.messaging_enabled;
        }
        if (data.image) {
            this.image = data.image;
        }
        if (data.primary_contact !== undefined) {
            this.primary_contact = PrimaryContact.createOne(data.primary_contact);
        }
        if (data.address !== undefined) {
            this.address = Address.createOne(data.address);
        }
    }

    public get initials(): string {
        const letters = (() => {
            if (!this.name) { return []; }

            const capitalLetters = this.name.split('').filter(letter => {
                const charCode = letter.charCodeAt(0);
                return charCode >= 65 && charCode <= 90;
            });

            if (capitalLetters.length) { return capitalLetters; }

            const words = this.name.split(' ');

            if (words.length) { return words; }
        })();

        return letters.length ? letters.slice(0, 2).join('') : 'N/A';
    }
}
