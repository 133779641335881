import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthResponse, CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginPage implements OnInit, OnDestroy {

    public username: string = null;
    public password: string = null;
    public newPassword: string = null;
    public newPasswordAgain: string = null;
    public mfaCode: string = null;
    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;
    public authType: 'login' | 'mfa' | 'new-password' = 'login';
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private authService: CognitoAuthenticationService,
        private router: Router,
    ) {
        this.authService.getLogout().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(() => {
            this.authType = 'login';
            console.log('getLogout');
            this.addError('You are not permitted to access this service.');
            this.isLoading = false;
        });
    }

    public ngOnInit(): void {
        this.isLoading = false;
        this.clearFormValues();
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }
    public cancelLogin() {
        this.authType = 'login';
    }

    private clearFormValues() {
        this.username = '';
        this.password = '';
        this.newPassword = '';
        this.newPasswordAgain = '';
        this.mfaCode = '';
    }

    private addError(error: string) {
        this.errors = [error];
        this.hasError = true;
    }

    private clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    private respondToAuth(authResponse: AuthResponse) {
        if (authResponse.loggedIn) {
            return setTimeout(() => {
                return this.router.navigate(['dashboard']).then(() => this.clearFormValues());
            }, 1000);
        } else {
            switch (authResponse.challengeType) {
                case 'new-password':
                    this.authType = 'new-password';
                    this.isLoading = false;
                    break;
                case 'mfa':
                    this.authType = 'mfa';
                    this.isLoading = false;
                    break;
                default:
                    this.addError('Invalid Authorisation Response');
                    this.isLoading = false;
                    break;
            }
        }
    }

    private catchAuthError(error) {
        this.clearErrors();
        this.addError(error);
        this.isLoading = false;
    }

    private login() {
        this.authService.initiateAuth(this.username, this.password)
            .then((authResponse: AuthResponse) => this.respondToAuth(authResponse))
            .catch(error => this.catchAuthError(error));
    }

    private mfa() {
        this.authService.respondToMfa(this.mfaCode)
            .then((authResponse: AuthResponse) => this.respondToAuth(authResponse))
            .catch(error => this.catchAuthError(error));
    }

    private setNewPassword() {
        if (this.newPassword !== this.newPasswordAgain) {
            return;
        }
        this.authService.respondToNewPassword(this.newPassword)
            .then((authResponse: AuthResponse) => this.respondToAuth(authResponse))
            .catch(error => this.catchAuthError(error));
    }

    public loginAction() {
        if (this.isLoading) {
            return;
        }
        this.clearErrors();
        this.isLoading = true;
        switch (this.authType) {
            case 'mfa':
                this.mfa();
                break;
            case 'new-password':
                this.setNewPassword();
                break;
            case 'login':
            default:
                this.login();
                break;
        }
    }

}
