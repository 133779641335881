<div class="modal-dialog modal-dialog-centered" role="document">
	<div class="modal-content">
	  <div class="modal-header">
		<h5 class="modal-title" id="exampleModalLongTitle">{{modalTitle}}</h5>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		  <img src="/assets/icon/cancel.svg">
		</button>
	  </div>
	  <ng-content></ng-content>
	</div>
</div>
