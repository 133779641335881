import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import {BehaviorSubject, Observable} from 'rxjs';
import {Connection} from '@scaffold/mediccoms-api-client/models';

@Injectable()
export class ConnectionsService {

	private Connections = [];
	private ConnectionInvites = [];

    private connectionsChanged: BehaviorSubject<Connection> = new BehaviorSubject<Connection>(null);
	private connectionInvitesChanged: BehaviorSubject<Connection> = new BehaviorSubject<Connection>(null);

    constructor(
        private api: ApiClientService,
    ) {
    }

	public async fetchConnections(): Promise<any[]> {

		const response = await this.api.connections.all({
			page: 1,
		}).toPromise();
		this.setConnections(response);
		return response.connections;

	}

	public getAllConnections(): Observable<any> {
		this.fetchConnections();
		return this.connectionsChanged.asObservable();
	}

	public getConnectionInvites(): Observable<any> {
		this.fetchConnectionInvites();
		return this.connectionInvitesChanged.asObservable();
	}

	public setConnections(connections) {
		this.Connections = connections;
		this.connectionsChanged.next(connections);
	}

	public async fetchConnectionInvites(): Promise<any[]> {
    	// const response = await this.api.connections.allInvites().toPromise();
		// this.setConnectionInvites(response);
		// return response.invites;
		return Promise.resolve(null);
	}

	public setConnectionInvites(connectionInvites) {
		this.ConnectionInvites = connectionInvites;
		this.connectionInvitesChanged.next(connectionInvites);
	}

}
