import { Component, OnDestroy, OnInit } from '@angular/core';
import { Branch } from '../../models/branch';
import { BranchesService } from './branches.service';
import { Subject } from 'rxjs';
import { User } from '../../models/user';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Organisation } from '../../models/organisation';
import { OrganisationsService } from '../organisations/organisations.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-branches',
    templateUrl: './branches.html',
    styleUrls: ['./branches.scss']
})
export class BranchesPage implements OnInit, OnDestroy {

    private _page = 1;
    private currentUser: User = null;
    public totalPages = 0;
    public totalBranches = 0;

    private isDestroyed: Subject<void> = new Subject<void>();
    private pageChanged: Subject<number> = new Subject<number>();
    notificationType: string;

    public organisation: Organisation = null;
    public branches: Branch[] = [];

    public get page(): number {
        return this._page;
    }

    public set page(value: number) {
        if (value !== this.page) {
            this._page = value;
            this.pageChanged.next(value);
        }
    }

    constructor(
        private branchService: BranchesService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
        this.menuService.setActive(null);
    }

    public ngOnInit(): void {
        this.menuService.setActive('branches');

        this.branchService.getBranches().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(branches => this.branches = branches);

        this.branchService.getTotalBranches().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalBranches = total);

        this.branchService.getCurrentPage().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.page = currentPage);

        this.branchService.getTotalPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalPages = totalPages);

        this.pageChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.branchService.fetchBranches(this.organisation?.id, page));

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationService.fetchOrganisation(params.organisation_id);
                this.branchService.fetchBranches(params.organisation_id);
            }
        });
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

}
