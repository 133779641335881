import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-notification-bar',
    templateUrl: './notification-bar.component.html',
    styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

    @Input('notficationType') notficationType: string;

    notifications = [
        {
            name: 'member-created',
            icon: '/assets/icon/sent-email-icon.svg',
            text: 'Your new member has now been sent an email to join your organisation.'
        },
        {
            name: 'member-edited',
            icon: '/assets/icon/sent-email-icon.svg',
            text: 'This member has been successfully edited.'
        },
        {
            name: 'account-suspended',
            icon: '/assets/icon/warning-icon.svg',
            text: 'This member account has been suspended!'
        },
        {
            name: 'account-removed',
            icon: '/assets/icon/warning-icon.svg',
            text: 'Account has been removed!'
        },
        {
            name: 'password-reset-sent',
            icon: '/assets/icon/sent-email-icon.svg',
            text: 'Password reset has been sent for this user!'
        },
        {
            name: 'account-removed',
            icon: '/assets/icon/warning-icon.svg',
            text: 'Account has been removed!'
        },
        {
            name: 'error',
            icon: '/assets/icon/warning-icon.svg',
            text: 'An Error Occurred!'
        },
        {
            name: 'organisation_success',
            icon: '/assets/icon/sent-email-icon.svg',
            text: 'The organisation has been successfully created!'
        },
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

}
