import { Component } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { UserService } from './services/user.service';
import { SettingsService } from './services/settings.service';
import { CognitoAuthenticationService } from './services/cognito-authentication.service';
import { Router } from '@angular/router';
import { BranchesService } from './pages/branches/branches.service';
import { CaseService } from './pages/cases/cases.service';
import { OrganisationsService } from './pages/organisations/organisations.service';
import { Environment, ENVIRONMENTS } from './app.constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'mediccoms-admin';

    constructor(
        private api: ApiClientService,
        private authService: CognitoAuthenticationService,
        private branchService: BranchesService,
        private caseService: CaseService,
        private organisationService: OrganisationsService,
        private router: Router,
        private settings: SettingsService,
        private userService: UserService,
    ) {
        this.setEnvironmentByHost(window.location.host);
        this.api.setAuthRefreshHandler(() => this.authService.refreshTokenAuthApi());
        this.initialiseAuthHandlers();
    }

    private setEnvironmentByHost(host: string) {
        for (const id in ENVIRONMENTS) {
            const environment = ENVIRONMENTS[id];
            if (environment.host === host || environment.host === 'localhost' && host.includes('localhost')) {
                this.setEnvironment(environment);
                return;
            }
        }
        this.setEnvironment(ENVIRONMENTS.PRODUCTION);
    }

    private setEnvironment(environment: Environment) {
        this.authService.setClientId(environment.app_client_id);
        this.api.setEndpoint(environment.url);
    }

    private initialiseAuthHandlers() {
        this.authService.getToken().subscribe(async (idToken) => {
            this.api.setToken(idToken);
            await this.userService.fetchMe();
        });

        this.authService.getLogout().subscribe(async () => {
            this.branchService.clear();
            this.caseService.clear();
            this.organisationService.clear();
            this.userService.clear();

            this.router.navigate(['login']);
        });
    }
}
