import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';
import { Subject } from 'rxjs';
import { AllMessageStats, MessageService } from '../../services/message.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.html',
    styleUrls: ['./messages.scss']
})
export class MessagesPage implements OnInit, OnDestroy {

    public stats: AllMessageStats = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private menuService: MenuService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router
    ) {

    }

    public ngOnInit(): void {
        this.menuService.setActive('messages');

        this.messageService.getStats().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(stats => this.stats = stats);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                const organisationId = params.organisation_id;
                this.messageService.fetchAllStats(organisationId);
            } else {
                this.messageService.fetchAllStats();
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

}
