import { Component, Input, OnInit } from '@angular/core';
import { FlashMessage } from '../../../models/flash-message';

@Component({
    selector: 'app-flash-message',
    templateUrl: './flash-message.component.html',
    styleUrls: ['./flash-message.component.scss']
})
export class FlashMessageComponent {

    @Input() message: FlashMessage;

    constructor() {
    }

    public get icon() {
        if (this.message.type === 'success') {
            return '/assets/icon/sent-email-icon.svg';
        } else {
            return '/assets/icon/warning-icon.svg';
        }
    }

}
