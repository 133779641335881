import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Branch } from '../../models/branch';
import { Observable, Subject } from 'rxjs';
import { Connection } from '../../models/connection';
import { Invite } from '../../models/invite';
import { Organisation } from '../../models/organisation';
import { SearchConnectionInvitesRequest, SearchConnectionsRequest } from '@scaffold/mediccoms-api-client/requests';

@Injectable()
export class ConnectionService {

    public connections: Subject<Connection[]> = new Subject<Connection[]>();
    public sentInvites: Subject<any[]> = new Subject<any[]>();
    public receivedInvites: Subject<any[]> = new Subject<any[]>();
    private connection: Subject<Connection> = new Subject<Connection>();
    private initiatingOrganisation: Subject<Organisation> = new Subject<Organisation>();
    private initiatingBranch: Subject<Branch> = new Subject<Branch>();
    private inviteAccepted: Subject<any> = new Subject<any>();
    private inviteRejected: Subject<any> = new Subject<any>();

    public totalConnectionsSubject: Subject<number> = new Subject<number>();
    public currentPageSubject: Subject<number> = new Subject<number>();
    public totalPagesSubject: Subject<number> = new Subject<number>();
    private _totalConnections: number = null;
    private _currentConnectionsPage: number = null;
    private _totalConnectionsPages: number = null;

    public totalInvitesReceivedSubject: Subject<number> = new Subject<number>();
    public currentInvitesReceivedSubject: Subject<number> = new Subject<number>();
    public totalInvitesReceivedPagesSubject: Subject<number> = new Subject<number>();
    private _totalInvitesReceived: number = null;
    private _currentInvitesReceivedPage: number = null;
    private _totalInvitesReceivedPages: number = null;

    public totalInvitesSentSubject: Subject<number> = new Subject<number>();
    public currentInvitesSentSubject: Subject<number> = new Subject<number>();
    public totalInvitesSentPagesSubject: Subject<number> = new Subject<number>();
    private _totalInvitesSent: number = null;
    private _currentInvitesSentPage: number = null;
    private _totalInvitesSentPages: number = null;

    public set totalConnections(value) {
        this._totalConnections = value;
        this.totalConnectionsSubject.next(value);
    }

    public get totalConnections() {
        return this._totalConnections;
    }

    public set currentConnectionsPage(value) {
        this._currentConnectionsPage = value;
        this.currentPageSubject.next(value);
    }

    public get currentConnectionsPage() {
        return this._currentConnectionsPage;
    }

    public set totalConnectionsPages(value) {
        this._totalConnectionsPages = value;
        this.totalPagesSubject.next(value);
    }

    public get totalConnectionsPages() {
        return this._totalConnectionsPages;
    }

    public set totalInvitesReceived(value) {
        this._totalInvitesReceived = value;
        this.totalInvitesReceivedSubject.next(value);
    }

    public get totalInvitesReceived() {
        return this._totalInvitesReceived;
    }

    public set currentInvitesReceivedPage(value) {
        this._currentInvitesReceivedPage = value;
        this.currentInvitesReceivedSubject.next(value);
    }

    public get currentInvitesReceivedPage() {
        return this._currentInvitesReceivedPage;
    }

    public set totalInvitesReceivedPages(value) {
        this._totalInvitesReceivedPages = value;
        this.totalInvitesReceivedPagesSubject.next(value);
    }

    public get totalInvitesReceivedPages() {
        return this._totalInvitesReceivedPages;
    }

    public set totalInvitesSent(value) {
        this._totalInvitesSent = value;
        this.totalInvitesSentSubject.next(value);
    }

    public get totalInvitesSent() {
        return this._totalInvitesSent;
    }

    public set currentInvitesSentPage(value) {
        this._currentInvitesSentPage = value;
        this.currentInvitesSentSubject.next(value);
    }

    public get currentInvitesSentPage() {
        return this._currentInvitesSentPage;
    }

    public set totalInvitesSentPages(value) {
        this._totalInvitesSentPages = value;
        this.totalInvitesSentPagesSubject.next(value);
    }

    public get totalInvitesSentPages() {
        return this._totalInvitesSentPages;
    }

    constructor(
        private api: ApiClientService,
    ) {
    }

    public async fetchAllConnections(organisationId: string, page?: number, branch?: Branch, searchTerm: string = null, orderBy: string = null) {
        const request: SearchConnectionsRequest = {
            page: page || 1,
            organisation_id: organisationId,
        };
        if (branch) {
            request.branch_id = branch.id;
        }
        if (searchTerm) {
            request.search_term = searchTerm;
        }
        if (orderBy) {
            request.order_by = orderBy;
        }

        const response = await this.api.connections.search(request).toPromise();

        if (response) {
            this.totalConnections = response.pagination.total_items;
            this.currentConnectionsPage = response.pagination.current_page;
            this.totalConnectionsPages = Math.ceil(this.totalConnections / response.pagination.per_page);

            const connections = Connection.createMany(response.connections as any);
            this.connections.next(connections);
        }
    }

    public async fetchReceivedInvites(organisationId: string, page?: number, branch?: Branch, searchTerm: string = null, orderBy: string = null) {
        const request: SearchConnectionInvitesRequest = {
            page: page || 1,
            type: 'received',
            organisation_id: organisationId,
        };
        if (branch) {
            request.branch_id = branch.id;
        }
        if (searchTerm) {
            request.search_term = searchTerm;
        }
        if (orderBy) {
            request.order_by = orderBy;
        }
        const response = await this.api.connections.searchInvites(request).toPromise();

        if (response) {
            this.totalInvitesReceived = response.pagination.total_items;
            this.currentInvitesReceivedPage = response.pagination.current_page;
            this.totalInvitesReceivedPages = Math.ceil(this.totalInvitesReceived / response.pagination.per_page);

            const invites = Invite.createMany(response.invites as any);
            this.receivedInvites.next(invites);
        }
    }

    public async fetchSentInvites(organisationId: string, page?: number, branch?: Branch, searchTerm: string = null, orderBy: string = null) {
        const request: SearchConnectionInvitesRequest = {
            page: page || 1,
            type: 'sent',
            organisation_id: organisationId,
        };
        if (branch) {
            request.branch_id = branch.id;
        }
        if (searchTerm) {
            request.search_term = searchTerm;
        }
        if (orderBy) {
            request.order_by = orderBy;
        }
        const response = await this.api.connections.searchInvites(request).toPromise();

        if (response) {
            this.totalInvitesSent = response.pagination.total_items;
            this.currentInvitesSentPage = response.pagination.current_page;
            this.totalInvitesSentPages = Math.ceil(this.totalInvitesSent / response.pagination.per_page);

            const invites = Invite.createMany(response.invites as any);
            this.sentInvites.next(invites);
        }
    }

    public getConnections(): Observable<Connection[]> {
        return this.connections.asObservable();
    }

    public getTotalConnections(): Observable<number> {
        return this.totalConnectionsSubject.asObservable();
    }

    public getCurrentConnectionsPage(): Observable<number> {
        return this.currentPageSubject.asObservable();
    }

    public getTotalConnectionsPages(): Observable<number> {
        return this.totalPagesSubject.asObservable();
    }

    public getInvitesReceived(): Observable<any[]> {
        return this.receivedInvites.asObservable();
    }

    public getTotalInvitesReceived(): Observable<number> {
        return this.totalInvitesReceivedSubject.asObservable();
    }

    public getCurrentInvitesReceivedPage(): Observable<number> {
        return this.currentInvitesReceivedSubject.asObservable();
    }

    public getTotalInvitesReceivedPages(): Observable<number> {
        return this.totalInvitesReceivedSubject.asObservable();
    }

    public getInvitesSent(): Observable<any[]> {
        return this.sentInvites.asObservable();
    }

    public getTotalInvitesSent(): Observable<number> {
        return this.totalInvitesSentSubject.asObservable();
    }

    public getCurrentInvitesSentPage(): Observable<number> {
        return this.currentInvitesSentSubject.asObservable();
    }

    public getTotalInvitesSentPages(): Observable<number> {
        return this.totalInvitesSentSubject.asObservable();
    }

    public async fetchConnection(connectionId: string) {
        const response = await this.api.connections.get(connectionId).toPromise();
        const connection = Connection.createOne(response.connection as any);
        this.connection.next(connection);
    }

    public async fetchInvite(inviteId: string, token?: string) {
        const response = await this.api.connections.getInvite(inviteId, token).toPromise();
        const initiatingOrganisation = Organisation.createOne(response.organisation);
        const initiatingBranch = Branch.createOne(response.branch);
        this.initiatingOrganisation.next(initiatingOrganisation);
        this.initiatingBranch.next(initiatingBranch);
    }

    public getConnection(): Observable<Connection> {
        return this.connection.asObservable();
    }

    public getInitiatingOrganisation(): Observable<Organisation> {
        return this.initiatingOrganisation.asObservable();
    }

    public getInitiatingBranch(): Observable<Branch> {
        return this.initiatingBranch.asObservable();
    }

    public async rejectInvite(inviteId: string, token?: string) {
        let response;
        if (token) {
            response = await this.api.connections.rejectInvite(inviteId, {
                token,
            }).toPromise();
        } else {
            response = await this.api.connections.rejectInvite(inviteId, {}).toPromise();
        }
        this.inviteRejected.next(response);
    }

    public async acceptInvite(inviteId: string, branches: string[], token?: string) {
        let response;
        if (token) {
            response = await this.api.connections.acceptInvite(inviteId, {
                branches,
                token,
            }).toPromise();
        } else {
            response = await this.api.connections.acceptInvite(inviteId, {
                branches,
            }).toPromise();
        }

        if (response) {
            this.inviteAccepted.next(response);
        }
    }

    public getInviteRejected(): Observable<any> {
        return this.inviteRejected.asObservable();
    }

    public getInviteAccepted(): Observable<any> {
        return this.inviteAccepted.asObservable();
    }

    public async createConnection(recipientEmail: string, initiatingBranch: string) {
        return await this.api.connections.createInvite({
            email: recipientEmail,
            branch_id: initiatingBranch,
        }).toPromise();
    }

    public async sendInvite(inviteId: string) {
        return await this.api.connections.sendInvite(inviteId).toPromise();
    }

    public async removeConnection(connectionId: string) {
        return await this.api.connections.delete(connectionId).toPromise();
    }

}
