import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, formatDistanceToNowStrict, parseISO } from 'date-fns';

@Pipe({
    name: 'distanceToNow'
})
export class DistanceToNowPipe implements PipeTransform {

    public transform(value: Date | number | string, strict: true, options?: {
        addSuffix?: boolean
        unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
        roundingMethod?: 'floor' | 'ceil' | 'round'
        locale?: Locale
    }): string;
    public transform(value: Date | number | string, strict: false | null, options?: {
        includeSeconds?: boolean
        addSuffix?: boolean
        locale?: Locale
    }): string;
    public transform(value: Date | number | string, strict: boolean, options?): string;
    public transform(value: Date | number | string, strict: boolean, options?): string {
        if (typeof value === 'string') {
            return this.transform(parseISO(value), strict, options);
        }
        const formatter = strict ? formatDistanceToNowStrict : formatDistanceToNow;
        return formatter(value, options);
    }

}
