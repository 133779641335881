import { ButtonComponent } from './button/button.component';
import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { StatBoxComponent } from './stat-box/stat-box.component';
import { ChartComponent } from './chart/chart.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ModalComponent } from './modal/modal.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { FlashMessageComponent } from './flash-messages/flash-message/flash-message.component';
import { FlashMessagesComponent } from './flash-messages/flash-messages.component';
import { AppCaseMessageComponent } from './case-message/case-message';

const COMPONENTS = [
    ButtonComponent,
    FlashMessageComponent,
    FlashMessagesComponent,
    HeaderComponent,
    SideMenuComponent,
    StatBoxComponent,
    ChartComponent,
    BreadcrumbComponent,
    ModalComponent,
    NotificationBarComponent,
    PaginationComponent,
];

@NgModule({
    imports: [
        BrowserModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
    ],
    exports: [
        ...COMPONENTS,
        AppCaseMessageComponent,
    ],
    declarations: [
        ...COMPONENTS,
        AppCaseMessageComponent,
    ],
})
export class ComponentsModule {}
