import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Observable, Subject } from 'rxjs';
import { Organisation } from '../../models/organisation';
import { Branch } from '../../models/branch';
import {
    CreateNewOrganisationRequest,
    CreateUserRequest,
    EditBranchRequest,
    EditOrganisationRequest
} from '@scaffold/mediccoms-api-client/requests';

@Injectable()
export class OrganisationsService {

    private organisationsChanged: Subject<Organisation[]> = new Subject<Organisation[]>();
    private organisationChanged: Subject<Organisation> = new Subject<Organisation>();
    private organisationCreated: Subject<Organisation> = new Subject<Organisation>();
    private totalOrganisationsChanged: Subject<number> = new Subject<number>();
    private currentPageChanged: Subject<number> = new Subject<number>();
    private totalPagesChanged: Subject<number> = new Subject<number>();

    constructor(
        private api: ApiClientService
    ) {
    }

    public getOrganisations(): Observable<Organisation[]> {
        return this.organisationsChanged.asObservable();
    }

    public getOrganisation(): Observable<Organisation> {
        return this.organisationChanged.asObservable();
    }

    public getTotalOrganisations(): Observable<number> {
        return this.totalOrganisationsChanged.asObservable();
    }

    public getCurrentPage(): Observable<number> {
        return this.currentPageChanged.asObservable();
    }

    public getTotalPages(): Observable<number> {
        return this.totalPagesChanged.asObservable();
    }

    public async fetchOrganisations(page?: number) {
        const response = await this.api.organisations.all({
            page: page || 1
        }).toPromise();

        const organisations = Organisation.createMany(response.organisations);
        this.organisationsChanged.next(organisations);
        this.totalOrganisationsChanged.next(response.pagination.total_items);
        const currentPage = response.pagination.current_page;
        this.currentPageChanged.next(currentPage);
        const totalPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
        this.totalPagesChanged.next(totalPages);
        const perPage = response.pagination.per_page;
    }

    public async fetchOrganisation(organisationId: string) {
        const response = await this.api.organisations.get(organisationId).toPromise();
        const organisation = Organisation.createOne(response.organisation);
        this.organisationChanged.next(organisation);
    }

    public create(request: CreateNewOrganisationRequest) {
        return this.api.organisations.create(request).toPromise();
    }

    public async updateOrganisation(organisation: Organisation): Promise<any> {
        const request: EditOrganisationRequest = {
            name: organisation.name,
            address: organisation.address,
            primary_contact: organisation.primary_contact,
            messaging_enabled: organisation.messaging_enabled,
            cases_enabled: organisation.cases_enabled,
            enabled: organisation.enabled,
        };
        if (organisation.image) {
            request.image = organisation.image;
        }
        try {
            return await this.api.organisations.edit(organisation.id, request).toPromise();
        } catch (error) {
            return {
                error: true,
                errors: error
            };
        }
    }

    public async deleteOrganisation(organisationId: string): Promise<any> {
        try {
            return await this.api.organisations.delete(organisationId).toPromise();
        } catch (error) {
            return {
                error: true,
                errors: error
            };
        }
    }

    public clear(): void {
        console.log('clear organisations.service');
    }
}
