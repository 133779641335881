<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

    <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		<ng-container *ngIf="!organisation || !connection">
			Loading...
		</ng-container>
		<ng-container *ngIf="organisation && connection">
			<app-breadcrumb>
				<li class="breadcrumb-item" (click)="goToPage('organisations')" *can="'view'; target:'organisations'">
					Organisations
				</li>
				<li class="breadcrumb-item" aria-current="page" (click)="goToPage('organisations/' + organisation.id)">
					{{ organisation.name }}
				</li>
				<ng-container *ngIf="branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches')">
						Locations
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						{{ branch.name }}
					</li>
					<li class="breadcrumb-item" aria-current="page">
						Connections
					</li>
				</ng-container>
				<ng-container *ngIf="!branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/connections')">
						Connections
					</li>
				</ng-container>
				<li class="breadcrumb-item active" aria-current="page">
					{{ connection.id }}
				</li>
			</app-breadcrumb>
			<header class="d-flex">
				<h1 class="h1">Connection</h1>
			</header>

			<app-flash-messages></app-flash-messages>

			<div class="col-xl-5">
				<app-connection-organisation-branch [entity]="connection.branch"></app-connection-organisation-branch>

				<app-button class="red remove-connection small" data-toggle="modal" data-target="#remove-member-account">Remove Connection</app-button>
			</div>
		</ng-container>
	</main>

  </div>
</div>

<app-modal *ngIf="connection" modalTitle="Remove Connection" class="modal remove fade" id="remove-member-account" tabindex="-1" role="dialog" aria-labelledby="remove-member-account" aria-hidden="true">
	<div class="modal-body">
		<p class="sub-header">Are you sure you want to remove this connection?</p>
		<p class="note">All conversations and cases with this {{ connection.branch.name }} will be removed.</p>
	</div>
	<div class="modal-footer">
	  <app-button class="transparent" data-dismiss="modal">Cancel</app-button>
	  <app-button class="active" data-dismiss="modal" (click)="removeConnection()">Remove</app-button>
	</div>
</app-modal>
