import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { User } from '../models/user';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()

export class PolicyService implements OnDestroy {

    private currentUser: User = null;
    private policyServiceReady: Subject<void> = new Subject<void>();
    private onDestroy: Subject<void> = new Subject<void>();

    constructor(
        private userService: UserService,
    ) {
        this.userService.getMe().pipe(
            takeUntil(this.onDestroy),
        ).subscribe(user => {
            if (user !== this.currentUser) {
                this.currentUser = user;
                this.policyServiceReady.next();
            }
        });
    }

    public ngOnDestroy() {
        this.onDestroy.next();
    }

    public can(action: string, target: any): boolean {
        if (!this.currentUser) {
            return false;
        }
        return this.currentUser.can(action, target);
    }

    public cannot(action: string, target: any): boolean {
        if (!this.currentUser) {
            return false;
        }
        return !this.can(action, target);
    }

    public ready(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            if (this.currentUser) {
                resolve(true);
            }

            this.policyServiceReady.pipe(first()).subscribe(() => resolve(true));
        });
    }
}
