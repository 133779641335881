import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '../../pages/connections/connections.service';
import { Subject } from 'rxjs';
import { Organisation } from '../../models/organisation';
import { Branch } from '../../models/branch';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Connection } from '../../models/connection';
import { Invite } from '../../models/invite';
import { Router } from '@angular/router';

@Component({
    selector: 'app-connections-component',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit, OnDestroy {

    @Input() organisation: Organisation = null;
    @Input() branch: Branch = null;

    private isDestroyed: Subject<void> = new Subject<void>();
    public connections: Connection[] = [];
    public invitesReceived: Invite[] = [];
    public invitesSent: Invite[] = [];

    public _searchTerm: string = null;
    private searchTermChanged: Subject<string> = new Subject<string>();

    public set searchTerm(searchTerm: string) {
        this._searchTerm = searchTerm;
        this.searchTermChanged.next(this.searchTerm);
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public _orderBy: string = null;
    private orderByChanged: Subject<string> = new Subject<string>();

    public set orderBy(orderBy: string) {
        this._orderBy = orderBy;
        this.orderByChanged.next(this.orderBy);
    }

    public get orderBy(): string {
        return this._orderBy;
    }

    public selectedView: 'connections' | 'invites_sent' | 'invites_received' = 'connections';

    private _pageConnections = 1;
    private pageConnectionsChanged: Subject<number> = new Subject<number>();
    public totalConnectionsPages: number = null;
    public totalConnections: number = null;

    private _pageReceivedInvites = 1;
    private pageReceivedInvitesChanged: Subject<number> = new Subject<number>();
    public totalReceivedInvitesPages: number = null;
    public totalReceivedInvites: number = null;

    private _pageSentInvites = 1;
    private pageSentInvitesChanged: Subject<number> = new Subject<number>();
    public totalSentInvitesPages: number = null;
    public totalSentInvites: number = null;

    public get pageConnections(): number {
        return this._pageConnections;
    }

    public set pageConnections(value: number) {
        if (value !== this.pageConnections) {
            this._pageConnections = value;
            this.pageConnectionsChanged.next(value);
        }
    }

    public get pageReceivedInvites(): number {
        return this._pageReceivedInvites;
    }

    public set pageReceivedInvites(value: number) {
        if (value !== this.pageReceivedInvites) {
            this._pageReceivedInvites = value;
            this.pageReceivedInvitesChanged.next(value);
        }
    }

    public get pageSentInvites(): number {
        return this._pageSentInvites;
    }

    public set pageSentInvites(value: number) {
        if (value !== this.pageSentInvites) {
            this._pageSentInvites = value;
            this.pageSentInvitesChanged.next(value);
        }
    }

    constructor(
        private connectionService: ConnectionService,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.connectionService.getConnections().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(connections => this.connections = connections);

        this.connectionService.getTotalConnections().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalConnections = total);

        this.connectionService.getCurrentConnectionsPage().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.pageConnections = currentPage);

        this.connectionService.getTotalConnectionsPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalConnectionsPages = totalPages);

        this.connectionService.getInvitesReceived().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(invites => this.invitesReceived = invites);

        this.connectionService.getTotalInvitesReceived().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalReceivedInvites = total);

        this.connectionService.getCurrentInvitesReceivedPage().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.pageReceivedInvites = currentPage);

        this.connectionService.getTotalInvitesReceived().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalReceivedInvitesPages = totalPages);

        this.connectionService.getInvitesSent().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(invites => this.invitesSent = invites);

        this.connectionService.getTotalInvitesSent().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalSentInvites = total);

        this.connectionService.getCurrentInvitesSentPage().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.pageSentInvites = currentPage);

        this.connectionService.getTotalInvitesSent().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalSentInvitesPages = totalPages);

        this.pageConnectionsChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(page => {
            if (this.branch) {
                this.connectionService.fetchAllConnections(this.organisation.id, page, this.branch);
            } else {
                this.connectionService.fetchAllConnections(this.organisation.id, page);
            }
        });

        this.pageSentInvitesChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(page => {
            if (this.branch) {
                this.connectionService.fetchSentInvites(this.organisation.id, page, this.branch);
            } else {
                this.connectionService.fetchSentInvites(this.organisation.id, page);
            }
        });

        this.pageReceivedInvitesChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(page => {
            if (this.branch) {
                this.connectionService.fetchReceivedInvites(this.organisation.id, page, this.branch);
            } else {
                this.connectionService.fetchReceivedInvites(this.organisation.id, page);
            }
        });

        if (this.branch) {
            this.connectionService.fetchAllConnections(this.organisation.id, 1, this.branch);
            this.connectionService.fetchSentInvites(this.organisation.id, 1, this.branch);
            this.connectionService.fetchReceivedInvites(this.organisation.id, 1, this.branch);
        } else {
            this.connectionService.fetchAllConnections(this.organisation.id);
            this.connectionService.fetchSentInvites(this.organisation.id);
            this.connectionService.fetchReceivedInvites(this.organisation.id);
        }

        this.searchTermChanged.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(() => {
            this.pageConnections = 1;
            this.pageReceivedInvites = 1;
            this.pageSentInvites = 1;

            if (this.branch) {
                this.connectionService.fetchAllConnections(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
                this.connectionService.fetchSentInvites(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
                this.connectionService.fetchReceivedInvites(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
            } else {
                this.connectionService.fetchAllConnections(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
                this.connectionService.fetchSentInvites(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
                this.connectionService.fetchReceivedInvites(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
            }
        });

        this.orderByChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(() => {
            this.pageConnections = 1;
            this.pageReceivedInvites = 1;
            this.pageSentInvites = 1;

            if (this.branch) {
                this.connectionService.fetchAllConnections(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
                this.connectionService.fetchSentInvites(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
                this.connectionService.fetchReceivedInvites(this.organisation.id, 1, this.branch, this.searchTerm, this.orderBy);
            } else {
                this.connectionService.fetchAllConnections(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
                this.connectionService.fetchSentInvites(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
                this.connectionService.fetchReceivedInvites(this.organisation.id, 1, null, this.searchTerm, this.orderBy);
            }
        });
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public selectView(selectedView: 'connections' | 'invites_sent' | 'invites_received'): void {
        this.selectedView = selectedView;
    }
    public isChecked(selectedView: string): boolean {
        return selectedView === this.selectedView;
    }

    public viewConnection(connectionId: string) {
        if (this.branch) {
            this.router.navigate(['organisations', this.organisation.id, 'branches', this.branch.id, 'connections', connectionId]);
        } else {
            this.router.navigate(['organisations', this.organisation.id, 'connections', connectionId]);
        }
    }

    public viewInvite(inviteId: string) {
        if (this.branch) {
            this.router.navigate(['organisations', this.organisation.id, 'branches', this.branch.id, 'connections', 'invites', inviteId]);
        } else {
            this.router.navigate(['organisations', this.organisation.id, 'connections', 'invites', inviteId]);
        }
    }

    public removeConnection(connectionId: string) {
        // TODO: Implement
    }

    public acceptInvite(inviteId: string, token: string, branches: string[]) {
        // TODO: Implement
    }

    public rejectInvite(inviteId: string, token: string) {
        // TODO: Implement
    }

    public createInvite(email: string, branchId: string) {
        // TODO: Implement
    }

    public sendInvite(inviteId: string) {
        if (this.branch) {
            this.router.navigate(['organisations', this.organisation.id, 'branches', this.branch.id, 'connections', 'invites', inviteId, 'send']);
        } else {
            this.router.navigate(['organisations', this.organisation.id, 'connections', 'invites', inviteId, 'send']);
        }
    }

}
