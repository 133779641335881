import { NgModule } from '@angular/core';
import { AttachmentPipe } from './attachment.pipe';
import { Userrole } from './userrole';
import { ApiClientModule } from '@scaffold/mediccoms-api-client';
import { DateFnsPipe } from './date-fns.pipe';
import { DistanceBetweenPipe } from './distance-between.pipe';
import { DistanceToNowPipe } from './distance-to-now.pipe';
import { BackgroundImagePipe } from './background-image.pipe';
import { OrderByPipe } from './order-by.pipe';

const PIPES = [
    AttachmentPipe,
    BackgroundImagePipe,
    DateFnsPipe,
    DistanceBetweenPipe,
    DistanceToNowPipe,
    OrderByPipe,
    Userrole,
];

@NgModule({
    exports: [
        ...PIPES,
    ],
    declarations: [
        ...PIPES,
    ],
    imports: [
        ApiClientModule,
    ]
})
export class PipesModule {
}
