import { CaseEvent } from './case-event';
import { CaseMessageEvent as ApiCaseMessageEvent } from '@scaffold/mediccoms-api-client/models';
import { User } from '../user';

export class CaseMessageEvent extends CaseEvent {
    type: string;
    attachments: string[];
    deliveredAt: string;
    messageBody: string;
    readAt: string;
    sentBy: User;

    public static mapFromApi(data: Partial<ApiCaseMessageEvent>): Partial<CaseMessageEvent> {
        if (!data) {
            return null;
        }

        return {
            type: 'message',
            attachments: data.attachments,
            deliveredAt: data.delivered_at,
            messageBody: data.message_body,
            readAt: data.read_at,
            sentAt: data.sent_at,
            sentBy: User.mapFromApi({
                id: data.sent_by
            }) as User,
            // sentBy: User.createOne(data.sent_by as any),
        };
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            attachments: this.attachments,
            deliveredAt: this.deliveredAt,
            messageBody: this.messageBody,
            readAt: this.readAt,
            sentAt: this.sentAt,
            sentBy: this.sentBy ? this.sentBy.id : null,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<CaseMessageEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.attachments !== undefined) {
            this.attachments = data.attachments;
        }
        if (data.deliveredAt !== undefined) {
            this.deliveredAt = data.deliveredAt;
        }
        if (data.messageBody !== undefined) {
            this.messageBody = data.messageBody;
        }
        if (data.readAt !== undefined) {
            this.readAt = data.readAt;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.sentBy !== undefined) {
            this.sentBy = User.createOne(data.sentBy);
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public get status(): 'sent' | 'delivered' | 'read' {
        if (!this.deliveredAt) { return 'sent'; }
        if (!this.readAt) { return 'delivered'; }
        return 'read';
    }

    public fillFromApi(data: Partial<ApiCaseMessageEvent>): this {
        this.fill(CaseMessageEvent.mapFromApi(data));
        return this;
    }
}
