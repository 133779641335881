import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { BranchesService } from '../branches/branches.service';
import { Branch } from '../../models/branch';
import { MembersService } from '../members/members.service';
import { User } from '../../models/user';
import { Connection } from '../../models/connection';
import { Organisation } from '../../models/organisation';
import { OrganisationsService } from '../organisations/organisations.service';
import { UserRole } from '../members/members';
import { environment } from '../../../environments/environment';
import { FlashMessageService } from '../../services/flash-message.service';
import { MenuService } from '../../services/menu.service';
import { AttachmentService } from '../../services/attachment.service';

@Component({
    selector: 'app-branch',
    templateUrl: './branch.html',
    styleUrls: ['./branch.scss']
})
export class BranchPage implements OnInit, OnDestroy, AfterViewInit {
    private _page: number = null;
    private _pageMembers: number = null;
    private _pageConnections: number = null;
    private _searchTerm: string = null;

    public organisationId: string = null;
    public branchId: string = null;

    public selectedRole: UserRole = null;
    public userRoles: UserRole[];
    public currentUser: User = null;

    private isDestroyed: Subject<void> = new Subject<void>();
    private pageChanged: Subject<number> = new Subject<number>();
    private pageMembersChanged: Subject<number> = new Subject<number>();
    private pageConnectionsChanged: Subject<number> = new Subject<number>();
    private searchTermChanged: Subject<string> = new Subject<string>();
    private filterChanged: Subject<string> = new Subject<string>();
    notificationType: string;

    public roles: string[] = [];
    public filteredRole = 'All';
    public branchMembers: any = null;
    public allBranchMembers: Branch[] = [];
    public branchProfile: Branch;

    public totalMembersPages = 0;
    public totalBranchMembers = 0;
    public allBranchMemberCount = 0;
    public branchConnection: string[] = [];

    public totalBranchConnections = 0;
    public connections: Connection[] = [];
    public totalConnectionsPages = 0;

    public branch: Branch = null;
    public organisation: Organisation = null;
    public cases: string[] = [];
    public users: User[] = null;

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    public get page(): number {
        return this._page;
    }

    public set page(value: number) {
        this._page = value;
        this.pageChanged.next(value);
    }

    public get pageMembers(): number {
        return this._pageMembers;
    }

    public set pageMembers(value: number) {
        this._pageMembers = value;
        this.pageMembersChanged.next(value);
    }

    public get pageConnections(): number {
        return this._pageConnections;
    }

    public set pageConnections(value: number) {
        this._pageConnections = value;
        this.pageConnectionsChanged.next(value);
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        this._searchTerm = value;
        this.searchTermChanged.next(value);
    }


    yearToDateData = [12, 19, 3, 5, 2, 3];

    constructor(
        private attachmentService: AttachmentService,
        private branchService: BranchesService,
        private flashMessageService: FlashMessageService,
        private membersService: MembersService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public async removeBranch() {
        try {
            const response = await this.branchService.deleteBranch(this.organisationId, this.branchId);
            if (response.deleted) {
                this.flashMessageService.addMessage('success', 'Branch Removed Successfully');
                await this.router.navigate(['/organisations/' + this.organisationId + '/branches']);
            } else {
                throw new Error('An Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.addMessage('error', e);
        }
    }

    public ngOnInit(): void {
        this.menuService.setActive('branches');

        this.pageMembersChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.branchService.getBranchMembersPage(page));

        this.pageConnectionsChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.branchService.fetchConnections(this.organisationId, this.branchId, page));

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.branchService.getBranchMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(users => this.users = users);

        this.branchService.getTotalBranchMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(totalUsers => this.totalBranchMembers = totalUsers);

        this.branchService.getTotalMembersPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalMemberPages) => this.totalMembersPages = totalMemberPages);

        this.branchService.getCurrentMembersPage().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.pageMembers = currentPage);

        // this.branchService.getBranchConnections().pipe(
        //     takeUntil(this.isDestroyed)
        // ).subscribe(connections => this.connections = connections);

        // this.branchService.getTotalBranchConnections().pipe(
        //     takeUntil(this.isDestroyed)
        // ).subscribe(totalBranchConnections => this.totalBranchConnections = totalBranchConnections);
        //
        // this.branchService.getTotalConnectionsPages().pipe(
        //     takeUntil(this.isDestroyed)
        // ).subscribe((totalConnectionsPages) => this.totalConnectionsPages = totalConnectionsPages);
        //
        // this.branchService.getCurrentConnectionsPage().pipe(
        //     takeUntil(this.isDestroyed)
        // ).subscribe((currentPage) => this.pageConnections = currentPage);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id') && params.hasOwnProperty('branch_id')) {
                const organisationId = params.organisation_id;
                const branchId = params.branch_id;
                this.organisationId = organisationId;
                this.branchId = branchId;
                this.organisationService.fetchOrganisation(organisationId);
                this.branchService.fetchBranch(organisationId, branchId);
                this.branchService.fetchBranchMembers(organisationId, branchId);
                // this.branchService.fetchConnections(organisationId, branchId);
            }
        });

        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                this.userRoles = [];
                const userRoles = environment.user_roles;

                for (const role of userRoles) {
                    if (this.currentUser.can('seeUserRole', role)) {
                        this.userRoles.push(role);
                    }
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public filterMembers(selectedRole?: string) {
        if (selectedRole) {
            this.selectedRole = this.userRoles.find(role => role.key === selectedRole);
        } else {
            this.selectedRole = null;
        }
        this.branchService.filterByRole(selectedRole);
    }

    public async saveChanges() {
        this.flashMessageService.clearMessages();
        try {
            const response = await this.branchService.updateBranch(this.organisationId, this.branch);
            if (response.branch) {
                this.flashMessageService.showMessage('success', 'The branch has been successfully updated.');
            } else {
                throw new Error('An Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', e);
        }
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.branch.image = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }
}
