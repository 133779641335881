import { Component, OnInit, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import { CaseResolvedBreakdown } from '../../models/case-resolved-breakdown';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {

    @Input() chartTitle: any;
    @Input() chartType: any;
    @Input() chartData: CaseResolvedBreakdown[];
    @Input() xLabel: string = null;

    @ViewChild('chartElement', {static: true}) chartElement: ElementRef;

    constructor() {
    }

    private generateChart(chartType, chartData: CaseResolvedBreakdown[]) {

        const myChart = new Chart(this.chartElement.nativeElement, {
            type: chartType,
            data: {
                labels: chartData.map(value => {
                    return value.key;
                }),
                datasets: [{
                    label: this.chartTitle,
                    fill: true,
                    pointStyle: 'circle',
                    pointRadius: 4,
                    pointBackgroundColor: 'rgba(55, 39, 66, 1)',
                    pointHoverRadius: 8,
                    data: chartData.map(value => {
                        return value.value;
                    }),
                    backgroundColor: [
                        'rgba(235, 233, 236, 1)',
                    ],
                    borderColor: [
                        'rgba(55, 39, 66, 1)',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Cases Resolved'
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        }
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: this.xLabel,
                        }
                    }]
                }
            },
        });
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.generateChart(this.chartType, this.chartData);
    }

}
