import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ConnectionService } from './connections.service';
import { MenuService } from '../../services/menu.service';
import { Organisation } from '../../models/organisation';
import { OrganisationsService } from '../organisations/organisations.service';
import { BranchesService } from '../branches/branches.service';
import { takeUntil } from 'rxjs/operators';
import { Branch } from '../../models/branch';

@Component({
    selector: 'app-connections',
    templateUrl: './connections.html',
    styleUrls: ['./connections.scss']
})
export class ConnectionsPage implements OnInit, OnDestroy {

    public organisationId: string = null;
    public organisation: Organisation = null;
    public branchId: string = null;
    public branch: Branch = null;

    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private branchService: BranchesService,
        private connectionService: ConnectionService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.menuService.setActive('connections');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(this.organisationId);

                if (params.hasOwnProperty('branch_id')) {
                    this.branchId = params.organisation_id;
                    this.branchService.fetchBranch(this.organisationId, this.branchId);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

}
