/* tslint:disable:variable-name */
import { AbstractModel } from './abstract.model';
import { UserRole, UserState } from '@scaffold/mediccoms-api-client/types';
import { User as ApiUser } from '@scaffold/mediccoms-api-client/models';
import { UserRole as AppUserRole } from '../pages/members/members';
import { environment } from '../../environments/environment';

export class User extends AbstractModel {

    public get isDoctor(): boolean {
        return this.title && this.title.toLowerCase() === 'dr';
    }

    public get friendlyName(): string {
        const parts = [];
        if (this.isDoctor) {
            parts.push(this.title, this.last_name);
        } else {
            parts.push(this.first_name, this.last_name);
        }
        return parts.length ? parts.filter(part => part).join(' ') : null;
    }

    public get fullName(): string | null {
        const parts = [
            this.first_name,
            this.last_name,
        ].filter(part => part);
        return parts.length ? parts.join(' ') : null;
    }

    public get fullNameWithTitle(): string | null {
        const parts = [
            this.title,
            this.first_name,
            this.last_name,
        ].filter(part => part);
        return parts.length ? parts.join(' ') : null;
    }

    public get initials(): string | null {
        if (this.first_name && this.last_name) {
            return this.first_name.substr(0, 1) + this.last_name.substr(0, 1);
        }
        return null;
    }

    public get userLevel() {
        const roles = environment.user_roles;
        return roles.find(role => role.key === this.role).level;
    }

    public get isMedicComs() {
        return this.userLevel >= 40;
    }

    public get isActive() {
        return this.state === 'active';
    }

    public can_assign_cases: boolean;
    public email: string;
    public first_name: string;
    public has_full_case_access: boolean;
    public id: string;
    public image: string;
    public job_title: string;
    public last_name: string;
    public organisation_id: string;
    public phone_number: string;
    public role: UserRole;
    public state: UserState;
    public title: string;

    public static mapFromApi(data: Partial<ApiUser>): Partial<User> {
        if (!data) {
            return null;
        }

        return {
            id: data.id,
            can_assign_cases: data.can_assign_cases,
            email: data.email,
            first_name: data.first_name,
            has_full_case_access: data.has_full_case_access,
            image: data.image,
            job_title: data.job_title,
            last_name: data.last_name,
            organisation_id: data.organisation_id,
            phone_number: data.phone_number,
            role: data.role,
            state: data.state,
            title: data.title,
        };
    }

    public fill(data: Partial<User>) {
        if (data.can_assign_cases !== undefined) {
            this.can_assign_cases = data.can_assign_cases;
        }
        if (data.email !== undefined) {
            this.email = data.email;
        }
        if (data.first_name !== undefined) {
            this.first_name = data.first_name;
        }
        if (data.has_full_case_access !== undefined) {
            this.has_full_case_access = data.has_full_case_access;
        }
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.job_title !== undefined) {
            this.job_title = data.job_title;
        }
        if (data.last_name !== undefined) {
            this.last_name = data.last_name;
        }
        if (data.organisation_id !== undefined) {
            this.organisation_id = data.organisation_id;
        }
        if (data.phone_number !== undefined) {
            this.phone_number = data.phone_number;
        }
        if (data.role !== undefined) {
            this.role = data.role;
        }
        if (data.state !== undefined) {
            this.state = data.state;
        }
        if (data.title !== undefined) {
            this.title = data.title;
        }
    }

    public can(action: string, target?: any) {
        switch (action) {
            case 'seeUserRole':
                return this.seeUserRole(target);

            case 'viewComponent':
                return true;

            case 'accessSidebar':
                return this.accessSidebar(target);

            case 'view':
                return this.view(target);

            case 'edit':
                return this.edit(target);

            default:
                return false;
        }
    }

    private seeUserRole(target: AppUserRole) {
        return target.level <= this.userLevel;
    }

    private accessSidebar(target: string) {
        if (target === 'organisations' && this.userLevel >= 40) { return true; }
        if (target === 'organisations' && this.userLevel < 40) { return false; }
        if (target === 'branches' && this.userLevel >= 40) { return false; }
        if (target === 'branches' && this.userLevel < 40) { return true; }
        if (target === 'connections' && this.userLevel !== 30) { return false; }
        if (target === 'connections' && this.userLevel === 30) { return true; }
        if (target === 'cases' && this.userLevel >= 40) { return false; }
        if (target === 'cases' && this.userLevel < 40) { return true; }
        // if (target === 'messages' && this.userLevel >= 40) { return false; }
        // if (target === 'messages' && this.userLevel < 40) { return true; }
        // if (target === 'cases_messages' && this.userLevel >= 40) { return false; }
        // if (target === 'cases_messages' && this.userLevel < 40) { return true; }
        return true;
    }

    private view(target: string) {
        if (target === 'organisations' && this.userLevel >= 40) { return true; }
        return !(target === 'organisations' && this.userLevel < 40);
    }

    private edit(target: any) {
        if (target instanceof User) {
            return this.editUser(target);
        }
    }

    private editUser(targetUser: User) {
        return this.userLevel > targetUser.userLevel;
    }

}
