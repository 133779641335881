import { NgModule } from '@angular/core';
import { MembersPage } from './members';
import { MembersService } from './members.service';
import { AddMemberPage } from '../add-member/add-member';
import { MemberProfilePage } from '../member-profile/member-profile';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';

const COMPONENTS = [
    AddMemberPage,
    MembersPage,
    MemberProfilePage,
];

@NgModule({
    imports: [
        BrowserModule,
        ComponentsModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
        NgSelectModule,
    ],
    exports: [
        ...COMPONENTS,
    ],
    declarations: [
        ...COMPONENTS,
    ],
    providers: [
        MembersService,
    ]
})
export class MembersModule {}
