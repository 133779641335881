<div class="case white-box" *ngIf="events?.length > 0">
    <h2 class="case-title">
        Message History
    </h2>

    <ng-container *ngFor="let event of events | orderBy: 'sentAtDate'">
        <div class="info-event" *ngIf="event.type === 'opened' && event.sentAt">Opened {{ event.sentAt | distanceToNow : true }} ago</div>
        <div class="info-event" *ngIf="event.type === 'resolved' && event.sentAt">Resolved {{ event.sentAt | distanceToNow : true }} ago</div>
        <div class="info-event" *ngIf="event.type === 'accepted' && event.sentAt">Accepted {{ event.sentAt | distanceToNow : true }} ago</div>
        <div class="info-event" *ngIf="event.type === 'assigned' && event.sentAt">Assigned {{ event.sentAt | distanceToNow : true }} ago</div>
        <div class="message-event" *ngIf="event.type === 'message'">
            <app-case-message [conversation]="conversation" [event]="event"></app-case-message>
        </div>
    </ng-container>
</div>
