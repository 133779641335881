<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

    <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		<ng-container *ngIf="!organisation || !initiatingOrganisation || !initiatingBranch">
			Loading...
		</ng-container>
		<ng-container *ngIf="organisation && initiatingOrganisation && initiatingBranch">
			<app-breadcrumb>
				<li class="breadcrumb-item" (click)="goToPage('organisations')" *can="'view'; target:'organisations'">
					Organisations
				</li>
				<li class="breadcrumb-item" aria-current="page" (click)="goToPage('organisations/' + organisation.id)">
					{{ organisation.name }}
				</li>
				<ng-container *ngIf="branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches')">
						Location
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						{{ branch.name }}
					</li>
					<li class="breadcrumb-item" aria-current="page"
					(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						Connections
					</li>
				</ng-container>
				<ng-container *ngIf="!branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/connections')">
						Connections
					</li>
				</ng-container>
				<li class="breadcrumb-item" aria-current="page">
					Invites
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					{{ inviteId }}
				</li>
			</app-breadcrumb>
			<header class="d-flex">
				<h1 class="h1">Invite</h1>
			</header>

			<app-flash-messages></app-flash-messages>

			<div class="row d-flex justify-content-between">
				<div class="col-xl-5">
					<app-connection-organisation-branch [entity]="initiatingOrganisation"></app-connection-organisation-branch>
				</div>
				<div class="col-xl-5">
					<app-connection-organisation-branch [entity]="initiatingBranch"></app-connection-organisation-branch>
				</div>

				<div class="col-12 mt-5" *ngIf="branches">
					<div class="connection white-box">
						<h2 class="connection-title">Select Locations to Connect with {{ initiatingBranch.name }}</h2>

						<form class="base-form">
							<div class="form-group">
								<label for="connected_branches">Locations</label>
								<select class="form-control" id="connected_branches" [(ngModel)]="branchesToConnect"
										multiple="true" [ngModelOptions]="{ standalone: true }">
									<option *ngFor="let branch of uniqueBranches"
											[value]="branch.id">{{ branch.name }}</option>
								</select>
							</div>
						</form>

						<div class="d-flex justify-content-between">
							<app-button class="active remove-connection small" (click)="acceptInvite()">Accept Invite</app-button>
							<app-button class="red remove-connection small" data-toggle="modal" data-target="#remove-member-account">Reject Invite</app-button>
						</div>
					</div>
				</div>

			</div>
		</ng-container>
	</main>

  </div>
</div>

<app-modal *ngIf="initiatingOrganisation && initiatingBranch" modalTitle="Reject Invite" class="modal remove fade" id="remove-member-account" tabindex="-1" role="dialog" aria-labelledby="remove-member-account" aria-hidden="true">
	<div class="modal-body">
		<p class="sub-header">Are you sure you want to reject this invite?</p>
		<p class="note">You will need to re-initiate a connection with this organisation if you wish to connect again.</p>
	</div>
	<div class="modal-footer">
	  <app-button class="transparent" data-dismiss="modal">Cancel</app-button>
	  <app-button class="active" data-dismiss="modal" (click)="rejectInvite()">Reject</app-button>
	</div>
</app-modal>

<button #errorModal data-toggle="modal" data-target="#errorModal" style="display: none;">open modal</button>
<app-modal modalTitle="Error" class="modal remove fade" id="errorModal"  tabindex="-1" role="dialog" aria-labelledby="errorModal" aria-hidden="true">
	<div class="modal-body">
		<p class="sub-header">You need to select Locations</p>
		<p class="note">To accept a Connection Invite, you need to select atleast one branch.</p>
	</div>
	<div class="modal-footer">
		<app-button class="transparent" data-dismiss="modal">Dismiss</app-button>
	</div>
</app-modal>
