import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { Branch } from '../../models/branch';
import { MenuService } from '../../services/menu.service';
import { CaseStats } from '../../models/case-stats';
import { CaseService } from '../cases/cases.service';
import { UserRole } from '../members/members';
import { environment } from '../../../environments/environment';
import { FlashMessageService } from '../../services/flash-message.service';
import { AttachmentService } from '../../services/attachment.service';

@Component({
    selector: 'app-member-profile',
    templateUrl: './account.html',
    styleUrls: ['./account.scss']
})
export class AccountPage implements OnInit, OnDestroy, AfterViewInit {

    public currentUser: User;
    public caseStats: CaseStats = null;
    public branches: Branch[] = [];
    private isDestroyed: Subject<void> = new Subject<void>();

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    constructor(
        private api: ApiClientService,
        private attachmentService: AttachmentService,
        private caseService: CaseService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.menuService.setActive('account');

        this.caseService.getCaseStats().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(stats => this.caseStats = stats);

        this.userService.getMe().pipe(
                takeUntil(this.isDestroyed)
        ).subscribe(me => {
                this.currentUser = me;
                if (this.currentUser.organisation_id) {
                    this.caseService.searchCases(1, {
                        organisationId: this.currentUser.organisation_id,
                        userId: this.currentUser.id,
                        caseStatus: 'all',
                        type: 'all',
                    });
                }
            });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
    }

    public async updateProfile() {
        this.userService.editMember(this.currentUser.id, this.currentUser).then(response => {
            if (response.error) {
                this.flashMessageService.showMessage('error', response.error);
                return;
            }

            this.flashMessageService.showMessage('success', 'Profile successfully updated!');
        });
    }

    public get userRoles(): UserRole[] {
        const roles: UserRole[] = [];

        if (this.currentUser) {
            for (const role of environment.user_roles) {
                if (this.currentUser.can('seeUserRole', role)) {
                    if ((!this.currentUser.organisation_id && role.level >= 40) || (this.currentUser.organisation_id && role.level < 40)) {
                        roles.push(role);
                    }
                }
            }
        }

        return roles;
    }

    public get userRole(): UserRole {
        return this.userRoles.find(role => role.key === this.currentUser?.role);
    }

    public isMedicComs(): boolean {
        if (this.userRole?.level >= 40) {
            return true;
        }
        return false;
    }

    public isEndUser() {
        return !this.isMedicComs();
    }

    public toggleAssignCases() {
        this.currentUser.can_assign_cases = !this.currentUser.can_assign_cases;
    }

    public toggleViewCaseInfo() {
        this.currentUser.has_full_case_access = !this.currentUser.has_full_case_access;
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.currentUser.image = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

}
