import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PolicyService } from '../services/policy.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[can]'
})
export class CanDirective implements OnInit {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private policyService: PolicyService,
    ) {
    }

    private action: string;

    @Input() set can(action: string) {
        this.action = action;
    }

    private target: any;

    @Input() set canTarget(target: any) {
        this.target = target;
        this.policyService.ready().then(() => {
            if (this.policyService.can(this.action, this.target)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }

    public ngOnInit() {
    }
}
