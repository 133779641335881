import { Component, HostBinding, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

    public currentUser: User;
    public currentPage: string = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    @HostBinding('class.col-md-3') colMd3 = true;
    @HostBinding('class.col-xl-2') colXl2 = true;

    constructor(
        private menuService: MenuService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(me => this.currentUser = me);

        this.menuService.getActive().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(currentPage => this.currentPage = currentPage);
    }

    public showPage(page) {
        switch (page) {
            case 'branches':
                page = `organisations/${this.currentUser.organisation_id}/branches`;
                break;
            case 'cases':
                page = `organisations/${this.currentUser.organisation_id}/cases`;
                break;
            case 'connections':
                page = `organisations/${this.currentUser.organisation_id}/connections`;
                break;
        }
        this.router.navigate([page]);
    }

    public isActive(page: string) {
        return this.currentPage === page ? true : (this.currentPage === 'branches' && page === 'organisations');
    }

}
