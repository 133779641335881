import { Component, OnInit } from '@angular/core';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.html',
    styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordPage implements OnInit {

    public username: string = null;
    public newPassword: string = null;
    public newPasswordAgain: string = null;
    public mfaCode: string = null;
    public errors: string[] = [];
    public hasError = false;
    public isLoading = false;
    public authType: 'request' | 'verify' | 'success' = 'request';

    constructor(
        private authService: CognitoAuthenticationService,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.isLoading = false;
        this.clearValues();
    }

    private clearValues() {
        this.username = '';
        this.newPassword = '';
        this.newPasswordAgain = '';
        this.mfaCode = '';
    }

    private addError(error: string) {
        this.errors.push(error);
        this.hasError = true;
        this.isLoading = false;
    }

    private clearErrors() {
        this.errors = [];
        this.hasError = false;
    }

    private catchAuthError(error) {
        this.addError(error);
        this.isLoading = false;
    }

    public loginAction() {
        console.log('loginAction');
        this.clearErrors();
        this.isLoading = true;

        switch (this.authType) {
            case 'verify':
                this.verify();
                break;
            case 'request':
                this.request();
                break;
            case 'success':
            default:
                this.goToLogin();
                break;
        }
    }

    private request() {
        if (!this.username || this.username === '') {
            this.addError('Username is required.');
            return;
        }

        this.authService.forgotPassword(this.username).then(forgotPasswordResponse => {
            if (forgotPasswordResponse) {
                this.authType = 'verify';
                this.isLoading = false;
            } else {
                this.addError('An Unexpected Error Occurred');
            }
        }).catch(error => {
            this.addError(error);
        });
    }

    private verify() {
        if (this.mfaCode === '' || this.newPassword === '' || this.newPasswordAgain === '') {
            this.addError('All Fields Required.');
            return;
        }

        if (this.newPassword !== this.newPasswordAgain) {
            this.addError('New Passwords Do Not Match.');
            return;
        }

        this.authService.confirmForgotPassword(this.mfaCode, this.newPassword)
            .then(response => {
                if (response) {
                    console.log('confirmForgotPassword: ', response);
                    this.authType = 'success';
                    this.isLoading = false;
                } else {
                    this.addError('An Unexpected Error Occurred');
                }
            }).catch(error => {
            this.addError(error);
        });
    }

    private goToLogin() {
        this.router.navigate(['login']);
    }

    public get actionButtonText() {
        switch (this.authType) {
            case 'verify':
                return 'Change Password';
            case 'request':
                return 'Request Code';
            case 'success':
            default:
                return 'Login';
        }
    }

}
