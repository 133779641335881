<div class="white-box report-filters mb-4">
    <form class="base-form">
        <div class="form-title">
            Report Filters
        </div>
        <div class="form-group">
            <label>Cases</label>
            <div class="filters">
                <div class="radio-group">
                    <input type="radio" (click)="selectStatus('all')" [checked]="isChecked('all')" name="radio-filters">
                    <div class="button">All</div>
                </div>
                <div class="radio-group">
                    <input type="radio" (click)="selectStatus('opened')" [checked]="isChecked('opened')" name="radio-filters">
                    <div class="button">Open</div>
                </div>
                <div class="radio-group">
                    <input type="radio" (click)="selectStatus('resolved')" [checked]="isChecked('resolved')" name="radio-filters">
                    <div class="button">Resolved</div>
                </div>
            </div>
        </div>
        <div class="form-group form-group-select">
            <label for="employee">Employee</label>
            <select class="form-control" id="employee" [(ngModel)]="employeeId" [ngModelOptions]="{ standalone: true }">
                <option [value]="null">All Employees</option>
                <option *ngFor="let user of users" [value]="user.id">{{ user.fullNameWithTitle }}</option>
            </select>
        </div>
        <div class="form-group form-group-select">
            <label for="patient_id">Patient ID </label>
            <input type="text" class="form-control" id="patient_id" [(ngModel)]="patientId" [ngModelOptions]="{ standalone: true }" placeholder="Patient ID">
        </div>

        <div class="form-group">
            <label>After Date</label>
            <input class="form-control" [(ngModel)]="afterDate" type="date" placeholder="Date" [ngModelOptions]="{ standalone: true }" />
        </div>

        <div class="form-group">
            <label>After Time</label>
            <input class="form-control" [(ngModel)]="afterTime" type="time" placeholder="Time" [ngModelOptions]="{ standalone: true }" />
        </div>

        <div class="form-group">
            <label>Before Date</label>
            <input class="form-control" [(ngModel)]="beforeDate" type="date" placeholder="Date" [ngModelOptions]="{ standalone: true }" />
        </div>

        <div class="form-group">
            <label>Before Time</label>
            <input class="form-control" [(ngModel)]="beforeTime" type="time" placeholder="Time" [ngModelOptions]="{ standalone: true }" />
        </div>

        <app-button class="active small mt-4" (click)="runReport()">Run Report</app-button>
    </form>
</div>
