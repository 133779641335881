import {Pipe, PipeTransform} from '@angular/core';
import { environment } from '../../environments/environment';
import { UserRole } from '../pages/members/members';

@Pipe({
	name: 'userrole',
})
export class Userrole implements PipeTransform {

	constructor() {
	}

	public transform(userrole: string | UserRole): any {
		if (typeof userrole === 'string') {
			const selectedRole = environment.user_roles.find(role => role.key === userrole);
			return selectedRole.name;
		}
		return userrole.name;
	}
}
