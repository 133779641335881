import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { UserService } from '../services/user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { CognitoAuthenticationService } from '../services/cognito-authentication.service';
import { Subject } from 'rxjs';

@Injectable()
export class SystemUserGuard implements CanActivate {

    private resolvedSubject: Subject<void> = new Subject<void>();

    constructor(
        private authService: CognitoAuthenticationService,
        private router: Router,
        private settings: SettingsService,
        private userService: UserService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            this.userService.getMe().pipe(
                takeUntil(this.resolvedSubject),
                filter(user => user !== null)
            ).subscribe(user => {
                if (user.userLevel >= 40) {
                    this.resolvedSubject.next();
                    resolve(true);
                } else {
                    this.router.navigate(['dashboard']);
                    this.resolvedSubject.next();
                }
            });
        });
    }
}
