import {Injectable} from '@angular/core';
import {ApiClientService} from '@scaffold/mediccoms-api-client';
import {User} from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { EditUserRequest } from '@scaffold/mediccoms-api-client/requests';
import { CognitoAuthenticationService } from './cognito-authentication.service';

@Injectable()
export class UserService {

	private currentUserChanged: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	private currentUser: User;

	constructor(
		private api: ApiClientService,
		private authService: CognitoAuthenticationService,
	) {
		this.authService.getLogout().subscribe(async () => {
			// this.router.navigate(['login']);
			// window.location.reload();
			this.setCurrentUser(null);
		});
	}

	public async fetchMe(): Promise<User> {
		const response = await this.api.me.getProfile().toPromise();
		const user = User.createOne(response.user);
		this.setCurrentUser(user);
		return user;
	}

	public getMe(): Observable<User> {
		return this.currentUserChanged.asObservable();
	}

	public setCurrentUser(user: User) {
		this.currentUser = user;
		this.currentUserChanged.next(user);
	}

	public removeMember(userID): Promise<any> {
		return this.api.users.delete(userID).toPromise();
	}

	public async createMember(data): Promise<any> {
		try {
			return await this.api.users.create(data).toPromise();
		} catch (error) {
			return {
				error: true,
				errors: error
			};
		}
	}

	public async editMember(id, data: EditUserRequest, branches?: string[]): Promise<any> {
		if (branches) {
			data.branches = branches;
		}
		if (!data.image) {
			delete data.image;
		}
		if (data.organisation_id === null) {
			delete data.organisation_id;
		}
		try {
			return await this.api.users.edit(id, data).toPromise();

		} catch (error) {
			return {
				error: true,
				errors: error
			};
		}
	}

	public async resetPassword(userId: string): Promise<any> {
		return await this.api.users.resetPassword(userId).toPromise();
	}

	public clear(): void {
		this.currentUser = null;
	}
}
