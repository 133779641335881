<app-header></app-header>

<div class="container-fluid" *ngIf="currentUser">
  <div class="row">

	  <app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

	  <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		  <ng-container *ngIf="!currentUser">
			  Loading...
		  </ng-container>
		  <ng-container *ngIf="currentUser">
			  <app-breadcrumb>
				  <li class="breadcrumb-item active" aria-current="page">My Profile</li>
			  </app-breadcrumb>

			  <app-flash-messages></app-flash-messages>
			<h1 class="h1">My Profile</h1>
			<div class="row">
				<div class="col-xl-5">
					<div class="profile-options white-box">
						<div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="currentUser.image">
							<span class="add-icon">
								<img src="assets/icon/purple-edit-icon.svg" />
							</span>
							<span class="profile-img" [style.backgroundImage]="currentUser.image | attachment: 'profile_small' | backgroundImage"></span>
						</div>
						<div data-toggle="modal" data-target="#add-photo" class="image profile-avatar profile-avatar-fallback large" *ngIf="!currentUser.image">
							<span class="add-icon">
								<img src="assets/icon/purple-add-icon.svg" />
							</span>
							<span class="profile-img">{{ currentUser.initials }}</span>
						</div>
						<form class="base-form">
							<div class="form-group">
							  <label for="first_name">First Name</label>
							  <input type="text" class="form-control" id="first_name" aria-describedby="first_name" placeholder="Enter name" [(ngModel)]="currentUser.first_name" name="first_name">
							</div>
							<div class="form-group">
								<label for="last_name">Last Name</label>
								<input type="text" class="form-control" id="last_name" aria-describedby="last_name" placeholder="Enter name" [(ngModel)]="currentUser.last_name" name="last_name">
							</div>
							<div class="form-group">
								<label for="email">Email</label>
								<input type="text" class="form-control" id="email" aria-describedby="email" placeholder="Enter email" [(ngModel)]="currentUser.email" name="email">
							</div>
							<div class="form-group">
								<label for="phone_number">Contact Number</label>
								<input type="text" class="form-control" id="phone_number" aria-describedby="phone_number" placeholder="Enter Contact Number" [(ngModel)]="currentUser.phone_number" name="phone_number">
							</div>
							<div class="form-group">
								<label for="job_title">Job Title</label>
								<input type="text" class="form-control" id="job_title" aria-describedby="job_title"
									   placeholder="Enter Job Title" [(ngModel)]="currentUser.job_title" name="job_title">
							</div>
						</form>
					</div>
					<app-button class="active remove-member small" (click)="updateProfile()">Update Profile</app-button>
				</div>
				<div class="col-xl-7">

					<div class="cases-widget" *ngIf="isEndUser() && caseStats">
						<div class="title-group">
							<img src="/assets/icon/cases.svg" />
						</div>
						<div class="open-cases count">
							<div class="count-title">Open Cases</div>
							<div class="count-value">{{ caseStats.open }}</div>
						</div>
						<div class="resolved-cases count">
							<div class="count-title">Resolved</div>
							<div class="count-value">{{ caseStats.resolved }}</div>
						</div>
					</div>

					<div class="user-preferences white-box">
						<h2>
							User Preferences
						</h2>
						<form class="base-form">
							<div class="form-group-inline" *ngIf="isEndUser()">
								<label for="ability-to-assgin-cases">Ability to assign cases</label>
								<div class="form-switch">
									<input type="checkbox" id="ability-to-assgin-cases" [checked]="currentUser.can_assign_cases" (click)="toggleAssignCases()">
									<span class="slider round"></span>
								</div>
							</div>
							<div class="form-group-inline" *ngIf="isEndUser()">
								<label for="ability-to-view-case-information">Ability to view case information</label>
								<div class="form-switch">
									<input type="checkbox" id="ability-to-view-case-information" [checked]="currentUser.has_full_case_access" (click)="toggleViewCaseInfo()">
									<span class="slider round"></span>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		  </ng-container>
	  </main>
  </div>
</div>

<app-modal modalTitle="Add Branch Icon" class="modal fade" id="add-photo" tabindex="-1" role="dialog"
		   aria-labelledby="add-photo" aria-hidden="true">
	<div class="modal-body image-upload-body">
		<img src="/assets/icon/drop-photos-here-icon.svg">
		<input #imageInput type="file" name="logo" accept="image/*" (change)="onImageAdded()">
		<div class="sep-text">
			or
		</div>
	</div>
	<div class="modal-footer">
		<button #closeModal style="display:none;" data-dismiss="modal">Close</button>
		<app-button class="active" (click)="clickImageUpload()">Upload Photo</app-button>
	</div>
</app-modal>
