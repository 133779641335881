import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { CognitoAuthenticationService } from '../../services/cognito-authentication.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {

    public currentUser: User;
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private api: ApiClientService,
        private authService: CognitoAuthenticationService,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(me => this.currentUser = me);
    }

    public goToProfile() {
        this.router.navigate(['account']);
    }

    public logout() {
        this.authService.logout();
    }

}
