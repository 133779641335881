import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseOpenedEvent as ApiCaseOpenedEvent } from '@scaffold/mediccoms-api-client/models/case-opened-event';

export class CaseOpenedEvent extends CaseEvent {
    public type: string;
    public openedBy: User;

    public static mapFromApi(data: Partial<ApiCaseOpenedEvent>): Partial<CaseOpenedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            // openedBy: User.createOne(data.opened_by as any),
            openedBy: User.mapFromApi(data.opened_by) as User,
            sentAt: data.sent_at,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            sentAt: this.sentAt,
            openedBy: this.openedBy ? this.openedBy.id : null,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<CaseOpenedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.openedBy !== undefined) {
            this.openedBy = User.createOne(data.openedBy);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public fillFromApi(data: Partial<ApiCaseOpenedEvent>): this {
        this.fill(CaseOpenedEvent.mapFromApi(data));
        return this;
    }

    public get sentBy(): User {
        return this.openedBy;
    }
}
