// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  user_roles: [
    {
      name: 'End User',
      key: 'organisation_end_user',
      level: 10,
    },
    {
      name: 'Manager',
      key: 'organisation_manager',
      level: 20,
    },
    {
      name: 'Admin',
      key: 'organisation_admin',
      level: 30,
    },
    {
      name: 'System User',
      key: 'mediccoms_general',
      level: 40,
    },
    {
      name: 'System Admin',
      key: 'mediccoms_admin',
      level: 50,
    },
    {
      name: 'Developer',
      key: 'developer',
      level: 60,
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
