<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

    <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		<ng-container *ngIf="!organisation || !initiatingBranch">
			Loading...
		</ng-container>
		<ng-container *ngIf="organisation && initiatingBranch">
			<app-breadcrumb>
				<li class="breadcrumb-item" (click)="goToPage('organisations')" *can="'view'; target:'organisations'">
					Organisations
				</li>
				<li class="breadcrumb-item" aria-current="page" (click)="goToPage('organisations/' + organisation.id)">
					{{ organisation.name }}
				</li>
				<ng-container *ngIf="branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches')">
						Locations
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						{{ branch.name }}
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						Connections
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						Invites
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						{{ inviteId }}
					</li>
				</ng-container>
				<ng-container *ngIf="!branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/connections')">
						Connections
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/connections')">
						Invites
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/connections')">
						{{ inviteId }}
					</li>
				</ng-container>
				<li class="breadcrumb-item active" aria-current="page">
					Send Invite
				</li>
			</app-breadcrumb>
			<header class="d-flex">
				<h1 class="h1">Send Invite</h1>
			</header>

			<app-flash-messages></app-flash-messages>

			<div class="row d-flex justify-content-between">
				<div class="col-xl-5">
					<div class="connection white-box">
						<h2 class="connection-title">Send Invite to Recipient</h2>

						<p>This will send an email to the recipient of the invite, to allow them to connect to your branch:</p>

						<h2 class="mt-3 connection-title">
							Initiating Branch: {{ initiatingBranch.name }}
						</h2>

						<div class="connection-info">
							<div class="connection-info-label">
								Address
							</div>
							<address>
								{{ initiatingBranch.address.line_1 }} <br>
								{{ initiatingBranch.address.line_2 }} <br *ngIf="initiatingBranch.address.line_2">
								{{ initiatingBranch.address.town }} <br>
								{{ initiatingBranch.address.postcode }} <br>
							</address>
						</div>

						<div class="connection-info">
							<div class="connection-info-label">
								Primary Contact
							</div>
							<div class="connection-info-item">
								{{ initiatingBranch.primary_contact.name }}
							</div>
						</div>

						<div class="connection-info">
							<div class="connection-info-label">
								Contact Number
							</div>
							<div class="connection-info-item">
								<a [href]="'tel:' + initiatingBranch.primary_contact.phone_number">
									{{ initiatingBranch.primary_contact.phone_number }}
								</a>
							</div>
						</div>

						<div class="connection-info">
							<div class="connection-info-label">
								Email
							</div>
							<div class="connection-info-item">
								<a [href]="'mailto:' + initiatingBranch.primary_contact.email">
									{{ initiatingBranch.primary_contact.email }}
								</a>
							</div>
						</div>

						<div class="d-flex justify-content-between">
							<app-button class="active remove-connection small" (click)="sendInvite()">Send Invite</app-button>
						</div>
					</div>
				</div>
				<div class="col-xl-5">
					<div class="connection white-box">
						<h2 class="connection-title">Send Invite to Recipient</h2>
						<p>Here are the invite details to allow you to manually send an invite to someone:</p>
						<textarea class="form-control" disabled>{{ 'https://admin.mediccoms.com/connections/invites/' + this.inviteId + '/' + this.token }}</textarea>
					</div>
				</div>
			</div>
		</ng-container>
	</main>

  </div>
</div>
