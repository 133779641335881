/* tslint:disable:variable-name curly */
import { AbstractModel } from './abstract.model';
import { User } from './user';
import { Branch } from './branch';

export class Invite extends AbstractModel {

    public branch: Branch = null;
    public id: string = null;
    public organisation: string = null;
    public type: 'sent' | 'received' = null;

    public fill(data: Partial<Invite>) {
        if (data.branch) {
            this.branch = Branch.createOne(data.branch as any);
        }
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.organisation) {
            this.organisation = data.organisation;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
    }
}
