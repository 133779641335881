import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseAssignedEvent as ApiCaseAssignedEvent } from '@scaffold/mediccoms-api-client/models/case-assigned-event';

export class CaseAssignedEvent extends CaseEvent {
    public type: string;
    public assignedBy: User;
    public newAssignee: User;
    public previousAssignee: User;

    public static mapFromApi(data: Partial<ApiCaseAssignedEvent>): Partial<CaseAssignedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            // assignedBy: User.createOne(data.assigned_by as any),
            // newAssignee: User.createOne(data.new_assignee as any),
            // previousAssignee: User.createOne(data.previous_assignee as any),
            assignedBy: User.mapFromApi(data.assigned_by) as User,
            newAssignee: User.mapFromApi(data.new_assignee) as User,
            previousAssignee: User.mapFromApi(data.previous_assignee) as User,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            assignedBy: this.assignedBy ? this.assignedBy.id : null,
            newAssignee: this.newAssignee ? this.newAssignee.id : null,
            previousAssignee: this.newAssignee ? this.previousAssignee.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<CaseAssignedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.assignedBy !== undefined) {
            this.assignedBy = User.createOne(data.assignedBy);
        }
        if (data.newAssignee !== undefined) {
            this.newAssignee = User.createOne(data.newAssignee);
        }
        if (data.previousAssignee !== undefined) {
            this.previousAssignee = User.createOne(data.previousAssignee);
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public fillFromApi(data: Partial<ApiCaseAssignedEvent>): this {
        this.fill(CaseAssignedEvent.mapFromApi(data));
        return this;
    }

    public get sentBy(): User {
        return this.assignedBy;
    }
}
