import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { MessageStats as ApiMessageStats } from '@scaffold/mediccoms-api-client/models';
import { Observable, Subject } from 'rxjs';

export type MessageStats = {
    sent: number,
    received: number,
};

export type AllMessageStats = {
    all: MessageStats,
    directMessage: MessageStats,
    groupMessage: MessageStats,
};

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private directMessageStats: MessageStats = null;
    private groupMessageStats: MessageStats = null;

    private get allStats(): AllMessageStats {
        const stats: AllMessageStats = {
            all: null,
            directMessage: null,
            groupMessage: null,
        };

        stats.all = {
            sent: 0,
            received: 0,
        };
        if (this.directMessageStats) {
            stats.directMessage = this.directMessageStats,
            stats.all.sent = stats.all.sent + this.directMessageStats.sent;
            stats.all.received = stats.all.received + this.directMessageStats.received;
        }

        if (this.groupMessageStats) {
            stats.groupMessage = this.groupMessageStats,
            stats.all.sent = stats.all.sent + this.groupMessageStats.sent;
            stats.all.received = stats.all.received + this.groupMessageStats.received;
        }

        return stats;
    }

    private allStatsSubject: Subject<AllMessageStats> = new Subject<AllMessageStats>();

    constructor(
        private api: ApiClientService,
    ) {
    }

    public async getDirectMessageStats(organisationId: string): Promise<MessageStats> {
        let response;
        if (organisationId) {
            response = await this.api.organisations.getDirectMessageStats(organisationId).toPromise();
        } else {
            response = await this.api.directMessages.getStats().toPromise();
        }
        this.directMessageStats = response.stats;
        this.allStatsSubject.next(this.allStats);
        return this.directMessageStats;
    }

    public async getGroupMessageStats(organisationId: string): Promise<MessageStats> {
        let response;
        if (organisationId) {
            response = await this.api.organisations.getGroupMessageStats(organisationId).toPromise();
        } else {
            response = await this.api.groupMessages.getStats().toPromise();
        }
        this.groupMessageStats = response.stats;
        this.allStatsSubject.next(this.allStats);
        return this.groupMessageStats;
    }

    public fetchAllStats(organisationId: string = null): void {
        this.getDirectMessageStats(organisationId);
        this.getGroupMessageStats(organisationId);
    }

    public getStats(): Observable<AllMessageStats> {
        return this.allStatsSubject.asObservable();
    }
}
