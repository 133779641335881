import { CaseEvent } from './case-event';
import { User } from '../user';
import { CaseAcceptedEvent as ApiCaseAcceptedEvent } from '@scaffold/mediccoms-api-client/models/case-accepted-event';

export class CaseAcceptedEvent extends CaseEvent {
    public type: string;
    public acceptedAt: string;
    public acceptedBy: User;

    public static mapFromApi(data: Partial<ApiCaseAcceptedEvent>): Partial<CaseAcceptedEvent> {
        if (!data) {
            return null;
        }

        return {
            type: data.type,
            sentAt: data.sent_at,
            acceptedAt: data.accepted_at,
            // acceptedBy: User.createOne(data.accepted_by as any),
            acceptedBy: User.mapFromApi(data.accepted_by) as User,
        };
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            acceptedAt: this.acceptedAt,
            acceptedBy: this.acceptedBy ? this.acceptedBy.id : null,
            sentAt: this.sentAt,
            conversation: this.conversation,
        };
    }

    public fill(data: Partial<CaseAcceptedEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.acceptedAt !== undefined) {
            this.acceptedAt = data.acceptedAt;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public fillFromApi(data: Partial<ApiCaseAcceptedEvent>): this {
        this.fill(CaseAcceptedEvent.mapFromApi(data));
        return this;
    }

    public get sentBy(): User {
        return this.acceptedBy;
    }
}
