import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from '../../services/menu.service';
import { CaseStats } from '../../models/case-stats';
import { CaseService } from '../cases/cases.service';
import { BranchesService } from '../branches/branches.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { subDays } from 'date-fns';
import { Branch } from '../../models/branch';

export type BranchStat = {
    branch: Branch,
    stats: CaseStats,
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.scss']
})
export class DashboardPage implements OnInit, OnDestroy {

    public caseStats: CaseStats = null;
    public currentUser: User;
    private isDestroyed: Subject<void> = new Subject<void>();

    public branchStats: { [key: string]: BranchStat } = {};
    // public branchStats: { id: string, stats: BranchStat }[] = [];

    public dateAfter: string = null;
    public dateBefore: string = null;

    constructor(
        private branchService: BranchesService,
        private caseService: CaseService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {

        this.menuService.setActive('dashboard');

        this.caseService.getCaseStats().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(stats => this.caseStats = stats);

        this.branchService.getAllBranches().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(async branches => {
            for (const branch of branches) {
                const stats = await this.caseService.fetchBranchStats(branch.id);
                this.branchStats[branch.id] = {branch, stats};
            }
        });

        this.userService.getMe()
            .pipe(takeUntil(this.isDestroyed))
            .subscribe(me => {
                this.currentUser = me;
                if (this.currentUser?.organisation_id) {
                    this.branchService.fetchAllBranches(this.currentUser.organisation_id);
                }
            });

        this.caseService.searchCases(1, {
            after: subDays(new Date(), 30).toISOString(),
        });
    }

    ngOnDestroy(): void {
        this.menuService.setActive(null);
    }

    public setDateAfter(value: string) {
        this.dateAfter = value;
    }

    public setDateBefore(value: string) {
        this.dateBefore = value;
    }

}
