import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../../../models/user';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { OrganisationsService } from '../organisations.service';
import { Organisation } from '../../../models/organisation';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRole } from '../../members/members';
import { MembersService } from '../../members/members.service';
import { environment } from '../../../../environments/environment';
import { FlashMessageService } from '../../../services/flash-message.service';
import { MenuService } from '../../../services/menu.service';
import { AttachmentService } from '../../../services/attachment.service';

@Component({
    selector: 'app-organisations-view',
    templateUrl: './organisations-view.html',
    styleUrls: ['./organisations-view.scss']
})
export class OrganisationsViewPage implements OnInit, OnDestroy, AfterViewInit {

    public organisation: Organisation = null;
    public organisationId: string = null;
    private _page: number = null;
    private _searchTerm: string = null;
    public selectedRole: UserRole = null;
    private currentUser: User = null;
    private isDestroyed: Subject<void> = new Subject<void>();
    private pageChanged: Subject<number> = new Subject<number>();
    private searchTermChanged: Subject<string> = new Subject<string>();
    public notificationType: string = null;
    public members: User[] = [];
    public userRoles: UserRole[];
    public totalMembers = 0;
    public totalPages = 0;

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;


    public get page(): number {
        return this._page;
    }

    public set page(value: number) {
        this._page = value;
        this.pageChanged.next(value);
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        this._searchTerm = value;
        this.searchTermChanged.next(value);
    }

    constructor(
        private attachmentService: AttachmentService,
        private flashMessageService: FlashMessageService,
        private membersService: MembersService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
        this.menuService.setActive(null);
    }

    public ngOnInit(): void {
        this.menuService.setActive('organisations');

        this.membersService.getMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((members) => this.members = members);

        this.membersService.getTotalMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalMembers = total);

        this.membersService.getCurrentPage().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.page = currentPage);

        this.membersService.getTotalPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalPages = totalPages);

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(params.organisation_id);
                this.membersService.searchMembers(null, params.organisation_id);
            }
        });

        this.searchTermChanged.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(() => {
            this.page = 1;
            this.membersService.searchMembers(this.searchTerm, this.organisation.id);
        });

        this.pageChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.membersService.getPage(page));

        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                this.userRoles = [];
                const userRoles = environment.user_roles;

                for (const role of userRoles) {
                    if (this.currentUser.can('seeUserRole', role)) {
                        this.userRoles.push(role);
                    }
                }
            }
        });
    }

    public allOrganisations() {
        this.router.navigate(['organisations']);
    }

    public async removeOrganisation() {
        try {
            const response = await this.organisationService.deleteOrganisation(this.organisationId);
            if (response.deleted) {
                this.flashMessageService.addMessage('success', 'Organisation Removed!');
                await this.router.navigate(['/organisations/']);
            } else {
                throw new Error('An Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', e);
        }
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public showMember(member: User) {
        this.router.navigate(['members', member.id]);
    }

    public filterMembers(selectedRole?: string) {
        if (selectedRole) {
            this.selectedRole = this.userRoles.find(role => role.key === selectedRole);
        } else {
            this.selectedRole = null;
        }
        this.membersService.filterByRole(selectedRole);
    }

    public toggleMessages() {
        this.organisation.messaging_enabled = !this.organisation.messaging_enabled;
    }

    public toggleCases() {
        this.organisation.cases_enabled = !this.organisation.cases_enabled;
    }

    public toggleEnabled() {
        this.organisation.enabled = !this.organisation.enabled;
    }

    public async saveChanges() {
        try {
            const response = await this.organisationService.updateOrganisation(this.organisation);
            if (response.organisation) {
                this.flashMessageService.showMessage('success', 'Organisation Successfully Updated!');
            } else {
                throw new Error('An Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', e);
        }
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.organisation.image = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }
}
