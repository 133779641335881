<app-header></app-header>

<div class="container-fluid">
	<div class="row">

		<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

		<main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
			<ng-container *ngIf="!organisation || !case">
				Loading...
			</ng-container>
			<ng-container *ngIf="organisation && case">
				<app-breadcrumb>
					<li class="breadcrumb-item" (click)="goToPage('organisations')" *can="'view'; target:'organisations'">
						Organisations
					</li>
					<li class="breadcrumb-item" aria-current="page" (click)="goToPage('organisations/' + organisation.id)">
						{{ organisation.name }}
					</li>
					<ng-container *ngIf="branchId">
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/branches')">
							Locations
						</li>
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
							{{ branch.name }}
						</li>
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId + '/cases')">
							Cases
						</li>
					</ng-container>
					<ng-container *ngIf="!branchId">
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/cases')">
							Cases
						</li>
					</ng-container>
					<li class="breadcrumb-item active" aria-current="page">
						Case {{ case.case_number }}
					</li>
				</app-breadcrumb>
				<header class="d-flex">
					<h1 class="h1">Case {{ case.case_number }}</h1>
				</header>

				<app-flash-messages></app-flash-messages>

				<div class="row d-flex justify-content-between">
					<div class="col-xl-5">
						<div class="case white-box mt-0">

							<h2 class="case-title">
								Details
							</h2>

							<div class="case-info">
								<div class="case-info-label">
									Created: <br>
								</div>
								<div class="case-info-item">
									{{ case.created_at | dateFns: 'dd LLL y @ HH:mm' }}
								</div>
							</div>
							<div class="case-info">
								<div class="case-info-label">
									Opened: <br>
								</div>
								<div class="case-info-item">
									<span *ngIf="case.opened_at">{{ case.opened_at | dateFns: 'dd LLL y @ HH:mm' }}</span>
									<div class="status-pill resolved" *ngIf="!case.opened_at">Not Opened</div>
								</div>
							</div>
							<div class="case-info">
								<div class="case-info-label">
									Accepted: <br>
								</div>
								<div class="case-info-item">
									<span *ngIf="case.accepted_at">{{ case.accepted_at | dateFns: 'dd LLL y @ HH:mm' }}</span>
									<div class="status-pill resolved" *ngIf="!case.accepted_at">Not Accepted</div>
								</div>
							</div>
							<div class="case-info" [class.border-bottom]="!case.isOpen">
								<div class="case-info-label">
									Resolved: <br>
								</div>
								<div class="case-info-item">
									<span *ngIf="case.resolved_at">{{ case.resolved_at | dateFns: 'dd LLL y @ HH:mm' }}</span>
									<div class="status-pill resolved" *ngIf="!case.resolved_at">Not Resolved</div>
								</div>
							</div>
							<div class="case-info border-bottom" *ngIf="case.isOpen">
								<div class="case-info-label">
									Duration: <br>
								</div>
								<div class="case-info-item">
									<span *ngIf="case.isOpen">{{ case.opened_at | distanceToNow: true }}</span>
									<span *ngIf="case.isResolved">{{ case.resolved_at | distanceBetween: case.opened_at: true }}</span>
								</div>
							</div>

							<div class="case-info">
								<div class="case-info-label">
									Case No: <br>
								</div>
								<div class="case-info-item">
									{{ case.case_number }}
								</div>
							</div>

							<div class="case-info border-bottom">
								<div class="case-info-label">
									Patient ID: <br>
								</div>
								<div class="case-info-item">
									{{ case.patient_id }}
								</div>
							</div>

							<div class="case-info">
								<h4>Correspondents</h4>
								<ul>
									<li *ngIf="case.isAssigned">{{ case.getCorrespondentUser(this.organisationId).fullNameWithTitle }}
										<div class="status-pill resolved">Assigned</div>
									</li>
									<li *ngIf="!case.isAssigned"><div class="status-pill resolved ml-0">Unassigned</div></li>
									<li>{{ case.getCorrespondentBranch(this.organisationId).name }}</li>
								</ul>
							</div>

						</div>
					</div>

					<div class="col-xl-5">
						<app-case-messenger [conversation]="case"></app-case-messenger>
					</div>
				</div>
			</ng-container>
		</main>

	</div>
</div>
