import { NgModule } from '@angular/core';
import { CanDirective } from './CanDirective';
import { CannotDirective } from './CannotDirective';

@NgModule({
    imports: [],
    exports: [
        CanDirective,
        CannotDirective,
    ],
    declarations: [
        CanDirective,
        CannotDirective,
    ],
    providers: []
})
export class DirectivesModule {}
