<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu *can="'viewComponent'; target:'sidebar'" id="sidebarMenu" class="col-md-3 col-xl-2 d-md-block sidebar collapse"></app-side-menu>

    <main *can="'viewComponent'; target:'dashboard'" role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		<h1 *ngIf="currentUser" class="h1">Welcome, {{ currentUser.fullNameWithTitle }}</h1>

		<app-flash-messages></app-flash-messages>

		<div class="row" *ngIf="caseStats">
			<div class="col-xl-3">
				<app-report-stats [caseStats]="caseStats"></app-report-stats>
			</div>
			<div class="col-xl-9">
				<div class="row">
					<div class="col-lg-6">
						<app-chart chartTitle="Cases Resolved Last 30 Days" xLabel="Date" [chartData]="caseStats.monthly" chartType="line"></app-chart>
					</div>
					<div class="col-lg-6">
						<app-chart chartTitle="Cases Resolved Last 24 Hours" xLabel="Time" [chartData]="caseStats.daily" chartType="line"></app-chart>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-2" *ngIf="branchStats">
			<div class="col-xl-3 mb-2" *ngFor="let item of branchStats | keyvalue">
				<div class="white-box p-4">
					<h5 class="mb-4">{{ item.value.branch.name }}</h5>
					<app-report-stats [caseStats]="item.value.stats" color="magnolia"></app-report-stats>
				</div>
			</div>
		</div>
	</main>
  </div>
</div>
