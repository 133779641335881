<div class="white-bg-content table-responsive">
    <div class="additional-filters">
        <div class="radio-group">
            <input type="radio" (click)="selectView('connections')" [checked]="isChecked('connections')" name="radio-filters">
            <div class="active button">Connections</div>
        </div>
        <div class="radio-group">
            <input type="radio" (click)="selectView('invites_received')" [checked]="isChecked('invites_received')" name="radio-filters">
            <div class="button">Received Requests</div>
        </div>
        <div class="radio-group">
            <input type="radio" (click)="selectView('invites_sent')" [checked]="isChecked('invites_sent')" name="radio-filters">
            <div class="button">Sent Requests</div>
        </div>
    </div>
    <ng-container *ngIf="isChecked('connections')">
        <div class="table-filters">
            <div class="table-count">
                {{ totalConnections }} Connections
            </div>
            <app-connection-filter [(searchTerm)]="searchTerm" [(sortBy)]="orderBy"></app-connection-filter>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Branch Name</th>
                <th>Primary Contact</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let connection of connections">
                <td>
                    <div class="member">
                        <div class="image profile-avatar member-avatar" *ngIf="connection.image">
                            <span class="profile-img" [style.backgroundImage]="connection.image | attachment: 'profile_small' | backgroundImage"></span>
                        </div>
                        <div class="member-avatar profile-avatar-fallback" *ngIf="!connection.image">
                            {{ connection.initials }}
                        </div>
                    </div>
                </td>
                <td>
                    {{ connection.branch?.name }}
                </td>
                <td>
                    {{ connection.branch?.primary_contact.name }}
                </td>
                <td>
                    {{ connection.branch?.primary_contact.phone_number }}
                </td>
                <td>
                    {{ connection.branch?.address.line_1 }} {{ connection.branch?.address.line_2 }} {{ connection.branch?.address.town }} {{ connection.branch?.address.postcode }}
                </td>
                <td>
                    <div class="action-view action-button" (click)="viewConnection(connection.id)">
                        View
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <app-pagination [(currentPage)]="pageConnections" [totalPages]="totalConnectionsPages" [totalItems]="totalConnections"></app-pagination>
    </ng-container>

    <ng-container *ngIf="isChecked('invites_sent')">
        <div class="table-filters">
            <div class="table-count">
                {{ totalSentInvites }} Invites Sent
            </div>
            <app-connection-filter [(searchTerm)]="searchTerm" [(sortBy)]="orderBy"></app-connection-filter>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Branch Name</th>
                <th>Primary Contact</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let invite of invitesSent">
                <td>
                    <div class="member">
                        <div class="image profile-avatar member-avatar" *ngIf="invite.branch.image">
                            <span class="profile-img" [style.backgroundImage]="invite.branch.image | attachment: 'profile_small' | backgroundImage"></span>
                        </div>
                        <div class="member-avatar profile-avatar-fallback" *ngIf="!invite.branch.image">
                            {{ invite.branch.initials }}
                        </div>
                    </div>
                </td>
                <td>
                    {{ invite.branch?.name }}
                </td>
                <td>
                    {{ invite.branch?.primary_contact.name }}
                </td>
                <td>
                    {{ invite.branch?.primary_contact.phone_number }}
                </td>
                <td>
                    {{ invite.branch?.address.line_1 }} {{ invite.branch?.address.line_2 }} {{ invite.branch?.address.town }} {{ invite.branch?.address.postcode }}
                </td>
                <td>
                    <div class="action-view action-button" (click)="sendInvite(invite.id)">
                        Send
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <app-pagination [(currentPage)]="pageSentInvites" [totalPages]="totalSentInvitesPages" [totalItems]="totalSentInvites"></app-pagination>
    </ng-container>

    <ng-container *ngIf="isChecked('invites_received')">
        <div class="table-filters">
            <div class="table-count">
                {{ totalReceivedInvites }} Invites Received
            </div>
            <app-connection-filter [(searchTerm)]="searchTerm" [(sortBy)]="orderBy"></app-connection-filter>
        </div>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Branch Name</th>
                <th>Primary Contact</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let invite of invitesReceived">
                <td>
                    <div class="member">
                        <div class="image profile-avatar member-avatar" *ngIf="invite.branch.image">
                            <span class="profile-img" [style.backgroundImage]="invite.branch.image | attachment: 'profile_small' | backgroundImage"></span>
                        </div>
                        <div class="member-avatar profile-avatar-fallback" *ngIf="!invite.branch.image">
                            {{ invite.branch.initials }}
                        </div>
                    </div>
                </td>
                <td>
                    {{ invite.branch?.name }}
                </td>
                <td>
                    {{ invite.branch?.primary_contact.name }}
                </td>
                <td>
                    {{ invite.branch?.primary_contact.phone_number }}
                </td>
                <td>
                    {{ invite.branch?.address.line_1 }} {{ invite.branch?.address.line_2 }} {{ invite.branch?.address.town }} {{ invite.branch?.address.postcode }}
                </td>
                <td>
                    <div class="action-view action-button" (click)="viewInvite(invite.id)">
                        View
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <app-pagination [(currentPage)]="pageReceivedInvites" [totalPages]="totalReceivedInvitesPages" [totalItems]="totalReceivedInvites"></app-pagination>
    </ng-container>
</div>
