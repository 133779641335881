<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

    <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">

		<app-breadcrumb>
			<li class="breadcrumb-item" *can="'view'; target:'organisations'">
				Organisations
			</li>
		</app-breadcrumb>

		<header class="d-flex">
			<h1 class="h1">Organisations</h1>
			<app-button class="active" routerLink="/organisations/new">Add Organisation</app-button>
		</header>

		<app-flash-messages></app-flash-messages>

		<div class="white-bg-content table-responsive">
			<div class="table-filters">
				<div class="table-count">
					{{ totalOrganisations }} {{ totalOrganisations === 1 ? 'Organisation' : 'Organisations' }}
				</div>
			</div>
			<table class="table">
				<thead>
					<tr>
						<th></th>
						<th>Organisation Name</th>
						<th>Primary Contact</th>
						<th>Contact Number</th>
						<th>Address</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let organisation of organisations">
						<td>
							<div class="member">
								<div class="image profile-avatar member-avatar" *ngIf="organisation.image">
									<span class="profile-img" [style.backgroundImage]="organisation.image | attachment: 'profile_small' | backgroundImage"></span>
								</div>
								<div class="member-avatar profile-avatar-fallback" *ngIf="!organisation.image">
									{{ organisation.initials }}
								</div>
							</div>
						</td>
						<td>
							{{ organisation.name }}
						</td>
						<td>{{ organisation.primary_contact.name }}</td>
						<td>{{ organisation.primary_contact.phone_number }} </td>
						<td>{{ organisation.address.line_1}} {{ organisation.address.line_2}} {{ organisation.address.postcode}}</td>
						<td>
							<div class="action-edit action-button" routerLink="/organisations/{{ organisation.id }}">
								<svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g transform="translate(-358.000000, -295.000000)" class="fill" fill="#232323">
											<g transform="translate(358.000000, 295.000000)">
												<g>
													<path d="M18.6968203,-0.00189077605 C17.5921722,-0.00189077605 16.4861938,0.421384999 15.6458443,1.26174183 L0.681595118,16.2259828 C0.561925728,16.3457082 0.488340377,16.5038204 0.473799043,16.6724724 L0.0020420778,22.2282505 C-0.0151228874,22.4385952 0.0610007178,22.6458584 0.210230636,22.7950896 C0.359460555,22.9443208 0.566723149,23.0204462 0.777067961,23.003283 L6.33285292,22.5357394 C6.50156292,22.5197362 6.65919672,22.4446502 6.77793301,22.3237334 L21.743584,7.35948435 C23.4242901,5.67877884 23.4242901,2.94245008 21.743584,1.26174455 C20.9032275,0.421384999 19.8014617,-0.00189077605 18.6968203,-0.00189077605 Z M18.6968203,1.42601051 C19.4295554,1.42601051 20.1623263,1.7079136 20.7298651,2.27545292 C21.8649432,3.41053159 21.8649432,5.21068374 20.7298651,6.34576238 L6.27247535,20.8031532 L2.20918942,16.7370551 L16.6651776,2.27545292 C17.2327166,1.7079136 17.9640856,1.42601051 18.6968203,1.42601051 Z M1.77674723,18.3404679 L4.67187269,21.2327894 L1.50857356,21.5009554 L1.77674723,18.3404679 Z" id="Shape"></path>
												</g>
											</g>
										</g>
									</g>
								</svg>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<app-pagination [(currentPage)]="page" [totalPages]="totalPages" [totalItems]="totalOrganisations"></app-pagination>
		</div>

	</main>

  </div>
</div>

