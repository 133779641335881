import { Injectable, OnDestroy } from '@angular/core';
import { FlashMessage } from '../models/flash-message';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MenuService implements OnDestroy {

    private isDestroyed: Subject<void> = new Subject<void>();
    private activePageSubject: Subject<string> = new Subject<string>();
    private activePage: string = null;

    constructor() {
    }

    public setActive(page: string) {
        this.activePageSubject.next(page);
        this.activePage = page;
    }

    public getActive(): Observable<string> {
        return new Observable<string>((observer) => {
            observer.next(this.activePage);
            this.activePageSubject.asObservable().pipe(
                takeUntil(this.isDestroyed)
            ).subscribe(value => {
                observer.next(value);
            });
        });
    }

    public ngOnDestroy() {
        this.isDestroyed.next();
    }
}
