import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../models/user';
import { CaseConversation } from '../../models/case-conversation';
import { Branch } from '../../models/branch';
import { UserService } from '../../services/user.service';
import { CaseMessageEvent } from '../../models/case-conversation-events/case-message-event';
import { MembersService } from '../../pages/members/members.service';

@Component({
    selector: 'app-case-message',
    templateUrl: 'case-message.html',
    styleUrls: ['message.scss', 'case-message.scss'],
})
export class AppCaseMessageComponent implements OnChanges, OnDestroy, OnInit {

    @Input() public conversation: CaseConversation = null;
    @Input() public event: CaseMessageEvent = null;

    private isDestroyed: Subject<void> = new Subject<void>();

    public attachment: string = null;
    public currentUser: User = null;
    public senderUser: User = null;

    public get isSentByMe(): boolean {
        return this.event?.sentBy?.id === this.currentUser?.id;
    }

    public get isSentByMyOrganisation(): boolean {
        return this.senderUser?.organisation_id === this.currentUser?.organisation_id;
    }

    public get isSentByRecipient(): boolean {
        return !this.isSentByMe && !this.isSentByMyOrganisation;
    }

    public get sender(): User | Branch {
        const currentUserId = this.currentUser?.organisation_id;
        // const recipientBranchId = this.conversation?.recipientBranch?.organisation?.id;
        // const branch = currentUserId === recipientBranchId ? this.conversation.senderBranch : this.conversation.recipientBranch;
        // return this.isSentByMyOrganisation ? this.senderUser || null : branch || null;
        return this.senderUser;
    }

    constructor(
        // private caseMessaging: CaseMessagingService,
        // private attachments: AttachmentService,
        private router: Router,
        private memberService: MembersService,
        private userService: UserService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('event' in changes) {
            const event = changes.event.currentValue;
            if (event.attachments && event.attachments.length) {
                this.attachment = event.attachments[0];
            }
            this.memberService.getMemberById(event.sentBy?.id);
        }
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
    }

    public async ngOnInit() {
        if (this.event?.sentBy?.id) {
            this.senderUser = await this.memberService.fetchMember(this.event?.sentBy?.id);
            // .pipe(takeUntil(this.isDestroyed)).subscribe(user =>  = user);
            this.userService.getMe().pipe(
                takeUntil(this.isDestroyed)
            ).subscribe(me => this.currentUser = me);
        }
    }

    public getMessageClasses() {
        const classes: string[] = [];
        const message = this.event;
        const sentByMe = message.sentBy?.id === this.currentUser?.id;

        switch (message.status) {
            case 'sent':
            case 'delivered':
                classes.push(sentByMe ? 'sent-message' : 'none');
                break;
            case 'read':
                classes.push(sentByMe ? 'seen-message' : 'none');
                break;
            default:
                classes.push('opened-message');
                break;
        }

        return classes;
    }

}
