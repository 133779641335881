<app-header></app-header>

<div class="container-fluid">
  <div class="row">

	<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

    <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
		<ng-container *ngIf="!isReady">
			Loading...
		</ng-container>
		<ng-container *ngIf="isReady">
			<app-breadcrumb>

				<ng-container *ngIf="organisation && organisationId">
					<li class="breadcrumb-item" (click)="goToPage('organisations')"
						*can="'view'; target:'organisations'">
						Organisations
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId)">
						{{ organisation.name }}
					</li>
				</ng-container>
				<ng-container *ngIf="branchId">
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches')">
						Locations
					</li>
					<li class="breadcrumb-item" aria-current="page"
						(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
						{{ branch.name }}
					</li>
				</ng-container>

				<li class="breadcrumb-item">Members</li>
				<li class="breadcrumb-item active" aria-current="page">{{ memberProfile.fullNameWithTitle }}</li>
			</app-breadcrumb>

			<app-flash-messages></app-flash-messages>

			<h1 class="h1">Edit User</h1>
			<div class="row" >
				<div class="col-xl-5">

					<div class="profile-options white-box">
						<div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="memberProfile.image">
							<span class="add-icon">
								<img src="assets/icon/purple-edit-icon.svg" />
							</span>
							<span class="profile-img" [style.backgroundImage]="memberProfile.image | attachment: 'profile_small' | backgroundImage"></span>
						</div>
						<div data-toggle="modal" data-target="#add-photo" class="image profile-avatar profile-avatar-fallback large" *ngIf="!memberProfile.image">
							<span class="add-icon">
								<img src="assets/icon/purple-add-icon.svg" />
							</span>
							<span class="profile-img">{{ memberProfile.initials }}</span>
						</div>
						<form class="base-form">
							<input type="hidden" [(ngModel)]="memberProfile.can_assign_cases" name="assign_cases">
							<input type="hidden" [(ngModel)]="memberProfile.has_full_case_access" name="view_full_case">

							<div class="form-group">
								<label for="user_title">Title</label>
								<input type="text" class="form-control" id="user_title"
									   aria-describedby="user_title" placeholder="Enter Title"
									   [(ngModel)]="memberProfile.title" name="user_title">
							</div>

							<div class="form-group">
								<label for="user_first_name">First Name</label>
								<input type="text" class="form-control" id="user_first_name"
									   aria-describedby="user_first_name" placeholder="Enter First Name"
									   [(ngModel)]="memberProfile.first_name" name="user_first_name">
							</div>

							<div class="form-group">
								<label for="user_last_name">Last Name</label>
								<input type="text" class="form-control" id="user_last_name"
									   aria-describedby="user_last_name" placeholder="Enter First Name"
									   [(ngModel)]="memberProfile.last_name" name="user_last_name">
							</div>

							<div class="form-group">
								<label for="user_email">Email</label>
								<input type="text" class="form-control" id="user_email"
									   aria-describedby="user_email" placeholder="Enter Email"
									   [(ngModel)]="memberProfile.email" name="user_email">
							</div>

							<div class="form-group">
								<label for="user_contact_number">Mobile Number (MFA)</label>
								<input type="text" class="form-control" id="user_contact_number"
									   aria-describedby="user_contact_number" placeholder="Enter Mobile Number"
									   [(ngModel)]="memberProfile.phone_number" name="user_contact_number">
							</div>

							<div class="form-group">
								<label for="user_job_title">Job Title</label>
								<input type="text" class="form-control" id="user_job_title"
									   aria-describedby="user_job_title" placeholder="Enter Job Title"
									   [(ngModel)]="memberProfile.job_title" name="user_job_title">
							</div>

							<div class="form-group">
								<label for="user_role">Role</label>
								<select class="form-control" id="user_role"
										[(ngModel)]="memberProfile.role"
										[ngModelOptions]="{ standalone: true }">
									<option disabled [value]="null">Select Role</option>
									<option *ngFor="let role of userRoles"
											[value]="role.key">{{ role.name }}</option>
								</select>
							</div>

							<ng-container *can="'view'; target:'organisations'">
								<div class="form-group" *ngIf="memberProfile.organisation_id">
									<label for="user_organisation">Organisation</label>
									<select [disabled]="true" class="form-control"
											id="user_organisation" [(ngModel)]="memberProfile.organisation_id"
											[ngModelOptions]="{ standalone: true }">
										<option disabled [value]="null">Select Organisation</option>
										<option *ngFor="let organisation of organisations"
												[value]="organisation.id">{{ organisation.name }}</option>
									</select>
								</div>
							</ng-container>

							<div class="form-group" *ngIf="memberProfile.organisation_id">
								<label for="user_branch">Locations (* must be a member of at least one location)</label>
								<ng-select [disabled]="!userOrganisation && !!branchId" [(ngModel)]="userBranch"
										   id="user_branch" [ngModelOptions]="{ standalone: true }" [multiple]="true"
										   placeholder="Select Location / Locations">
									<ng-option *ngFor="let branch of branches" [value]="branch.id">{{branch.name}}</ng-option>
								</ng-select>
							</div>

                            <div class="form-group form-group-inline">
                                <label for="suspend-account">Member {{ memberProfile.isActive ? 'Enabled' : 'Disabled' }}</label>
                                <div class="form-switch">
									<input type="checkbox" id="suspend-account" [checked]="memberProfile.isActive" (click)="toggleIsActive()">
                                    <span class="slider round"></span>
                                </div>
                            </div>

							<div class="form-group-inline">
								<label>Reset Password</label>
								<app-button class="active small" (click)="resetPassword()">Reset</app-button>
							</div>
						</form>
					</div>
					<app-button class="active remove-member small" (click)="saveChanges()">Save Changes</app-button>
					<app-button class="red remove-member small" data-toggle="modal" data-target="#remove-member-account">Remove Member</app-button>
				</div>
				<div class="col-xl-7">

					<div class="cases-widget" *ngIf="isEndUser() && caseStats">
						<div class="title-group">
							<img src="/assets/icon/cases.svg" />
						</div>
						<div class="open-cases count">
							<div class="count-title">Open Cases</div>
							<div class="count-value">{{ caseStats.open }}</div>
						</div>
						<div class="resolved-cases count">
							<div class="count-title">Resolved</div>
							<div class="count-value">{{ caseStats.resolved }}</div>
						</div>
					</div>


					<div class="user-preferences white-box">
						<h2>
							User Preferences
						</h2>
						<form class="base-form">
							<div class="form-group-inline" *ngIf="isEndUser()">
								<label for="ability-to-assgin-cases">Ability to assign cases</label>
								<div class="form-switch">
									<input type="checkbox" id="ability-to-assgin-cases" [checked]="memberProfile.can_assign_cases" (click)="toggleAssignCases()">
									<span class="slider round"></span>
								</div>
							</div>
							<div class="form-group-inline" *ngIf="isEndUser()">
								<label for="ability-to-view-case-information">Ability to view case information</label>
								<div class="form-switch">
									<input type="checkbox" id="ability-to-view-case-information" [checked]="memberProfile.has_full_case_access" (click)="toggleViewCaseInfo()">
									<span class="slider round"></span>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</ng-container>
	</main>

  </div>
</div>

<app-modal modalTitle="Remove Member Account" class="modal modal-remove fade" id="remove-member-account" tabindex="-1" role="dialog" aria-labelledby="remove-member-account" aria-hidden="true">
	<div class="modal-body">
		<p class="sub-header">Are you sure you want to remove this member?</p>
		<p class="note">All details for this member will be removed from your organisation.</p>
	</div>
	<div class="modal-footer">
	  <app-button class="transparent" data-dismiss="modal">Cancel</app-button>
	  <app-button class="active" data-dismiss="modal" (click)="removeMember()">Remove</app-button>
	</div>
</app-modal>

<app-modal modalTitle="Add Profile Photo" class="modal fade" id="add-photo" tabindex="-1" role="dialog"
		   aria-labelledby="add-photo" aria-hidden="true">
	<div class="modal-body image-upload-body">
		<img src="/assets/icon/drop-photos-here-icon.svg">
		<input #imageInput type="file" name="logo" accept="image/*" (change)="onImageAdded()">
		<div class="sep-text">
			or
		</div>
	</div>
	<div class="modal-footer">
		<button #closeModal style="display:none;" data-dismiss="modal">Close</button>
		<app-button class="active" (click)="clickImageUpload()">Upload Photo</app-button>
	</div>
</app-modal>
