<nav class="sidebar-sticky">
	<ul class="nav flex-column">
		<li *can="'accessSidebar'; target:'dashboard'" class="nav-item nav-item-dashboard">
			<a class="nav-link" [class.active]="isActive('dashboard')" (click)="showPage('dashboard')">
				<svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-84.000000, -154.000000)" class="fill" fill="#232323">
							<g transform="translate(84.000000, 154.000000)">
								<g>
									<path d="M20.7272727,0 L3.27272727,0 C1.46524991,0 0,1.46524991 0,3.27272727 L0,16.3636364 C0,18.1711137 1.46524991,19.6363636 3.27272727,19.6363636 L20.7272727,19.6363636 C22.5347501,19.6363636 24,18.1711137 24,16.3636364 L24,3.27272727 C24,1.46524991 22.5347501,0 20.7272727,0 Z M21.8181818,16.3636364 C21.8181818,16.9661288 21.3297652,17.4545455 20.7272727,17.4545455 L3.27272727,17.4545455 C2.67023482,17.4545455 2.18181818,16.9661288 2.18181818,16.3636364 L2.18181818,3.27272727 C2.18181818,2.67023482 2.67023482,2.18181818 3.27272727,2.18181818 L20.7272727,2.18181818 C21.3297652,2.18181818 21.8181818,2.67023482 21.8181818,3.27272727 L21.8181818,16.3636364 Z" id="Shape" fill-rule="nonzero"></path>
									<path d="M7.63636364,9.18939588 C7.03387118,9.18939588 6.54545455,9.21568936 6.54545455,9.81818182 L6.54545455,14.1818182 C6.54545455,14.7843106 7.03387118,14.8296748 7.63636364,14.8296748 C8.23885609,14.8296748 8.72727273,14.7843106 8.72727273,14.1818182 L8.72727273,9.81818182 C8.72727273,9.21568936 8.23885609,9.18939588 7.63636364,9.18939588 Z" id="Path" fill-rule="nonzero"></path>
									<path d="M12,7.0272561 C11.3975075,7.0272561 10.9090909,7.03387118 10.9090909,7.63636364 L10.9090909,14.1818182 C10.9090909,14.7843106 11.3975075,14.8296748 12,14.8296748 C12.6024925,14.8296748 13.0909091,14.7843106 13.0909091,14.1818182 L13.0909091,7.63636364 C13.0909091,7.03387118 12.6024925,7.0272561 12,7.0272561 Z" id="Path" fill-rule="nonzero"></path>
									<path d="M16.3636364,5.02348914 C15.7611439,5.02348914 15.2727273,5.02348914 15.2727273,5.45454545 L15.2727273,14.1818182 C15.2727273,14.7843106 15.7611439,14.8296748 16.3636364,14.8296748 C16.9661288,14.8296748 17.4545455,14.7843106 17.4545455,14.1818182 L17.4545455,5.45454545 C17.4545455,5.02348914 16.9661288,5.02348914 16.3636364,5.02348914 Z" id="Path" fill-rule="nonzero"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Dashboard <span class="sr-only">(current)</span>
			</a>
		</li>
		<li *can="'accessSidebar'; target:'organisations'" class="nav-item nav-item-branches" >
			<a class="nav-link" [class.active]="isActive('organisations')" (click)="showPage('organisations')">
				<svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-384.000000, -153.000000)" class="fill" fill="#232323">>
							<g transform="translate(385.000000, 154.000000)">
								<g>
									<path d="M13,-0.75 C10.6527898,-0.75 8.75,1.15278981 8.75,3.5 C8.75,5.84721019 10.6527898,7.75 13,7.75 C15.3472102,7.75 17.25,5.84721019 17.25,3.5 C17.25,1.15278981 15.3472102,-0.75 13,-0.75 Z M13,0.75 C14.5187831,0.75 15.75,1.98121694 15.75,3.5 C15.75,5.01878306 14.5187831,6.25 13,6.25 C11.4812169,6.25 10.25,5.01878306 10.25,3.5 C10.25,1.98121694 11.4812169,0.75 13,0.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M13,13.25 C10.9289322,13.25 9.25,14.9289322 9.25,17 C9.25,19.0710678 10.9289322,20.75 13,20.75 C15.0710678,20.75 16.75,19.0710678 16.75,17 C16.75,14.9289322 15.0710678,13.25 13,13.25 Z M13,14.75 C14.2426407,14.75 15.25,15.7573593 15.25,17 C15.25,18.2426407 14.2426407,19.25 13,19.25 C11.7573593,19.25 10.75,18.2426407 10.75,17 C10.75,15.7573593 11.7573593,14.75 13,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M23,13.25 C20.9289322,13.25 19.25,14.9289322 19.25,17 C19.25,19.0710678 20.9289322,20.75 23,20.75 C25.0710678,20.75 26.75,19.0710678 26.75,17 C26.75,14.9289322 25.0710678,13.25 23,13.25 Z M23,14.75 C24.2426407,14.75 25.25,15.7573593 25.25,17 C25.25,18.2426407 24.2426407,19.25 23,19.25 C21.7573593,19.25 20.75,18.2426407 20.75,17 C20.75,15.7573593 21.7573593,14.75 23,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M3,13.25 C0.928932188,13.25 -0.75,14.9289322 -0.75,17 C-0.75,19.0710678 0.928932188,20.75 3,20.75 C5.07106781,20.75 6.75,19.0710678 6.75,17 C6.75,14.9289322 5.07106781,13.25 3,13.25 Z M3,14.75 C4.24264069,14.75 5.25,15.7573593 5.25,17 C5.25,18.2426407 4.24264069,19.25 3,19.25 C1.75735931,19.25 0.75,18.2426407 0.75,17 C0.75,15.7573593 1.75735931,14.75 3,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M22,9.25 C22.9181734,9.25 23.6711923,9.95711027 23.7441988,10.8564728 L23.75,11 L23.75,14 L22.25,14 L22.25,11 C22.25,10.8816533 22.1677666,10.7825132 22.0573227,10.7566027 L22,10.75 L4,10.75 C3.88165327,10.75 3.78251318,10.8322334 3.75660268,10.9426773 L3.75,11 L3.75,14 L2.25,14 L2.25,11 C2.25,10.0818266 2.95711027,9.32880766 3.85647279,9.2558012 L4,9.25 L22,9.25 Z" id="Path-3" fill-rule="nonzero"></path>
									<polygon fill-rule="nonzero" points="13.75 7 13.75 14 12.25 14 12.25 7"></polygon>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Organisations
			</a>
		</li>
		<li *can="'accessSidebar'; target:'members'" class="nav-item nav-item-members">
			<a class="nav-link" [class.active]="isActive('members')" (click)="showPage('members')">
				<svg width="24px" height="21px" viewBox="0 0 24 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-178.000000, -150.000000)" class="fill" fill="#232323">>
							<g transform="translate(178.000000, 150.000000)">
								<g transform="translate(0.000000, 0.000000)">
									<g fill-rule="nonzero">
										<path d="M7.94482759,9.1994003 C12.3326347,9.1994003 15.8896552,12.6987997 15.8896552,17.0155276 C15.8896552,21.3322554 0,21.3322554 0,17.0155276 C0,12.6987997 3.55702047,9.1994003 7.94482759,9.1994003 Z M7.94482759,10.3994003 C4.21541498,10.3994003 1.2,13.3659678 1.2,17.0155276 C1.2,19.7322554 14.6896552,19.7322554 14.6896552,17.0155276 C14.6896552,13.3659678 11.6742402,10.3994003 7.94482759,10.3994003 Z" id="Combined-Shape"></path>
										<path d="M7.94482759,1.6 C9.68778485,1.6 11.1007309,3.06920411 11.1007309,4.88155922 C11.1007309,6.69391433 9.68778485,8.16311844 7.94482759,8.16311844 C6.20187032,8.16311844 4.78892429,6.69391433 4.78892429,4.88155922 C4.78892429,3.06920411 6.20187032,1.6 7.94482759,1.6 Z M7.94482759,2.8 C6.87532101,2.8 5.98892429,3.72168963 5.98892429,4.88155922 C5.98892429,6.04142881 6.87532101,6.96311844 7.94482759,6.96311844 C9.01433416,6.96311844 9.90073089,6.04142881 9.90073089,4.88155922 C9.90073089,3.72168963 9.01433416,2.8 7.94482759,2.8 Z" id="Oval"></path>
										<path d="M15.9448276,7.5994003 C20.3326347,7.5994003 23.8896552,11.0987997 23.8896552,15.4155276 C23.8896552,17.4178302 20.470927,18.4913701 16.8050064,18.6361474 C16.8427009,18.2250647 16.8612273,17.8253016 16.8617503,17.4359227 C19.9027589,17.323919 22.6896552,16.6503546 22.6896552,15.4155276 C22.6896552,11.7659678 19.6742402,8.7994003 15.9448276,8.7994003 C14.7821188,8.7994003 13.6888098,9.08774717 12.7346413,9.5952408 C12.3645463,9.35207088 11.9685568,9.12185365 11.5475525,8.90606486 C12.805266,8.08047545 14.3179412,7.5994003 15.9448276,7.5994003 Z" id="Combined-Shape"></path>
										<path d="M15.9448276,0 C17.6877849,0 19.1007309,1.46920411 19.1007309,3.28155922 C19.1007309,5.09391433 17.6877849,6.56311844 15.9448276,6.56311844 C14.2018703,6.56311844 12.7889243,5.09391433 12.7889243,3.28155922 C12.7889243,1.46920411 14.2018703,0 15.9448276,0 Z M15.9448276,1.2 C14.875321,1.2 13.9889243,2.12168963 13.9889243,3.28155922 C13.9889243,4.44142881 14.875321,5.36311844 15.9448276,5.36311844 C17.0143342,5.36311844 17.9007309,4.44142881 17.9007309,3.28155922 C17.9007309,2.12168963 17.0143342,1.2 15.9448276,1.2 Z" id="Oval"></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Members
			</a>
		</li>
		<li *can="'accessSidebar'; target:'branches'" class="nav-item nav-item-branches" >
			<a class="nav-link" [class.active]="isActive('branches')" (click)="showPage('branches')">
				<svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-384.000000, -153.000000)" class="fill" fill="#232323">>
							<g transform="translate(385.000000, 154.000000)">
								<g>
									<path d="M13,-0.75 C10.6527898,-0.75 8.75,1.15278981 8.75,3.5 C8.75,5.84721019 10.6527898,7.75 13,7.75 C15.3472102,7.75 17.25,5.84721019 17.25,3.5 C17.25,1.15278981 15.3472102,-0.75 13,-0.75 Z M13,0.75 C14.5187831,0.75 15.75,1.98121694 15.75,3.5 C15.75,5.01878306 14.5187831,6.25 13,6.25 C11.4812169,6.25 10.25,5.01878306 10.25,3.5 C10.25,1.98121694 11.4812169,0.75 13,0.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M13,13.25 C10.9289322,13.25 9.25,14.9289322 9.25,17 C9.25,19.0710678 10.9289322,20.75 13,20.75 C15.0710678,20.75 16.75,19.0710678 16.75,17 C16.75,14.9289322 15.0710678,13.25 13,13.25 Z M13,14.75 C14.2426407,14.75 15.25,15.7573593 15.25,17 C15.25,18.2426407 14.2426407,19.25 13,19.25 C11.7573593,19.25 10.75,18.2426407 10.75,17 C10.75,15.7573593 11.7573593,14.75 13,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M23,13.25 C20.9289322,13.25 19.25,14.9289322 19.25,17 C19.25,19.0710678 20.9289322,20.75 23,20.75 C25.0710678,20.75 26.75,19.0710678 26.75,17 C26.75,14.9289322 25.0710678,13.25 23,13.25 Z M23,14.75 C24.2426407,14.75 25.25,15.7573593 25.25,17 C25.25,18.2426407 24.2426407,19.25 23,19.25 C21.7573593,19.25 20.75,18.2426407 20.75,17 C20.75,15.7573593 21.7573593,14.75 23,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M3,13.25 C0.928932188,13.25 -0.75,14.9289322 -0.75,17 C-0.75,19.0710678 0.928932188,20.75 3,20.75 C5.07106781,20.75 6.75,19.0710678 6.75,17 C6.75,14.9289322 5.07106781,13.25 3,13.25 Z M3,14.75 C4.24264069,14.75 5.25,15.7573593 5.25,17 C5.25,18.2426407 4.24264069,19.25 3,19.25 C1.75735931,19.25 0.75,18.2426407 0.75,17 C0.75,15.7573593 1.75735931,14.75 3,14.75 Z" id="Oval" fill-rule="nonzero"></path>
									<path d="M22,9.25 C22.9181734,9.25 23.6711923,9.95711027 23.7441988,10.8564728 L23.75,11 L23.75,14 L22.25,14 L22.25,11 C22.25,10.8816533 22.1677666,10.7825132 22.0573227,10.7566027 L22,10.75 L4,10.75 C3.88165327,10.75 3.78251318,10.8322334 3.75660268,10.9426773 L3.75,11 L3.75,14 L2.25,14 L2.25,11 C2.25,10.0818266 2.95711027,9.32880766 3.85647279,9.2558012 L4,9.25 L22,9.25 Z" id="Path-3" fill-rule="nonzero"></path>
									<polygon fill-rule="nonzero" points="13.75 7 13.75 14 12.25 14 12.25 7"></polygon>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Locations
			</a>
		</li>
		<li *can="'accessSidebar'; target:'connections'" class="nav-item nav-item-conntections">
			<a class="nav-link" [class.active]="isActive('connections')" (click)="showPage('connections')">
				<svg width="26px" height="13px" viewBox="0 0 26 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-32.000000, -289.000000)">
							<g transform="translate(33.000000, 290.000000)">
								<g>
									<circle class="stroke" stroke="#232323" stroke-width="1.7" cx="5.14285714" cy="5.14285714" r="5.14285714"></circle>
									<circle class="stroke" stroke="#232323"stroke-width="1.7" cx="18.8571429" cy="5.14285714" r="5.14285714"></circle>
									<rect class="fill" fill="#232323" x="10.2857143" y="4.28571429" width="3.42857143" height="2.14285714"></rect>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Connections
			</a>
		</li>
		<li *can="'accessSidebar'; target:'cases_messages'" class="breaker"></li>
		<li *can="'accessSidebar'; target:'cases'" class="nav-item nav-item-cases">
			<a class="nav-link" [class.active]="isActive('cases')" (click)="showPage('cases')">
				<svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-33.000000, -357.000000)" class="fill" fill="#232323">
							<g transform="translate(33.000000, 357.000000)">
								<g>
									<path d="M11,0.204545455 C13.6233526,0.204545455 15.75,2.33119289 15.75,4.95454545 L15.745,4.74954545 L21,4.75 C21.3796958,4.75 21.693491,5.03215388 21.7431534,5.39822944 L21.75,5.5 L21.75,20.0454545 C21.75,20.4596681 21.4142136,20.7954545 21,20.7954545 L21,20.7954545 L1,20.7954545 C0.585786438,20.7954545 0.25,20.4596681 0.25,20.0454545 L0.25,20.0454545 L0.25,5.5 C0.25,5.08578644 0.585786438,4.75 1,4.75 L1,4.75 L6.25432413,4.75001507 C6.36144052,2.22154234 8.44517283,0.204545455 11,0.204545455 Z M20.25,6.249 L15.75,6.24854545 L15.75,6.25 L6.25,6.25 L6.25,6.24854545 L1.75,6.249 L1.75,19.295 L20.25,19.295 L20.25,6.249 Z M11,1.70454545 C9.32886252,1.70454545 7.95231635,2.96584077 7.77039625,4.58839662 L7.77039625,4.58839662 L7.757,4.749 L14.242,4.749 L14.2296037,4.58839662 C14.0544214,3.02593543 12.7714651,1.79847029 11.1844239,1.70969024 L11.1844239,1.70969024 Z" fill-rule="nonzero"></path>
									<path d="M11.8,8 C11.9104569,8 12,8.08954305 12,8.2 L12,12 L15.8,12 C15.9104569,12 16,12.0895431 16,12.2 L16,13.8 C16,13.9104569 15.9104569,14 15.8,14 L12,14 L12,17.8 C12,17.9104569 11.9104569,18 11.8,18 L10.2,18 C10.0895431,18 10,17.9104569 10,17.8 L10,14 L6.2,14 C6.08954305,14 6,13.9104569 6,13.8 L6,12.2 C6,12.0895431 6.08954305,12 6.2,12 L10,12 L10,8.2 C10,8.08954305 10.0895431,8 10.2,8 L11.8,8 Z" fill-rule="nonzero"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Cases
			</a>
		</li>
		<li *can="'accessSidebar'; target:'messages'" class="nav-item nav-item-messages">
			<a class="nav-link" [class.active]="isActive('messages')" (click)="showPage('messages')">
				<svg width="21px" height="25px" viewBox="0 0 21 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-34.000000, -353.000000)" class="fill" fill="#232323"> fill-rule="nonzero">
							<g  transform="translate(32.000000, 353.500000)">
								<path d="M12.5516806,0.25 C6.76773767,0.25 2.25,4.58657839 2.25,10.5138442 L2.2549369,10.8531875 C2.34365896,13.892593 3.62437182,16.5302618 5.78619979,18.3390362 L5.972,18.489 L5.94191545,22.994885 L5.94771689,23.0964161 C6.01657446,23.6567464 6.6815784,23.9541444 7.14916672,23.594479 L11.006,20.627 L10.6771735,20.5771305 C11.3101821,20.6848668 11.9331114,20.7407141 12.5516806,20.7407141 C18.3359716,20.7407141 22.8166667,16.4408273 22.8166667,10.5138442 C22.8166667,4.58088697 18.3286681,0.25 12.5516806,0.25 Z M12.5516806,1.75 C17.5119653,1.75 21.3166667,5.42150995 21.3166667,10.5138442 C21.3166667,15.5982628 17.5210519,19.2407141 12.5516806,19.2407141 C12.0201697,19.2407141 11.4810841,19.1923836 10.9288502,19.0983949 L10.8049832,19.087761 C10.6400681,19.0873266 10.4782916,19.1413284 10.3457432,19.2432836 L7.452,21.468 L7.47514328,18.1279597 C7.47678296,17.8875409 7.36306332,17.6609095 7.16933784,17.5185214 C5.00852653,15.930327 3.75,13.4459061 3.75,10.5138442 C3.75,5.42905048 7.58274172,1.75 12.5516806,1.75 Z" id="Path"></path>
							</g>
						</g>
					</g>
				</svg>
				Messages
			</a>
		</li>
		<li class="breaker"></li>
		<li *can="'accessSidebar'; target:'account'" class="nav-item nav-item-account">
			<a class="nav-link" [class.active]="isActive('account')" (click)="showPage('account')">
				<svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g transform="translate(-706.000000, -152.000000)" class="fill" fill="#232323">>
							<g transform="translate(706.000000, 152.000000)">
								<g>
									<path d="M9.93103448,9.49925037 C15.4157934,9.49925037 19.862069,13.8734996 19.862069,19.2694094 C19.862069,24.6653193 4.38760139e-13,24.6653193 4.38760139e-13,19.2694094 C4.38760139e-13,13.8734996 4.44627559,9.49925037 9.93103448,9.49925037 Z M9.93103448,10.9992504 C5.26926873,10.9992504 1.5,14.7074597 1.5,19.2694094 C1.5,22.6653193 18.362069,22.6653193 18.362069,19.2694094 C18.362069,14.7074597 14.5928002,10.9992504 9.93103448,10.9992504 Z" id="Combined-Shape" fill-rule="nonzero"></path>
									<path d="M9.93103448,-1.24344979e-14 C12.1097311,-1.24344979e-14 13.8759136,1.83650513 13.8759136,4.10194903 C13.8759136,6.36739292 12.1097311,8.20389805 9.93103448,8.20389805 C7.7523379,8.20389805 5.98615536,6.36739292 5.98615536,4.10194903 C5.98615536,1.83650513 7.7523379,-1.24344979e-14 9.93103448,-1.24344979e-14 Z M9.93103448,1.5 C8.59415127,1.5 7.48615536,2.65211204 7.48615536,4.10194903 C7.48615536,5.55178601 8.59415127,6.70389805 9.93103448,6.70389805 C11.2679177,6.70389805 12.3759136,5.55178601 12.3759136,4.10194903 C12.3759136,2.65211204 11.2679177,1.5 9.93103448,1.5 Z" id="Oval" fill-rule="nonzero"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Account
			</a>
		</li>
	</ul>
</nav>
