<app-header></app-header>

<div class="container-fluid">
    <div class="row">

        <app-side-menu id="sidebarMenu" class="col-md-3 col-xl-2 d-md-block sidebar collapse"></app-side-menu>

        <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
            <ng-container *ngIf="!organisation || !branch">
                Loading...
            </ng-container>
            <ng-container *ngIf="organisation && branch">
                <app-breadcrumb>
                    <li class="breadcrumb-item" (click)="goToPage('organisations')"
                        *can="'view'; target:'organisations'">
                        Organisations
                    </li>
                    <li class="breadcrumb-item" aria-current="page"
                        (click)="goToPage('organisations/' + organisation.id)">
                        {{ organisation.name }}
                    </li>
                    <li class="breadcrumb-item" aria-current="page"
                        (click)="goToPage('organisations/' + organisation.id + '/branches')">
                        Locations
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {{ branch.name }}
                    </li>
                </app-breadcrumb>
                <header class="d-flex">
                    <h1 class="h1">{{ branch.name }}</h1>
                </header>

                <app-flash-messages></app-flash-messages>

                <mat-tab-group>
                    <mat-tab label="Location">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="profile-options branch-options white-box">
                                    <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="branch.image">
                                        <span class="add-icon">
                                            <img src="assets/icon/purple-edit-icon.svg" />
                                        </span>
                                        <span class="profile-img" [style.backgroundImage]="branch.image | attachment: 'profile_small' | backgroundImage"></span>
                                    </div>
                                    <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="!branch.image && organisation.image">
                                        <span class="add-icon">
                                            <img src="assets/icon/purple-edit-icon.svg" />
                                        </span>
                                        <span class="profile-img" [style.backgroundImage]="organisation.image | attachment: 'profile_small' | backgroundImage"></span>
                                    </div>
                                    <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar profile-avatar-fallback large"
                                         *ngIf="!branch.image && !organisation.image">
                                        <span class="add-icon">
                                            <img src="assets/icon/purple-add-icon.svg" />
                                        </span>
                                        <span class="profile-img">{{ branch.initials }}</span>
                                    </div>
                                    <form class="base-form">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <input type="text" class="form-control" id="name" aria-describedby="name"
                                                   placeholder="Location Name" [(ngModel)]="branch.name" name="name">
                                        </div>
                                        <div class="form-group">
                                            <label for="line_1">Address Line 1</label>
                                            <input type="text" class="form-control" id="line_1"
                                                   aria-describedby="line_1"
                                                   placeholder="Address Line 1" [(ngModel)]="branch.address.line_1"
                                                   name="line_1">
                                        </div>
                                        <div class="form-group">
                                            <label for="line_2">Address Line 2</label>
                                            <input type="text" class="form-control" id="line_2"
                                                   aria-describedby="line_2"
                                                   placeholder="Address Line 2" [(ngModel)]="branch.address.line_2"
                                                   name="line_2">
                                        </div>
                                        <div class="form-group">
                                            <label for="town">Town</label>
                                            <input type="text" class="form-control" id="town"
                                                   aria-describedby="town"
                                                   placeholder="Town" [(ngModel)]="branch.address.town"
                                                   name="town">
                                        </div>
                                        <div class="form-group">
                                            <label for="postcode">Postcode</label>
                                            <input type="text" class="form-control" id="postcode"
                                                   aria-describedby="postcode"
                                                   placeholder="Postcode" [(ngModel)]="branch.address.postcode"
                                                   name="postcode">
                                        </div>
                                        <div class="form-group">
                                            <label for="primary_contact_name">Primary Contact Name</label>
                                            <input type="text" class="form-control" id="primary_contact_name"
                                                   aria-describedby="primary_contact_name" placeholder="Primary Contact Name"
                                                   [(ngModel)]="branch.primary_contact.name" name="primary_contact_name">
                                        </div>

                                        <div class="form-group">
                                            <label for="primary_contact_email">Primary Contact Email</label>
                                            <input type="text" class="form-control" id="primary_contact_email"
                                                   aria-describedby="primary_contact_email" placeholder="Primary Contact Email"
                                                   [(ngModel)]="branch.primary_contact.email" name="primary_contact_email">
                                        </div>

                                        <div class="form-group">
                                            <label for="primary_contact_phone">Primary Contact Phone</label>
                                            <input type="text" class="form-control" id="primary_contact_phone"
                                                   aria-describedby="primary_contact_phone" placeholder="Primary Contact Phone"
                                                   [(ngModel)]="branch.primary_contact.phone_number" name="primary_contact_phone">
                                        </div>
                                    </form>
                                </div>
                                <app-button class="red remove-branch small" data-toggle="modal"
                                            data-target="#remove-account">Delete Location
                                </app-button>
                                <app-button class="active remove-member small" (click)="saveChanges()">Save Changes</app-button>
                            </div>
                            <div class="col-xl-7"></div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Members">
                        <header class="d-flex">
                            <span></span>
                            <app-button class="active" routerLink="members/new">Create Member</app-button>
                        </header>
                        <div class="white-bg-content table-responsive">
                            <div class="table-filters">
                                <div class="table-count">
                                    {{ totalBranchMembers }} Members
                                </div>
                                <div class="d-md-flex">
                                    <div class="dropdown show">
                                        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="dropdown-toggle-filter">Filter by admin level:</span> <span
                                                class="dropdown-toggle-value">{{ selectedRole ? (selectedRole | userrole) : 'All' }}</span>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item" (click)="filterMembers()">All</a>
                                            <a class="dropdown-item" *ngFor="let role of userRoles"
                                               (click)="filterMembers(role.key)">{{ role | userrole }}</a>
                                        </div>
                                    </div>
                                    <div class="input-group search-group">
                                        <input type="text" class="form-control" placeholder="Search" aria-label="Search"
                                               aria-describedby="table-search" [(ngModel)]="searchTerm">
                                    </div>
                                </div>
                            </div>
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Member</th>
                                    <th></th>
                                    <th>Admin Level</th>
                                    <th>Job Title</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let user of users">
                                    <td>
                                        <div class="member">
                                            <div class="image profile-avatar member-avatar" *ngIf="user.image">
                                                <span class="profile-img" [style.backgroundImage]="user.image | attachment: 'profile_small' | backgroundImage"></span>
                                            </div>
                                            <div class="member-avatar profile-avatar-fallback" *ngIf="!user.image">
                                                {{ user.initials }}
                                            </div>
                                            <div class="title">
                                                {{ user.title }} {{ user.first_name}} {{ user.last_name }}
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>{{ user.role | userrole }}</td>
                                    <td>{{ user.job_title }}</td>
                                    <td>
                                        <div class="action-view action-button" routerLink="members/{{ user.id }}">
                                            <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g transform="translate(-358.000000, -295.000000)" class="fill"
                                                       fill="#232323">
                                                        <g transform="translate(358.000000, 295.000000)">
                                                            <g>
                                                                <path d="M18.6968203,-0.00189077605 C17.5921722,-0.00189077605 16.4861938,0.421384999 15.6458443,1.26174183 L0.681595118,16.2259828 C0.561925728,16.3457082 0.488340377,16.5038204 0.473799043,16.6724724 L0.0020420778,22.2282505 C-0.0151228874,22.4385952 0.0610007178,22.6458584 0.210230636,22.7950896 C0.359460555,22.9443208 0.566723149,23.0204462 0.777067961,23.003283 L6.33285292,22.5357394 C6.50156292,22.5197362 6.65919672,22.4446502 6.77793301,22.3237334 L21.743584,7.35948435 C23.4242901,5.67877884 23.4242901,2.94245008 21.743584,1.26174455 C20.9032275,0.421384999 19.8014617,-0.00189077605 18.6968203,-0.00189077605 Z M18.6968203,1.42601051 C19.4295554,1.42601051 20.1623263,1.7079136 20.7298651,2.27545292 C21.8649432,3.41053159 21.8649432,5.21068374 20.7298651,6.34576238 L6.27247535,20.8031532 L2.20918942,16.7370551 L16.6651776,2.27545292 C17.2327166,1.7079136 17.9640856,1.42601051 18.6968203,1.42601051 Z M1.77674723,18.3404679 L4.67187269,21.2327894 L1.50857356,21.5009554 L1.77674723,18.3404679 Z"
                                                                      id="Shape"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <app-pagination [(currentPage)]="pageMembers" [totalPages]="totalMembersPages"
                                            [totalItems]="totalBranchMembers"></app-pagination>
                        </div>
                    </mat-tab>
                    <mat-tab label="Connections">
                        <header class="d-flex">
                            <app-button class="active" routerLink="connections/new">Create Connection</app-button>
                        </header>
                        <app-connections-component [organisation]="organisation" [branch]="branch"></app-connections-component>
                    </mat-tab>
                    <mat-tab label="Cases">
                        <app-report-cases [organisationId]="this.organisationId" [branchId]="this.branchId"></app-report-cases>
                    </mat-tab>
                </mat-tab-group>

            </ng-container>
        </main>

    </div>
</div>

<app-modal modalTitle="Remove Location" class="modal modal-remove fade" id="remove-account" tabindex="-1" role="dialog"
           aria-labelledby="remove-account" aria-hidden="true">
    <div class="modal-body">
        <p class="sub-header">Are you sure you want to remove this branch?</p>
        <p class="note">All details for this branch will be destroyed.</p>
    </div>
    <div class="modal-footer">
        <app-button class="transparent" data-dismiss="modal">Cancel</app-button>
        <app-button class="active" data-dismiss="modal" (click)="removeBranch()">Remove</app-button>
    </div>
</app-modal>

<app-modal modalTitle="Add Branch Icon" class="modal fade" id="add-photo" tabindex="-1" role="dialog"
           aria-labelledby="add-photo" aria-hidden="true">
    <div class="modal-body image-upload-body">
        <img src="/assets/icon/drop-photos-here-icon.svg">
        <input #imageInput type="file" name="logo" accept="image/*" (change)="onImageAdded()">
        <div class="sep-text">
            or
        </div>
    </div>
    <div class="modal-footer">
        <button #closeModal style="display:none;" data-dismiss="modal">Close</button>
        <app-button class="active" (click)="clickImageUpload()">Upload Photo</app-button>
    </div>
</app-modal>
