import { AbstractModel} from './abstract.model';

export class FlashMessage extends AbstractModel {

    public type: 'success'|'error' = null;
    public message: string = null;

    public fill(data: Partial<FlashMessage>) {
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.message !== undefined) {
            this.message = data.message;
        }
    }

}
