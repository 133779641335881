import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaseConversation } from '../../models/case-conversation';

@Component({
  selector: 'app-report-case-table',
  templateUrl: './report-case-table.component.html',
  styleUrls: ['./report-case-table.component.scss']
})
export class ReportCaseTableComponent implements OnInit {

  @Input() cases: CaseConversation[] = null;
  @Input() totalCases: number = null;
  @Input() isAll: boolean = null;
  @Output() caseSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public selectCase(caseId: string) {
    this.caseSelected.emit(caseId);
  }
}
