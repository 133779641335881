/* tslint:disable:variable-name curly */
import { AbstractModel } from './abstract.model';
import { User } from './user';
import { Branch } from './branch';
import { CaseEvent } from './case-conversation-events/case-event';
import { CaseMessageEvent } from './case-conversation-events/case-message-event';
import { CaseAcceptedEvent } from './case-conversation-events/case-accepted-event';
import { CaseAssignedEvent } from './case-conversation-events/case-assigned-event';
import { CaseOpenedEvent } from './case-conversation-events/case-opened-event';
import { CaseResolvedEvent } from './case-conversation-events/case-resolved-event';

export class CaseConversation extends AbstractModel {

    public get hasBeenOpened(): boolean {
        return !!this.opened_at;
    }

    public get hasBeenAssigned(): boolean {
        return !!this.assignee_id;
    }

    public get hasBeenResolved(): boolean {
        return !!this.resolved_at;
    }

    public get isOpen(): boolean {
        return this.hasBeenOpened && !this.hasBeenResolved;
    }

    public get isAssigned(): boolean {
        return this.isOpen && this.hasBeenAssigned;
    }

    public get isResolved(): boolean {
        return this.hasBeenResolved;
    }

    public get status(): string {
        if (!this.opened_at) {
            return 'not opened';
        }
        if (this.resolved_at) {
            return 'resolved';
        }
        if (this.assignee_id) {
            return 'assigned';
        }
        if (this.accepted_at) {
            return 'accepted';
        }
        if (this.opened_at) {
            return 'opened';
        }
        return 'Unknown';
    }

    public accepted_at: string = null;
    public assignee_id: string = null;
    public assignee: User = null;
    public case_number: string = null;
    public created_at: string = null;
    public created_by: string = null;
    public creator: User = null;
    public id: string = null;
    public image: string = null;
    public opened_at: string = null;
    public patient_id: string = null;
    public recipient_branch: Branch = null;
    public resolved_at: string = null;
    public sender_branch: Branch = null;

    // tslint:disable-next-line:max-line-length
    public static createEvent(data: Partial<CaseEvent>): CaseMessageEvent | CaseAcceptedEvent | CaseAssignedEvent | CaseOpenedEvent | CaseResolvedEvent {
        let event = null;
        switch (data.type) {
            case 'message':
            case 'new_message':
            case 'message_delivered':
            case 'message_read':
                event = CaseMessageEvent.createOne(data) as CaseMessageEvent;
                break;
            case 'accepted':
                event = CaseAcceptedEvent.createOne(data) as CaseAcceptedEvent;
                break;
            case 'assigned':
                event = CaseAssignedEvent.createOne(data) as CaseAssignedEvent;
                break;
            case 'opened':
                event = CaseOpenedEvent.createOne(data) as CaseOpenedEvent;
                break;
            case 'resolved':
                event = CaseResolvedEvent.createOne(data) as CaseResolvedEvent;
                break;
            default:
                break;
        }
        return event;
    }

    public fill(data: Partial<CaseConversation>) {

        if (data.accepted_at !== undefined) {
            this.accepted_at = data.accepted_at;
        }
        if (data.assignee_id !== undefined) {
            this.assignee_id = data.assignee_id;
        }
        if (data.assignee) {
            this.assignee = User.createOne(data.assignee);
        }
        if (data.case_number !== undefined) {
            this.case_number = data.case_number;
        }
        if (data.created_at !== undefined) {
            this.created_at = data.created_at;
        }
        if (data.created_by) {
            this.created_by = data.created_by;
        }
        if (data.creator !== undefined) {
            this.creator = User.createOne(data.creator);
        }
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.image) {
            this.image = data.image;
        }
        if (data.opened_at !== undefined) {
            this.opened_at = data.opened_at;
        }
        if (data.patient_id !== undefined) {
            this.patient_id = data.patient_id;
        }
        if (data.recipient_branch) {
            this.recipient_branch = Branch.createOne(data.recipient_branch as any);
        }
        if (data.resolved_at !== undefined) {
            this.resolved_at = data.resolved_at;
        }
        if (data.sender_branch !== undefined) {
            this.sender_branch = Branch.createOne(data.sender_branch as any);
        }
    }

    public getCorrespondentBranch(organisationId: string): Branch {
        return this.sender_branch.organisation_id === organisationId ? this.recipient_branch : this.sender_branch;
    }

    public getCorrespondentUser(organisationId: string): User {
        return this.creator.organisation_id === organisationId ? (this.assignee ? this.assignee : null) : this.creator;
    }
}
