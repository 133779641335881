<div class="message" [class.you]="isSentByMyOrganisation" *ngIf="event && event.type == 'message'">
    <div *ngIf="sender" class="message-user-icon user-avatar">
        <div *ngIf="sender.image" class="image profile-avatar member-avatar">
            <img [src]="sender.image | attachment"/>
        </div>
        <div *ngIf="!sender.image" class="member-avatar profile-avatar-fallback">
            {{ sender.initials }}
        </div>
    </div>
    <div class="message-content">
        <div *ngIf="sender" class="message-content-header">
            <div class="title">
                <span *ngIf="isSentByRecipient || (isSentByMyOrganisation && !isSentByMe)">
                    {{ sender.friendlyName }}
                </span>
                <span *ngIf="isSentByMe">
                    You
                </span>
            </div>

            <div class="date-time">
                {{ event.sentAt | dateFns : 'HH:mm' }}
            </div>
            <div class="message-status" [ngClass]="getMessageClasses()">
                <div class="message-icon"></div>
            </div>
        </div>
        <div class="message-content-body" *ngIf="event.messageBody">{{ event.messageBody }}</div>
        <div class="message-content-attachment" *ngIf="attachment">
            <img [src]="attachment | attachment"/>
        </div>
    </div>
</div>
