<div class="d-md-flex">
    <div class="dropdown show">
        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="dropdown-toggle-filter">Sort by:</span> <span class="dropdown-toggle-value">{{ sortByLabel }}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item" *ngFor="let order of sortByOptions" (click)="selectOrder(order)">{{ order.label }}</a>
        </div>
    </div>
    <div class="input-group search-group">
        <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="table-search" [(ngModel)]="searchTerm">
    </div>
</div>
