<nav class="navbar sticky-top flex-md-nowrap shadow">
	<a class="navbar-brand p-0" href="/">
		<img src="/assets/icon/medicComsLogoWhite.svg" />
	</a>
	<div class="navbar-user" *ngIf="currentUser">
		<div class="image profile-avatar navbar-user-image" *ngIf="currentUser.image">
			<span class="profile-img" [style.backgroundImage]="currentUser.image | attachment: 'profile_small' | backgroundImage"></span>
		</div>
		<div class="image profile-avatar profile-avatar-fallback navbar-user-image"
			 *ngIf="!currentUser.image && !currentUser.image">
			<span class="profile-img">{{ currentUser.initials }}</span>
		</div>

		<div class="navbar-user-detail">
			<div class="navbar-user-detail-name">
				{{ currentUser.fullNameWithTitle }}
			</div>
			<div class="navbar-user-detail-menu">
				<a (click)="goToProfile()">Profile</a>
				<span class="sep">|</span>
				<a (click)="logout()">Logout</a>
			</div>
		</div>
	</div>
	<button class="navbar-toggler d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
		<img src="/assets/icon/menu.png"/>
	</button>
</nav>
