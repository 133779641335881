import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { OrganisationsService } from '../organisations.service';
import { Router } from '@angular/router';
import { CreateNewOrganisationRequest } from '@scaffold/mediccoms-api-client/requests';
import { FlashMessageService } from '../../../services/flash-message.service';
import { MenuService } from '../../../services/menu.service';
import { AttachmentService } from '../../../services/attachment.service';

@Component({
    selector: 'app-organisations-create',
    templateUrl: './organisations-create.html',
    styleUrls: ['./organisations-create.scss']
})
export class OrganisationsCreatePage implements OnInit, OnDestroy, AfterViewInit {

    public organisationName: string = null;
    public organisationImage: string = null;
    public organisationAddressLine1: string = null;
    public organisationAddressLine2: string = null;
    public organisationAddressTown: string = null;
    public organisationAddressPostcode: string = null;
    public organisationPrimaryContactName: string = null;
    public organisationPrimaryContactPhone: string = null;
    public organisationPrimaryContactEmail: string = null;
    public organisationEnabledCases = false;
    public organisationEnabledMessages = false;

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    constructor(
        private attachmentService: AttachmentService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private router: Router,
        private userService: UserService,
    ) {

    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
    }

    public ngOnInit(): void {
        this.menuService.setActive('organisations');
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public toggleMessages(event) {
        this.organisationEnabledMessages = !this.organisationEnabledMessages;
    }

    public toggleCases(event) {
        this.organisationEnabledCases = !this.organisationEnabledCases;
    }

    public async createOrganisation() {

        // show loading

        const request: CreateNewOrganisationRequest = {
            name: this.organisationName,
            address: {
                line_1: this.organisationAddressLine1,
                line_2: this.organisationAddressLine2 ? this.organisationAddressLine2 : '',
                town: this.organisationAddressTown,
                postcode: this.organisationAddressPostcode,
            },
            primary_contact: {
                name: this.organisationPrimaryContactName,
                email: this.organisationPrimaryContactEmail,
                phone_number: this.organisationPrimaryContactPhone,
            },
            messaging_enabled: this.organisationEnabledMessages,
            cases_enabled: this.organisationEnabledCases,
        };

        if (this.organisationImage) {
            request.image = this.organisationImage;
        }

        try {
            const response = await this.organisationService.create(request);
            if (response.organisation) {
                // clear form
                this.flashMessageService.addMessage('success', 'Organisation Created!');
                this.router.navigate(['organisations', response.organisation.id]);
            } else {
                this.flashMessageService.showMessage('error', 'Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'Error Occurred');
        }
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.organisationImage = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }

}
