import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BranchesService } from '../branches/branches.service';
import { UserService } from '../../services/user.service';
import { CreateBranchRequest, CreateNewOrganisationRequest } from '@scaffold/mediccoms-api-client/requests';
import { Organisation } from '../../models/organisation';
import { OrganisationsService } from '../organisations/organisations.service';
import { FlashMessageService } from '../../services/flash-message.service';
import { MenuService } from '../../services/menu.service';
import { AttachmentService } from '../../services/attachment.service';


@Component({
    selector: 'app-add-branch',
    templateUrl: './add-branch.html',
    styleUrls: ['./add-branch.scss']
})
// tslint:disable-next-line:component-class-suffix
export class AddBranchPage implements OnInit, OnDestroy, AfterViewInit {

    private organisationId: string = null;
    public organisation: Organisation = null;

    public branchName: string = null;
    public branchImage: string = null;
    public branchAddressLine1: string = null;
    public branchAddressLine2: string = null;
    public branchAddressTown: string = null;
    public branchAddressPostcode: string = null;
    public branchPrimaryContactName: string = null;
    public branchPrimaryContactPhone: string = null;
    public branchPrimaryContactEmail: string = null;

    private isDestroyed: Subject<void> = new Subject<void>();

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    constructor(
        private attachmentService: AttachmentService,
        private branchService: BranchesService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {

    }


    public async createBranch() {
        const request: CreateBranchRequest = {
            name: this.branchName,
            address: {
                line_1: this.branchAddressLine1,
                line_2: this.branchAddressLine2 ? this.branchAddressLine2 : '',
                town: this.branchAddressTown,
                postcode: this.branchAddressPostcode,
            },
            primary_contact: {
                name: this.branchPrimaryContactName,
                email: this.branchPrimaryContactEmail,
                phone_number: this.branchPrimaryContactPhone,
            }
        };

        if (this.branchImage) {
            request.image = this.branchImage;
        }

        try {
            const response = await this.branchService.create(this.organisationId, request);
            if (response.branch) {
                this.flashMessageService.addMessage('success', 'Branch Created');
                this.router.navigate(['organisations', this.organisationId, 'branches', response.branch.id]);
            } else {
                throw new Error('An Error Occurred');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', e);
        }
    }

    public clearForm() {
        this.branchName = null;
        this.branchImage = null;
        this.branchAddressLine1 = null;
        this.branchAddressLine2 = null;
        this.branchAddressTown = null;
        this.branchAddressPostcode = null;
        this.branchPrimaryContactName = null;
        this.branchPrimaryContactPhone = null;
        this.branchPrimaryContactEmail = null;
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public ngOnInit(): void {
        this.menuService.setActive('branches');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                const organisationId = params.organisation_id;
                this.organisationId = organisationId;
                this.organisationService.fetchOrganisation(organisationId);
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.branchImage = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }
}
