import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateNewOrganisationRequest, CreateUserRequest } from '@scaffold/mediccoms-api-client/requests';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Branch } from '../../models/branch';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { BranchesService } from '../branches/branches.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { Organisation } from '../../models/organisation';
import { MembersService } from '../members/members.service';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { UserRole } from '../members/members';
import { FlashMessageService } from '../../services/flash-message.service';
import { MenuService } from '../../services/menu.service';
import { ConnectionService } from '../connections/connections.service';


@Component({
    selector: 'app-add-connection',
    templateUrl: './add-connection.html',
    styleUrls: ['./add-connection.scss']
})
export class AddConnectionPage implements OnInit, OnDestroy {

    public branches: Branch[] = [];
    public userOrganisationID: string = null;
    private isDestroyed: Subject<void> = new Subject<void>();
    public organisation: Organisation = null;
    public branch: Branch = null;
    public organisationId: string = null;
    public branchId: string = null;

    public initiatingBranch: string = null;
    public recipientEmail: string = null;

    constructor(
        private api: ApiClientService,
        private branchService: BranchesService,
        private connectionService: ConnectionService,
        private flashMessageService: FlashMessageService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {

    }

    public ngOnInit(): void {
        this.menuService.setActive('connections');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.branchService.getBranches().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branches => this.branches = branches);

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => this.branch = branch);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id') && params.hasOwnProperty('branch_id')) {
                const organisationId = params.organisation_id;
                const branchId = params.branch_id;
                this.organisationId = organisationId;
                this.branchId = branchId;
                this.initiatingBranch = branchId;
                this.organisationService.fetchOrganisation(organisationId);
                this.branchService.fetchBranch(organisationId, branchId);
                this.branchService.fetchBranches(organisationId);
            } else if (params.hasOwnProperty('organisation_id')) {
                const organisationId = params.organisation_id;
                this.organisationId = organisationId;
                this.organisationService.fetchOrganisation(organisationId);
                this.branchService.fetchBranches(organisationId);
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public get isReady() {
        if (this.branchId && this.organisationId) {
            return !!this.branch && !!this.organisation;
        } else if (this.organisationId) {
            return !!this.organisation;
        } else {
            return true;
        }
    }

    public async createConnection() {
        let error = false;
        if (!this.recipientEmail) {
            error = true;
            this.flashMessageService.showMessage('error', 'You need to provide a recipient email address');
        }

        if (!this.initiatingBranch) {
            error = true;
            this.flashMessageService.showMessage('error', 'You need to select an initiating branch');
        }

        if (error) {
            return;
        }
        const response = await this.connectionService.createConnection(this.recipientEmail, this.initiatingBranch);

        if (response.invite_id && response.token) {
            if (this.branch) {
                this.router.navigate(
                    ['organisations', this.organisationId, 'branches', this.branchId, 'connections', 'invites', response.invite_id, response.token, 'send']
                );
            } else {
                this.router.navigate(['organisations', this.organisationId, 'connections', 'invites', response.invite_id, response.token, 'send']);
            }
        } else {
            this.flashMessageService.showMessage('error', 'An error occurred, try again');
        }
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }
}
