<app-header></app-header>

<div class="container-fluid">
    <div class="row">

        <app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

        <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
            <ng-container *ngIf="!isReady">
                Loading...
            </ng-container>
            <ng-container *ngIf="isReady">
                <app-breadcrumb>
                    <ng-container *ngIf="organisationId">
                        <li class="breadcrumb-item" (click)="goToPage('organisations')"
                            *can="'view'; target:'organisations'">
                            Organisations
                        </li>
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId)">
                            {{ organisation.name }}
                        </li>
                    </ng-container>
                    <ng-container *ngIf="branchId">
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId + '/branches')">
                            Locations
                        </li>
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
                            {{ branch.name }}
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            Connections
                        </li>
                    </ng-container>
                    <ng-container *ngIf="!branchId">
                        <li class="breadcrumb-item" aria-current="page"
                            (click)="goToPage('organisations/' + organisationId + '/connections')">
                            Connections
                        </li>
                    </ng-container>
                    <li class="breadcrumb-item active" aria-current="page">Create Connection Invite</li>
                </app-breadcrumb>
                <header class="d-block">
                    <h1 class="h1">Create Connection Invite</h1>
                </header>

                <app-flash-messages></app-flash-messages>

                <div class="row">
                    <div class="col-xl-5">
                        <div class="profile-options white-box">
                            <form class="base-form">

                                <div class="form-group">
                                    <label for="recipientEmail">Recipient Email</label>
                                    <input type="text" class="form-control" id="recipientEmail"
                                           aria-describedby="recipientEmail" placeholder="Enter Email"
                                           [(ngModel)]="recipientEmail" name="recipientEmail">
                                </div>

                                <div class="form-group">
                                    <label for="initiating_branch">Initiating Location</label>
                                    <select class="form-control" id="initiating_branch"
                                            [(ngModel)]="initiatingBranch" [disabled]="!!branchId"
                                            [ngModelOptions]="{ standalone: true }">
                                        <option disabled [value]="null">Select Location</option>
                                        <option *ngFor="let branch of branches"
                                                [value]="branch.id">{{ branch.name }}</option>
                                    </select>
                                </div>

                            </form>
                        </div>
                        <app-button (click)="createConnection()" type="submit" class="active remove-member small">Create
                        </app-button>
                    </div>
                </div>
            </ng-container>
        </main>

    </div>
</div>


