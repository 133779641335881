import { Injectable } from '@angular/core';
import { User } from '../../models/user';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Branch } from '../../models/branch';

export interface AllMembers {
    page: number;
    users: User[];
}

@Injectable()
export class MembersService {

    public members: User[] = [];
    public membersFiltered: User[] = [];
    private allMembers: AllMembers[] = [];
    private allUsers: User[] = [];
    private membersChanged: Subject<User[]> = new Subject<User[]>();
    private allMembersChanged: Subject<User[]> = new Subject<User[]>();
    private totalMembersChanged: Subject<number> = new Subject<number>();
    private currentPageChanged: Subject<number> = new Subject<number>();
    private totalPagesChanged: Subject<number> = new Subject<number>();

    private filterTerm: string = null;
    private currentPage: number = null;
    private currentUserPage: number = null;
    private totalPages: number = null;
    private perPage: number = null;
    public Roles: string[] = [];

    constructor(
        private api: ApiClientService,
    ) {
    }

    public getMembers(): Observable<User[]> {
        return this.membersChanged.asObservable();
    }

    public getAllMembers(): Observable<User[]> {
        return this.allMembersChanged.asObservable();
    }

    //     return new Observable<User[]>(observer => {
    //         let users: User[] = [];
    //
    //         // for (const members of this.allMembers) {
    //         //     for (const member of members.users) {
    //         //         users.push(member);
    //         //     }
    //         // }
    //         //
    //         // observer.next(users);
    //
    //         this.membersChanged.asObservable().subscribe(value => {
    //             users = [];
    //
    //             for (const members of this.allMembers) {
    //                 for (const member of members.users) {
    //                     users.push(member);
    //                 }
    //             }
    //             observer.next(value);
    //         });
    //     });
    // }

    public getTotalMembers(): Observable<number> {
        return this.totalMembersChanged.asObservable();
    }

    public getCurrentPage(): Observable<number> {
        return this.currentPageChanged.asObservable();
    }

    public getTotalPages(): Observable<number> {
        return this.totalPagesChanged.asObservable();
    }

    public getMemberById(id: string): User {
        return this.members.find(member => member.id === id);
    }

    public filterByRole(role: string): void {
        this.filterTerm = role;

        const users: User[] = [];
        for (const page of this.allMembers) {
            for (const user of page.users) {
                users.push(user);
            }
        }

        if (!role) {
            if (this.allMembers.length && !this.filterTerm) {
                this.totalMembersChanged.next(users.length);
                this.currentPageChanged.next(this.allMembers[0].page);
                this.totalPagesChanged.next(this.allMembers.length);
                this.membersChanged.next(this.allMembers[0].users);
            }
            return;
        }

        this.membersFiltered = users.filter(member => member.role === role);
        this.totalMembersChanged.next(this.membersFiltered.length);
        this.currentPageChanged.next(1);
        this.totalPagesChanged.next(Math.ceil(this.membersFiltered.length / this.perPage));
        const filteredUsers = this.membersFiltered.slice(((this.currentUserPage - 1) * this.perPage), (this.currentUserPage * this.perPage));
        this.membersChanged.next(filteredUsers);
    }

    public getPage(page: number) {
        this.currentUserPage = page;
        if (!this.filterTerm) {
            const currentPage = this.allMembers.filter(members => members.page === page);
            if (currentPage.length) {
                this.membersChanged.next(currentPage[0].users);
            }
        } else {
            const filteredUsers = this.membersFiltered.slice(((this.currentUserPage - 1) * this.perPage), (this.currentUserPage * this.perPage));
            this.membersChanged.next(filteredUsers);
        }
    }

    public async fetchAllMembers(searchTerm?: string, organisationId: string| null = null, page?: number) {

        if (!page || page === 1) {
            this.allUsers = [];
        }

        const response = await this.fetchUsersPage(searchTerm, organisationId, page);
        this.currentPage = response.pagination.current_page;

        for (const branch of response.users) {
            const userModel = User.createOne(branch);
            this.allUsers.push(userModel);
        }

        // Get remainder of pages for current query
        if (this.totalPages > 1 && this.currentPage < this.totalPages) {
            this.fetchAllMembers(searchTerm, organisationId, this.currentPage + 1);
        } else {
            this.allMembersChanged.next(this.allUsers);
        }
    }

    private async fetchUsersPage(searchTerm?: string, organisationId: string|null = null, page?: number) {
        const response = await this.api.users.search({
            page: page || 1,
            search_type: organisationId ? null : 'internal',
            search_name: searchTerm,
            organisation_id: organisationId,
        }).toPromise();
        this.totalPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
        return response;
    }

    public async searchMembers(searchTerm?: string, organisationId: string|null = null, page?: number) {
        const response = await this.fetchUsersPage(searchTerm, organisationId, page);

        this.currentPage = response.pagination.current_page;
        const members = User.createMany(response.users);

        if (this.currentPage === 1) {
            this.allMembers = [{
                page: this.currentPage,
                users: members,
            }];
            if (!this.filterTerm) {
                this.membersChanged.next(members);
                this.totalMembersChanged.next(response.pagination.total_items);
                this.currentPageChanged.next(response.pagination.current_page);
                this.totalPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
                this.perPage = response.pagination.per_page;
                this.totalPagesChanged.next(this.totalPages);
            } else {
                this.filterByRole(this.filterTerm);
            }
        } else {
            this.allMembers.push({
                page: this.currentPage,
                users: members,
            });
        }
        // Get remainder of pages for current query
        if (this.totalPages > 1 && this.currentPage < this.totalPages) {
            this.searchMembers(searchTerm, organisationId, this.currentPage + 1);
        }
    }

    public async fetchMember(id: string): Promise<User> {
        const response = await this.api.users.get(id).toPromise();
        let user = this.members.find(member => member.id === id);
        if (!user) {
            user = User.createOne({id: response.user.id});
            this.members.push(user);
        }
        user.fill(response.user);
        return user;
    }

    public async fetchMemberBranches(id: string): Promise<string[]> {
        const response = await this.api.users.getBranches(id).toPromise();
        return response.branches;
    }

}
