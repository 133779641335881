import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { UserService } from '../../services/user.service';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { User } from '../../models/user';
import { BehaviorSubject, Subject } from 'rxjs';
import { MembersService } from './members.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MenuService } from '../../services/menu.service';

export interface UserRole {
    name: string;
    key: string;
    level: number;
}

@Component({
    selector: 'app-members',
    templateUrl: './members.html',
    styleUrls: ['./members.scss']
})
export class MembersPage implements OnDestroy, OnInit {
    private _page: number = null;
    private _searchTerm: string = null;
    public selectedRole: UserRole = null;
    private currentUser: User = null;

    private isDestroyed: Subject<void> = new Subject<void>();
    private pageChanged: Subject<number> = new Subject<number>();
    private searchTermChanged: Subject<string> = new Subject<string>();
    public notificationType: string = null;

    public members: User[] = [];
    public userRoles: UserRole[];
    public totalMembers = 0;
    public totalPages = 0;

    public get page(): number {
        return this._page;
    }

    public set page(value: number) {
        this._page = value;
        this.pageChanged.next(value);
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    public set searchTerm(value: string) {
        this._searchTerm = value;
        this.searchTermChanged.next(value);
    }

    constructor(
        private api: ApiClientService,
        private membersService: MembersService,
        private menuService: MenuService,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
    ) {
        this.route.queryParams.subscribe(params => {
            this.notificationType = params['notificationStatus'];
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public ngOnInit(): void {
        this.menuService.setActive('members');

        this.membersService.getMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((members) => this.members = members);

        this.membersService.getTotalMembers().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalMembers = total);

        this.membersService.getCurrentPage().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.page = currentPage);

        this.membersService.getTotalPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalPages = totalPages);

        this.pageChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.membersService.getPage(page));

        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                this.userRoles = [];
                const userRoles = environment.user_roles;

                for (const role of userRoles) {
                    if (this.currentUser.can('seeUserRole', role)) {
                        this.userRoles.push(role);
                    }
                }
            }
        });

        this.searchTermChanged.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe(() => {
            this.page = 1;
            this.membersService.searchMembers(this.searchTerm);
        });

        this.membersService.searchMembers(null);
    }

    public addMember() {
        this.router.navigate(['members', 'new']);
    }

    public showMember(member: User) {
        this.router.navigate(['members', member.id]);
    }

    public filterMembers(selectedRole?: string) {
        if (selectedRole) {
            this.selectedRole = this.userRoles.find(role => role.key === selectedRole);
        } else {
            this.selectedRole = null;
        }
        this.membersService.filterByRole(selectedRole);
    }

}
