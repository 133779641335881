<app-header></app-header>

<div class="container-fluid">
	<div class="row">

		<app-side-menu id="sidebarMenu" class="col-md-3 d-md-block sidebar collapse"></app-side-menu>

		<main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
			<h1 class="h1">Messages</h1>

			<app-flash-messages></app-flash-messages>

			<div class="row" *ngIf="stats">
				<div class="col-xl-4">
					<div class="messages white-box" *ngIf="stats.all">

						<h2 class="messages-title">
							All Messages
						</h2>

						<div class="cols">
							<div class="message-label">
								Incoming
							</div>
							<div class="message-qty">
								{{ stats.all.received }}
							</div>
						</div>
						<div class="cols">
							<div class="message-label">
								Outgoing
							</div>
							<div class="message-qty">
								{{ stats.all.sent }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-4">
					<div class="messages white-box" *ngIf="stats.directMessage">

						<h2 class="messages-title">
							Individual Messages
						</h2>

						<div class="cols">
							<div class="message-label">
								Incoming
							</div>
							<div class="message-qty">
								{{ stats.directMessage.received }}
							</div>
						</div>
						<div class="cols">
							<div class="message-label">
								Outgoing
							</div>
							<div class="message-qty">
								{{ stats.directMessage.sent }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-4">
					<div class="messages white-box" *ngIf="stats.groupMessage">

						<h2 class="messages-title">
							Group Messages
						</h2>

						<div class="cols">
							<div class="message-label">
								Incoming
							</div>
							<div class="message-qty">
								{{ stats.groupMessage.received }}
							</div>
						</div>
						<div class="cols">
							<div class="message-label">
								Outgoing
							</div>
							<div class="message-qty">
								{{ stats.groupMessage.sent }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

	</div>
</div>
