import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Observable, Subject } from 'rxjs';
import { CaseStats } from '../../models/case-stats';
import { CaseConversation } from '../../models/case-conversation';
import { CaseEvent } from '../../models/case-conversation-events/case-event';
import { subDays } from "date-fns";

export type ReportParameters = {
    caseStatus?: 'all' | 'opened' | 'resolved';
    type?: 'all' | 'incoming' | 'outgoing';
    userId?: string;
    organisationId?: string;
    patientId?: string;
    branchId?: string;
    before?: string;
    after?: string;
};

@Injectable()
export class CaseService {

    public caseStats: Subject<CaseStats> = new Subject<CaseStats>();
    public cases: Subject<CaseConversation[]> = new Subject<CaseConversation[]>();
    public case: Subject<CaseConversation> = new Subject<CaseConversation>();

    public caseEvents: CaseEvent[] = [];
    public allCaseEvents: Subject<CaseEvent[]> = new Subject<CaseEvent[]>();

    private totalCasesChanged: Subject<number> = new Subject<number>();
    private currentPageChanged: Subject<number> = new Subject<number>();
    private totalPagesChanged: Subject<number> = new Subject<number>();

    public totalCases = 0;
    public openCases = 0;
    public resolvedCases = 0;

    constructor(
        private api: ApiClientService,
    ) {
    }

    public async searchCases(page?: number, reportParameters?: ReportParameters) {
        const response = await this.api.cases.searchCases({
            page: page || 1,
            patient_id: reportParameters?.patientId || null,
            user_id: reportParameters?.userId || null,
            case_status: reportParameters?.caseStatus || null,
            organisation_id: reportParameters?.organisationId || null,
            branch_id: reportParameters?.branchId || null,
            type: reportParameters?.type || null,
            before: reportParameters?.before || null,
            after: reportParameters?.after || null,
        }).toPromise();

        this.caseStats.next(CaseStats.createOne(response.stats));
        const cases = CaseConversation.createMany(response.cases as any);
        this.cases.next(cases);

        const totalItems = response.pagination.total_items;
        const currentPage = response.pagination.current_page;
        const totalPages = Math.ceil(totalItems / response.pagination.per_page);
        this.totalCasesChanged.next(totalItems);
        this.currentPageChanged.next(currentPage);
        this.totalPagesChanged.next(totalPages);
    }

    public async fetchCase(caseId: string) {
        const response = await this.api.cases.getCase(caseId).toPromise();
        const caseConversation = CaseConversation.createOne(response.case as any);
        this.case.next(caseConversation);
    }

    public getCaseStats(): Observable<CaseStats> {
        return this.caseStats.asObservable();
    }

    public getCases(): Observable<CaseConversation[]> {
        return this.cases.asObservable();
    }

    public getCase(): Observable<CaseConversation> {
        return this.case.asObservable();
    }

    public getTotalCases(): Observable<number> {
        return this.totalCasesChanged.asObservable();
    }

    public getCurrentPage(): Observable<number> {
        return this.currentPageChanged.asObservable();
    }

    public getTotalPages(): Observable<number> {
        return this.totalPagesChanged.asObservable();
    }

    public async fetchAllEvents(caseId: string) {
        this.caseEvents = [];
        return this.fetchEvents(caseId, new Date().toISOString());
    }

    public async fetchEvents(caseId: string, before: string) {
        const response = await this.api.cases.searchEvents(caseId, {
            before,
        }).toPromise();

        if (response.events.length) {
            for (const event of response.events) {
                const eventModel = CaseConversation.createEvent({
                    id: event.id,
                    type: event.type,
                    conversation: null,
                });
                eventModel.fillFromApi(event as any);
                this.caseEvents.push(eventModel);
            }

            const lastEvent = this.caseEvents[this.caseEvents.length - 1];
            this.fetchEvents(caseId, lastEvent.sentAt);
        } else {
            this.allCaseEvents.next(this.caseEvents);
        }
    }

    public getEvents(): Observable<CaseEvent[]> {
        return this.allCaseEvents.asObservable();
    }

    public async fetchBranchStats(id: string): Promise<CaseStats> {
        const response = await this.api.cases.searchCases({
            branch_id: id,
            patient_id: null,
            after: subDays(new Date(), 30).toISOString(),
        }).toPromise();

        return Promise.resolve(CaseStats.createOne(response.stats));
    }

    public clear(): void {
        console.log('clear cases.service');
        this.caseEvents = [];
        this.totalCases = 0;
        this.openCases = 0;
        this.resolvedCases = 0;
    }
}
