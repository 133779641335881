import { AbstractModel } from './abstract.model';
import { CaseResolvedBreakdown } from './case-resolved-breakdown';

export class CaseStats extends AbstractModel {
    public total: number = null;
    public open: number = null;
    public resolved: number = null;
    public daily: CaseResolvedBreakdown[] = null;
    public monthly: CaseResolvedBreakdown[] = null;

    public fill(data: Partial<CaseStats>) {

        if (data.total !== undefined) {
            this.total = data.total;
        }
        if (data.open !== undefined) {
            this.open = data.open;
        }
        if (data.resolved !== undefined) {
            this.resolved = data.resolved;
        }
        if (data.daily !== undefined) {
            this.daily = CaseResolvedBreakdown.createMany(data.daily);
        }
        if (data.monthly !== undefined) {
            this.monthly = CaseResolvedBreakdown.createMany(data.monthly);
        }
    }
}
