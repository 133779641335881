import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

class pageTotal {
}

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
	@Input() public currentPage: number;
	@Input() public totalPages: number;
	@Input() public totalItems: number;

    @Output() public currentPageChange: EventEmitter<number> = new EventEmitter<number>();
	@Output() public totalItemsChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    public ngOnInit(): void {

    }

    public get pageTotal(): number[] {
        return Array(this.totalPages).fill(0).map((x, i) => i + 1);
    }

    public prevPage() {
        this.setPage(this.currentPage - 1);
    }

    public setPage(page: number) {
        if (page < 1) { page = 1; }
		if (page > this.totalPages) { page = this.totalPages; }
        this.currentPage = page;
        this.currentPageChange.emit(page);
    }

    public nextPage() {
        this.setPage(this.currentPage + 1);
    }

}
