import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Organisation } from '../../models/organisation';
import { User } from '../../models/user';
import { CaseStats } from '../../models/case-stats';
import { CaseConversation } from '../../models/case-conversation';
import { CaseService, ReportParameters } from '../../pages/cases/cases.service';
import { MembersService } from '../../pages/members/members.service';
import { MenuService } from '../../services/menu.service';
import { OrganisationsService } from '../../pages/organisations/organisations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-report-cases',
  templateUrl: './report-cases.component.html',
  styleUrls: ['./report-cases.component.scss']
})
export class ReportCasesComponent implements OnInit, OnDestroy {

  @Input() organisationId: string = null;
  @Input() branchId: string = null;
  private _page = 1;
  private isDestroyed: Subject<void> = new Subject<void>();
  private pageChanged: Subject<number> = new Subject<number>();
  notificationType: string;
  public organisation: Organisation = null;
  public users: User[] = null;
  public caseStats: CaseStats = null;
  public selectedType: 'all' | 'incoming' | 'outgoing' = 'all';

  public totalPages: number = null;
  public totalCases: number = null;

  public cases: CaseConversation[] = [];

  public reportStatus = 'all';
  private reportParameters: ReportParameters = null;

  public get page(): number {
    return this._page;
  }

  public set page(value: number) {
    this._page = value;
    this.pageChanged.next(value);
  }

  yearToDateData = [12, 19, 3, 5, 2, 3];

  constructor(
      private caseService: CaseService,
      private memberService: MembersService,
      private menuService: MenuService,
      private organisationService: OrganisationsService,
      private route: ActivatedRoute,
      private router: Router,
  ) { }

  public ngOnInit(): void {

    this.memberService.getAllMembers().pipe(
        takeUntil(this.isDestroyed)
    ).subscribe(users => this.users = users);

    this.caseService.getCaseStats().pipe(
        takeUntil(this.isDestroyed)
    ).subscribe(stats => this.caseStats = stats);

    this.caseService.getCases().pipe(
        takeUntil(this.isDestroyed)
    ).subscribe(cases => this.cases = cases);

    this.caseService.getTotalCases().pipe(
        takeUntil(this.isDestroyed)
    ).subscribe(totalCases => this.totalCases = totalCases);

    this.caseService.getTotalPages().pipe(
        takeUntil(this.isDestroyed)
    ).subscribe(totalPages => this.totalPages = totalPages);

    this.pageChanged.pipe(
        distinctUntilChanged(),
        takeUntil(this.isDestroyed),
    ).subscribe((page) => {
        this.caseService.searchCases(page, this.reportParameters);
    });

    this.memberService.fetchAllMembers();

    this.runReport();
  }

  public ngOnDestroy(): void {
    this.isDestroyed.next();
  }

  public runReport(reportParameters?) {
    let parameters = this.reportParameters;
    if (!parameters) {
      parameters = {};
    }
    if (reportParameters) {
      parameters = reportParameters;
    }
    parameters.organisationId = this.organisationId;
    if (this.branchId) {
      parameters.branchId = this.branchId;
    }
    parameters.type = this.selectedType;
    this.reportParameters = parameters;
    this.page = 1;
    this.caseService.searchCases(this.page, this.reportParameters);
  }

  public selectCase(caseId) {
    if (this.branchId) {
      this.router.navigate(['organisations', this.organisationId, 'branches', this.branchId, 'cases', caseId]);
    } else {
      this.router.navigate(['organisations', this.organisationId, 'cases', caseId]);
    }
  }

  public get isAllCases(): boolean {
    if (!this.totalCases || !this.caseStats) {
      return true;
    }

    return this.totalCases === this.caseStats.total;
  }

  public get caseTypes(): ('all' | 'incoming' | 'outgoing')[] {
    return [
      'all',
      'incoming',
      'outgoing',
    ];
  }

  public filterTypes(selectedType: string) {
    this.selectedType = this.caseTypes.find(role => role === selectedType);
    this.runReport();
  }
}
