import { NgModule } from '@angular/core';
import { UserService } from './user.service';
import { ConnectionsService } from './connections.service';
import { BranchesService } from '../pages/branches/branches.service';
import { ConnectionService } from '../pages/connections/connections.service';
import { CaseService } from '../pages/cases/cases.service';
import { SettingsService } from './settings.service';
import { CognitoAuthenticationService } from './cognito-authentication.service';
import { PolicyService } from './policy.service';
import { OrganisationsService } from '../pages/organisations/organisations.service';
import { MembersService } from '../pages/members/members.service';
import { FlashMessageService } from './flash-message.service';

const SERVICES = [
    BranchesService,
    CaseService,
    CognitoAuthenticationService,
    ConnectionsService,
    ConnectionService,
    FlashMessageService,
    MembersService,
    OrganisationsService,
    PolicyService,
    SettingsService,
    UserService,
];

@NgModule({
    providers: [
        ...SERVICES,
    ]
})
export class ServicesModule {
}
