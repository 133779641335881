import { Injectable } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { HttpEventType } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(
        private api: ApiClientService,
    ) {
    }

    public uploadImage(attachment: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.api.attachments.uploadImage(attachment).subscribe(result => {
                if (result.type === HttpEventType.Response) {
                    resolve(result.body.image_id);
                }
            });
        });
    }
}
