export interface Environment {
    /**
     * Whether or not this environment should be used as the default
     */
    default?: boolean;

    /**
     * The name of the environment
     */
    name: string;

    /**
     * The API endpoint URL for the environment
     */
    url: string;

    /**
     * The URL for RTM Service in the environment
     */
    rtm: string;

    /**
     * The expected host name for the environment
     */
    host: string;

    /**
     * AWS Cognito App Client ID for the environment
     */
    app_client_id: string;
}

interface Environments {
    [K: string]: Environment;
}

export const ENVIRONMENTS: Environments = {
    PRODUCTION: {
        default: true,
        name: 'Production',
        url: 'https://api.mediccoms.com/v1',
        rtm: 'https://rtm.mediccoms.com',
        host: 'admin.mediccoms.com',
        app_client_id: '1fis5k6mp9fqug30d5vinkfokq',
    },
    STAGING: {
        name: 'Staging',
        url: 'https://api.beta.mediccoms.com/v1',
        rtm: 'https://rtm.beta.mediccoms.com',
        host: 'admin.beta.mediccoms.com',
        app_client_id: '1fis5k6mp9fqug30d5vinkfokq',
    },
    DEVELOPMENT: {
        name: 'Development',
        url: 'https://api.dev.mediccoms.com/v1',
        rtm: 'https://rtm.dev.mediccoms.com',
        host: 'admin.dev.mediccoms.com',
        app_client_id: '1hd87nuglmarkpkurlslgr2btn',
    },
    LOCAL: {
        name: 'Local',
        url: 'https://mediccoms.dev.scaffold.digital/v1',
        rtm: 'http://mediccoms.dev.scaffold.digital:8080',
        host: 'localhost',
        app_client_id: '4g60338e4anfc1msi7brvle8mj',
    },
};
