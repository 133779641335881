import { Pipe, PipeTransform } from '@angular/core';
import { ApiClientService } from '@scaffold/mediccoms-api-client';

@Pipe({
    name: 'attachment',
})
export class AttachmentPipe implements PipeTransform {

    constructor(
        private api: ApiClientService,
    ) {
    }

    public transform(imageId: string, size?: string): any {
        return this.api.attachments.getImageUrl(imageId, size);
    }

}
