import { Injectable, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Connection } from '../../models/connection';
import { ApiClientService } from '@scaffold/mediccoms-api-client';
import { Branch } from '../../models/branch';
import { Observable, Subject } from 'rxjs';
import { AllMembers } from '../members/members.service';
import { CreateBranchRequest, EditBranchRequest } from '@scaffold/mediccoms-api-client/requests';

export interface AllConnections {
    page: number;
    connections: Connection[];
}

@Injectable()
export class BranchesService {

    private membersFiltered: User[] = [];
    private allMembers: AllMembers[] = [];
    private membersChanged: Subject<User[]> = new Subject<User[]>();
    private totalMembersChanged: Subject<number> = new Subject<number>();
    private currentMembersPageChanged: Subject<number> = new Subject<number>();
    private totalMembersPagesChanged: Subject<number> = new Subject<number>();
    private filterTerm: string = null;
    private currentMembersPage: number = null;
    private currentMembersUserPage: number = null;
    private totalMembersPages: number = null;
    private perMembersPage: number = null;

    private connectionsChanged: Subject<Connection[]> = new Subject<Connection[]>();
    private totalConnectionsChanged: Subject<number> = new Subject<number>();
    private currentConnectionsPageChanged: Subject<number> = new Subject<number>();
    private totalConnectionsPagesChanged: Subject<number> = new Subject<number>();
    private currentConnectionsPage: number = null;

    private totalConnectionsPages: number = null;


    private allBranches: Branch[] = [];
    private getAllBranchesSubject: Subject<Branch[]> = new Subject<Branch[]>();
    private branchesChanged: Subject<Branch[]> = new Subject<Branch[]>();
    private branchChanged: Subject<Branch> = new Subject<Branch>();
    private totalBranchesChanged: Subject<number> = new Subject<number>();
    private currentPageChanged: Subject<number> = new Subject<number>();
    private totalPagesChanged: Subject<number> = new Subject<number>();
    private currentPage: number = null;
    private totalPages: number = null;
    private perPage: number = null;

    public branches: Branch[] = [];

    public branchCases: string[] = [];
    public branchMembers: User[] = [];
    public totalBranchMembers = 0;

    constructor(
        private api: ApiClientService,
    ) {

    }

    public getBranches(): Observable<Branch[]> {
        return this.branchesChanged.asObservable();
    }

    public getBranch(): Observable<Branch> {
        return this.branchChanged.asObservable();
    }

    public getAllBranches(): Observable<Branch[]> {
        return this.getAllBranchesSubject.asObservable();
    }

    public getTotalBranches(): Observable<number> {
        return this.totalBranchesChanged.asObservable();
    }

    public getCurrentPage(): Observable<number> {
        return this.currentPageChanged.asObservable();
    }

    public getTotalPages(): Observable<number> {
        return this.totalPagesChanged.asObservable();
    }

    public getBranchMembers(): Observable<User[]> {
        return this.membersChanged.asObservable();
    }

    public getTotalBranchMembers(): Observable<number> {
        return this.totalMembersChanged.asObservable();
    }

    public getTotalMembersPages(): Observable<number> {
        return this.totalMembersPagesChanged.asObservable();
    }

    public getCurrentMembersPage(): Observable<number> {
        return this.currentMembersPageChanged.asObservable();
    }

    public getBranchConnections(): Observable<Connection[]> {
        return this.connectionsChanged.asObservable();
    }

    public getTotalBranchConnections(): Observable<number> {
        return this.totalConnectionsChanged.asObservable();
    }

    public getTotalConnectionsPages(): Observable<number> {
        return this.totalConnectionsPagesChanged.asObservable();
    }

    public getCurrentConnectionsPage(): Observable<number> {
        return this.currentConnectionsPageChanged.asObservable();
    }

    public async fetchAllBranches(organisationId: string) {
        const response = await this.fetchBranchesPage(organisationId, 1);
        this.currentPage = response.pagination.current_page;

        for (const branch of response.branches) {
            const branchModel = Branch.createOne(branch);
            this.allBranches.push(branchModel);
        }

        // Get remainder of pages for current query
        if (this.totalPages > 1 && this.currentPage < this.totalPages) {
            this.fetchBranchesPage(organisationId, this.currentPage + 1);
        } else {
            this.getAllBranchesSubject.next(this.allBranches);
        }
    }

    private async fetchBranchesPage(organisationId: string, page?: number) {
        return await this.api.branches.all(organisationId, {
            page: page || 1,
        }).toPromise();
    }

    public async fetchBranches(organisationId: string, page?: number) {
        const response = await this.fetchBranchesPage(organisationId, page);
        const branches = Branch.createMany(response.branches);
        this.branchesChanged.next(branches);
        this.totalBranchesChanged.next(response.pagination.total_items);
        this.currentPage = response.pagination.current_page;
        this.currentPageChanged.next(this.currentPage);
        this.totalPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
        this.totalPagesChanged.next(this.totalPages);
        this.perPage = response.pagination.per_page;
    }

    public async fetchBranch(organisationId: string, branchId: string) {
        const response = await this.api.branches.get(organisationId, branchId).toPromise();
        const branch = Branch.createOne(response.branch);
        this.branchChanged.next(branch);
    }

    public getBranchMembersPage(page: number) {
        this.currentMembersUserPage = page;
        if (!this.filterTerm) {
            const currentPage = this.allMembers.filter(members => members.page === page);
            if (currentPage.length) {
                this.membersChanged.next(currentPage[0].users);
            }
        } else {
            const filteredUsers = this.membersFiltered.slice(((this.currentMembersUserPage - 1) * this.perMembersPage), (this.currentMembersUserPage * this.perPage));
            this.membersChanged.next(filteredUsers);
        }
    }

    public async fetchBranchMembers(organisationId: string, branchId: string, page?: number) {
        const response = await this.api.branches.allUsers(organisationId, branchId, {
            page: page || 1,
        }).toPromise();

        this.currentMembersPage = response.pagination.current_page;
        const members = User.createMany(response.users);

        if (this.currentMembersPage === 1) {
            this.allMembers = [{
                page: this.currentMembersPage,
                users: members,
            }];
            if (!this.filterTerm) {
                this.membersChanged.next(members);
                this.totalMembersChanged.next(response.pagination.total_items);
                this.currentMembersPageChanged.next(response.pagination.current_page);
                this.totalMembersPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
                this.perMembersPage = response.pagination.per_page;
                this.totalMembersPagesChanged.next(this.totalMembersPages);
            } else {
                this.filterByRole(this.filterTerm);
            }
        } else {
            this.allMembers.push({
                page: this.currentMembersPage,
                users: members,
            });
        }
        // Get remainder of pages for current query
        if (this.totalMembersPages > 1 && this.currentMembersPage < this.totalMembersPages) {
            this.fetchBranchMembers(organisationId, branchId, this.currentMembersPage + 1);
        }
    }

    public async fetchConnections(organisationId: string, branchId: string, page?: number) {
        const response = await this.api.branches.allConnections(organisationId, branchId, {
            page: page || 1,
        }).toPromise();

        const connections = Connection.createMany(response.connections as any);

        this.currentConnectionsPage = response.pagination.current_page;
        this.currentConnectionsPageChanged.next(this.currentConnectionsPage);
        this.totalConnectionsPages = Math.ceil(response.pagination.total_items / response.pagination.per_page);
        this.totalConnectionsPagesChanged.next(this.totalConnectionsPages);
        this.connectionsChanged.next(connections);
        // this.totalConnections = response.pagination.total_items;
        this.totalConnectionsChanged.next(response.pagination.total_items);
    }

    public filterByRole(role: string): void {
        this.filterTerm = role;

        const users: User[] = [];
        for (const page of this.allMembers) {
            for (const user of page.users) {
                users.push(user);
            }
        }

        if (!role) {
            if (this.allMembers.length && !this.filterTerm) {
                this.totalMembersChanged.next(users.length);
                this.currentMembersPageChanged.next(this.allMembers[0].page);
                this.totalMembersPagesChanged.next(this.allMembers.length);
                this.membersChanged.next(this.allMembers[0].users);
            }
            return;
        }

        this.membersFiltered = users.filter(member => member.role === role);
        this.totalMembersChanged.next(this.membersFiltered.length);
        this.currentMembersPageChanged.next(1);
        const totalPages = Math.ceil(this.membersFiltered.length / this.perMembersPage);
        this.totalMembersPagesChanged.next(totalPages);
        const filteredUsers = this.membersFiltered.slice(((this.currentMembersUserPage - 1) * this.perMembersPage), (this.currentMembersUserPage * this.perMembersPage));
        this.membersChanged.next(filteredUsers);
    }

    public getBranchById(id: string) {
        return this.branches.find(branch => branch.id === id);
    }

    // public async createBranch(orgid: string, data): Promise<any> {
    //     try {
    //         return await this.api.branches.create(orgid, data).toPromise();
    //     } catch (error) {
    //         return {
    //             error: true,
    //             errors: error
    //         };
    //     }
    // }

    public create(organisationId: string, request: CreateBranchRequest) {
        return this.api.branches.create(organisationId, request).toPromise();
    }

    public async updateBranch(organisationId: string, branch: Branch): Promise<any> {
        if (branch.address.line_2 === null) {
            branch.address.line_2 = '';
        }

        const request: EditBranchRequest = {
            name: branch.name,
            address: branch.address,
            primary_contact: branch.primary_contact,
        };
        if (branch.image) {
            request.image = branch.image;
        }
        try {
            return await this.api.branches.edit(organisationId, branch.id, request).toPromise();
        } catch (error) {
            return {
                error: true,
                errors: error
            };
        }
    }

    public async deleteBranch(organisationId: string, branchId: string): Promise<any> {
        try {
            return await this.api.branches.delete(organisationId, branchId).toPromise();
        } catch (error) {
            return {
                error: true,
                errors: error
            };
        }
    }

    public clear(): void {
        console.log('clear branches.service');
        this.totalBranchMembers = 0;
        this.membersFiltered = [];
        this.allMembers = [];
        this.allBranches = [];
        this.branches = [];
        this.branchCases = [];
        this.branchMembers = [];
    }

}
