<app-header></app-header>

<div class="container-fluid">
    <div class="row">

        <app-side-menu id="sidebarMenu" class="col-md-3 col-xl-2 d-md-block sidebar collapse"></app-side-menu>

        <main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
            <ng-container>
                <app-breadcrumb>
                    <li class="breadcrumb-item" (click)="goToPage('organisations')"
                        *can="'view'; target:'organisations'">
                        Organisations
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Create Organisation
                    </li>
                </app-breadcrumb>

                <header class="d-flex">
                    <h1 class="h1">Create Organisation</h1>
                </header>

                <app-flash-messages></app-flash-messages>

                <div class="row">
                    <div class="col-xl-5">
                        <div class="profile-options white-box">
                            <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar" *ngIf="organisationImage">
                                <span class="add-icon">
                                    <img src="assets/icon/purple-edit-icon.svg" />
                                </span>
                                <span class="profile-img" [style.backgroundImage]="organisationImage | attachment | backgroundImage"></span>
                            </div>
                            <div data-toggle="modal" data-target="#add-photo" class="image profile-avatar profile-avatar-fallback large"
                                 *ngIf="!organisationImage">
                                <span class="add-icon">
                                    <img src="assets/icon/purple-add-icon.svg" />
                                </span>
                                <img class="profile-img " src="/assets/icon/upload-photo-icon.svg">
                            </div>
                            <form class="base-form">
                                <input type="hidden" [(ngModel)]="organisationEnabledMessages" name="messages_enabled">
                                <input type="hidden" [(ngModel)]="organisationEnabledCases" name="cases_enabled">

                                <div class="form-group">
                                    <label for="organisation_name">Name</label>
                                    <input type="text" class="form-control" id="organisation_name"
                                           aria-describedby="organisation_name" placeholder="Organisation Name"
                                           [(ngModel)]="organisationName" name="organisation_name">
                                </div>

                                <div class="form-group">
                                    <label for="address_line_1">Address Line 1</label>
                                    <input type="text" class="form-control" id="address_line_1"
                                           aria-describedby="address_line_1" placeholder="Address Line 1"
                                           [(ngModel)]="organisationAddressLine1" name="address_line_1">
                                </div>

                                <div class="form-group">
                                    <label for="address_line_2">Address Line 2</label>
                                    <input type="text" class="form-control" id="address_line_2"
                                           aria-describedby="address_line_2" placeholder="Address Line 2"
                                           [(ngModel)]="organisationAddressLine2" name="address_line_2">
                                </div>

                                <div class="form-group">
                                    <label for="address_town">Town</label>
                                    <input type="text" class="form-control" id="address_town"
                                           aria-describedby="address_town" placeholder="Town"
                                           [(ngModel)]="organisationAddressTown" name="address_town">
                                </div>

                                <div class="form-group">
                                    <label for="address_postcode">Postcode</label>
                                    <input type="text" class="form-control" id="address_postcode"
                                           aria-describedby="address_postcode" placeholder="Postcode"
                                           [(ngModel)]="organisationAddressPostcode" name="address_postcode">
                                </div>

                                <div class="form-group">
                                    <label for="primary_contact_name">Primary Contact Name</label>
                                    <input type="text" class="form-control" id="primary_contact_name"
                                           aria-describedby="primary_contact_name" placeholder="Primary Contact Name"
                                           [(ngModel)]="organisationPrimaryContactName" name="primary_contact_name">
                                </div>

                                <div class="form-group">
                                    <label for="primary_contact_email">Primary Contact Email</label>
                                    <input type="text" class="form-control" id="primary_contact_email"
                                           aria-describedby="primary_contact_email" placeholder="Primary Contact Email"
                                           [(ngModel)]="organisationPrimaryContactEmail" name="primary_contact_email">
                                </div>

                                <div class="form-group">
                                    <label for="primary_contact_phone">Primary Contact Phone</label>
                                    <input type="text" class="form-control" id="primary_contact_phone"
                                           aria-describedby="primary_contact_phone" placeholder="Primary Contact Phone"
                                           [(ngModel)]="organisationPrimaryContactPhone" name="primary_contact_phone">
                                </div>
                            </form>
                        </div>
                        <app-button (click)="createOrganisation()" type="submit" class="active remove-member small">Create</app-button>
                    </div>
                    <div class="col-xl-7">
                        <div class="user-preferences white-box">
                            <h2>
                                Organisation Settings
                            </h2>
                            <form class="base-form">
                                <div class="form-group-inline">
                                    <label for="messages_enabled">Messaging Enabled</label>
                                    <div class="form-switch">
                                        <input type="checkbox" id="messages_enabled" #messagesEnabled
                                               (click)="toggleMessages(messagesEnabled)">
                                        <span class="slider round"></span>
                                    </div>
                                </div>
                                <div class="form-group-inline">
                                    <label for="cases_enabled">Cases Enabled</label>
                                    <div class="form-switch">
                                        <input type="checkbox" id="cases_enabled" #casesEnabled
                                               (click)="toggleCases(casesEnabled)">
                                        <span class="slider round"></span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
        </main>

    </div>
</div>

<app-modal modalTitle="Add Branch Icon" class="modal fade" id="add-photo" tabindex="-1" role="dialog"
           aria-labelledby="add-photo" aria-hidden="true">
    <div class="modal-body image-upload-body">
        <img src="/assets/icon/drop-photos-here-icon.svg">
        <input #imageInput type="file" name="logo" accept="image/*" (change)="onImageAdded()">
        <div class="sep-text">
            or
        </div>
    </div>
    <div class="modal-footer">
        <button #closeModal style="display:none;" data-dismiss="modal">Close</button>
        <app-button class="active" (click)="clickImageUpload()">Upload Photo</app-button>
    </div>
</app-modal>
