import {AbstractModel} from './abstract.model';

export class Address extends AbstractModel {

	public line_1: string = null;
	public line_2: string = null;
	public town: string = null;
	public postcode: string = null;

	public fill(data: Partial<Address>) {
		if (data.line_1 !== undefined) {
			this.line_1 = data.line_1;
		}
		if (data.line_2 !== undefined) {
			this.line_2 = data.line_2;
		}
		if (data.town !== undefined) {
			this.town = data.town;
		}
		if (data.postcode !== undefined) {
			this.postcode = data.postcode;
		}
	}

	// public join(separator = ', '): string {
	// 	return [
	// 		this.line_1,
	// 		this.line_2,
	// 		this.town,
	// 		this.postcode,
	// 	].filter(part => !!part).join(separator);
	// }
	//
	// public toString(): string {
	// 	return this.join();
	// }

}
