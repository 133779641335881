import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CaseService } from './cases.service';
import { MenuService } from '../../services/menu.service';
import { Organisation } from '../../models/organisation';
import { OrganisationsService } from '../organisations/organisations.service';
import { MembersService } from '../members/members.service';

@Component({
    selector: 'app-cases',
    templateUrl: './cases.html',
    styleUrls: ['./cases.scss']
})
export class CasesPage implements OnInit, OnDestroy {

    public organisationId: string = null;
    public organisation: Organisation = null;
    private isDestroyed: Subject<void> = new Subject<void>();

    constructor(
        private caseService: CaseService,
        private memberService: MembersService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.menuService.setActive('cases');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => this.organisation = organisation);

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
                this.organisationService.fetchOrganisation(params.organisation_id);
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }
}
