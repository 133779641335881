<div class="filters d-flex">
    <div class="dropdown show">
        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="dropdown-toggle-filter">Filter by Case Type:</span> <span class="dropdown-toggle-value text-capitalize">{{ selectedType }}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item text-capitalize" *ngFor="let caseType of caseTypes" (click)="filterTypes(caseType)">{{ caseType }}</a>
        </div>
    </div>
</div>
<div class="row" *ngIf="caseStats">
    <div class="col-xl-3">
        <app-report-stats [caseStats]="caseStats"></app-report-stats>
    </div>
    <div class="col-xl-9">
        <div class="row">
            <div class="col-lg-6">
                <app-chart chartTitle="Cases Resolved Last 30 Days" xLabel="Date" [chartData]="caseStats.monthly" chartType="line"></app-chart>
            </div>
            <div class="col-lg-6">
                <app-chart chartTitle="Cases Resolved Last 24 Hours" xLabel="Time" [chartData]="caseStats.daily" chartType="line"></app-chart>
            </div>
        </div>
    </div>
    <div class="col-xl-12">
        <hr>
        <div class="row">
            <div class="col-xl-4">
                <app-report-filters [users]="users" (reportParameters)="runReport($event)"></app-report-filters>
            </div>
            <div class="col-xl-8">
                <app-report-case-table [isAll]="isAllCases" [cases]="cases" [totalCases]="totalCases" (caseSelected)="selectCase($event)">
                    <app-pagination [(currentPage)]="page" [totalPages]="totalPages" [totalItems]="totalCases"></app-pagination>
                </app-report-case-table>
            </div>
        </div>
    </div>
</div>
