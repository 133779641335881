import { AbstractModel } from './abstract.model';
import { Branch } from './branch';

export class Connection extends AbstractModel {
    public id: string = null;
    public organisation_id: string = null;
    public name: string = null;
    public image: string = null;
    public created_at: string = null;
    public branches: Branch[] = null;

    public fill(data: Partial<any>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.organisation_id !== undefined) {
            this.organisation_id = data.organisation_id;
        }
        if (data.name !== undefined) {
            this.name = data.name;
        }
        if (data.image !== undefined) {
            this.image = data.image;
        }
        if (data.created_at !== undefined) {
            this.created_at = data.created_at;
        }
        if (data.branches !== undefined) {
            this.branches = Branch.createMany(data.branches);
        }
    }

    public get initials(): string {

        if (this.branches.length === 1) {
            return this.branches[0].initials;
        }

        const letters = (() => {
            if (!this.name) { return []; }

            const capitalLetters = this.name.split('').filter(letter => {
                const charCode = letter.charCodeAt(0);
                return charCode >= 65 && charCode <= 90;
            });

            if (capitalLetters.length) { return capitalLetters; }

            const words = this.name.split(' ');

            if (words.length) { return words; }
        })();

        return letters.length ? letters.slice(0, 2).join('') : 'N/A';
    }

    public get branch(): Branch {
        if (this.branches.length === 1) {
            return this.branches[0];
        }
    }
}
