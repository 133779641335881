import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../../models/user';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { OrganisationsService } from './organisations.service';
import { Organisation } from '../../models/organisation';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-organisations',
    templateUrl: './organisations.html',
    styleUrls: ['./organisations.scss']
})
export class OrganisationsPage implements OnInit, OnDestroy {

    private _page = 1;
    private currentUser: User = null;
    public totalPages = 0;
    public totalOrganisations = 0;

    private isDestroyed: Subject<void> = new Subject<void>();
    private pageChanged: Subject<number> = new Subject<number>();
    notificationType: string;

    public organisations: Organisation[] = [];

    public get page(): number {
        return this._page;
    }

    public set page(value: number) {
        if (value !== this.page) {
            this._page = value;
            this.pageChanged.next(value);
        }
    }

    constructor(
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private userService: UserService,
        private router: Router,
    ) {
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next();
        this.menuService.setActive(null);
    }

    public ngOnInit(): void {
        this.menuService.setActive('organisations');

        this.organisationService.getOrganisations().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(organisations => {
            this.organisations = organisations;
        });

        this.organisationService.getTotalOrganisations().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((total) => this.totalOrganisations = total);

        this.organisationService.getCurrentPage().pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed)
        ).subscribe((currentPage) => this.page = currentPage);

        this.organisationService.getTotalPages().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe((totalPages) => this.totalPages = totalPages);

        this.pageChanged.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed),
        ).subscribe((page) => this.organisationService.fetchOrganisations(page));

        this.userService.getMe().subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                this.organisationService.fetchOrganisations();
            }
        });
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

}
