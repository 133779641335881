import { AbstractModel } from '../abstract.model';

export class CaseEvent extends AbstractModel {

    public id: string;
    public type: string;
    public sentAt: string;
    public conversation: string;

    public get sentAtDate(): Date {
        return this.sentAt ? new Date(this.sentAt) : null;
    }

    // public static mapFromApi(data: Partial<any>): Partial<CaseEvent> {
    //     if (!data) { return null; }
    //
    //     console.log('mapFromApi:', data);
    //
    //     return {
    //         id: data.id,
    //         type: data.type,
    //         sentAt: data.sent_at,
    //         conversation: data.conversation,
    //     };
    // }

    public fill(data: Partial<CaseEvent>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.sentAt !== undefined) {
            this.sentAt = data.sentAt;
        }
        if (data.conversation !== undefined) {
            this.conversation = data.conversation;
        }
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            sentAt: this.sentAt,
            conversation: this.conversation,
        };
    }

    // public fillFromApi(data: Partial<CaseEvent>): this {
    //     this.fill(CaseEvent.mapFromApi(data));
    //     return this;
    // }
}
