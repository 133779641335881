import { AbstractModel } from './abstract.model';

export class CaseResolvedBreakdown extends AbstractModel {
    public key: string = null;
    public value: number = null;

    public fill(data: Partial<CaseResolvedBreakdown>) {
        if (data.key !== undefined) {
            this.key = data.key;
        }
        if (data.value !== undefined) {
            this.value = data.value;
        }
    }
}
