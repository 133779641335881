import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-connection-filter',
    templateUrl: './connection-filter.component.html',
    styleUrls: ['./connection-filter.component.scss']
})
export class ConnectionFilterComponent implements OnInit {

    public sortByOptions: any[] = [
        {
            key: 'a-z',
            label: 'A-Z (default)',
        },
        {
            key: 'newest',
            label: 'Newest',
        },
        {
            key: 'oldest',
            label: 'Oldest',
        }
    ];

    private _searchTerm: string = null;

    @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() get searchTerm() {
        return this._searchTerm;
    }

    set searchTerm(searchTerm: string) {
        this._searchTerm = searchTerm;
        this.searchTermChange.emit(this.searchTerm);
    }

    private _sortBy = 'a-z';
    public sortByLabel = 'A-Z (default)';

    @Output() sortByChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() get sortBy() {
        return this._sortBy;
    }

    set sortBy(sortBy: string) {
        this._sortBy = sortBy;
        this.sortByChange.emit(this.sortBy);
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    public selectOrder(sortBy: any) {
        this.sortBy = sortBy.key;
        this.sortByLabel = sortBy.label;
    }

}
