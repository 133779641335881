<div class="connection white-box">
    <div class="image profile-avatar" *ngIf="entity.image">
        <span class="profile-img" [style.backgroundImage]="entity.image | attachment: 'profile_small' | backgroundImage"></span>
    </div>
    <div class="image profile-avatar" *ngIf="!entity.image">
        <div class="profile-avatar-fallback">{{ entity.initials }}</div>
    </div>
    <h2 class="connection-title">
        {{ entity.name }}
    </h2>

    <div class="connection-info">
        <div class="connection-info-label">
            Address
        </div>
        <address>
            {{ entity.address.line_1 }} <br>
            {{ entity.address.line_2 }} <br *ngIf="entity.address.line_2">
            {{ entity.address.town }} <br>
            {{ entity.address.postcode }} <br>
        </address>
    </div>

    <div class="connection-info">
        <div class="connection-info-label">
            Primary Contact
        </div>
        <div class="connection-info-item">
            {{ entity.primary_contact.name }}
        </div>
    </div>

    <div class="connection-info">
        <div class="connection-info-label">
            Contact Number
        </div>
        <div class="connection-info-item">
            <a [href]="'tel:' + entity.primary_contact.phone_number">
                {{ entity.primary_contact.phone_number }}
            </a>
        </div>
    </div>

    <div class="connection-info">
        <div class="connection-info-label">
            Email
        </div>
        <div class="connection-info-item">
            <a [href]="'mailto:' + entity.primary_contact.email">
                {{ entity.primary_contact.email }}
            </a>
        </div>
    </div>

</div>
