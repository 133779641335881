<app-header></app-header>

<div class="container-fluid">
	<div class="row">

		<app-side-menu id="sidebarMenu" class="col-md-3 col-xl-2 d-md-block sidebar collapse"></app-side-menu>

		<main role="main" class="col-md-9 col-xl-10 ml-sm-auto px-md-4">
			<ng-container *ngIf="!organisation">
				Loading...
			</ng-container>
			<ng-container *ngIf="organisation">
				<app-breadcrumb>
					<li class="breadcrumb-item" (click)="goToPage('organisations')" *can="'view'; target:'organisations'">
						Organisations
					</li>
					<li class="breadcrumb-item" aria-current="page" (click)="goToPage('organisations/' + organisation.id)">
						{{ organisation.name }}
					</li>
					<ng-container *ngIf="branchId">
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/branches')">
							Locations
						</li>
						<li class="breadcrumb-item" aria-current="page"
							(click)="goToPage('organisations/' + organisationId + '/branches/' + branchId)">
							{{ branch.name }}
						</li>
					</ng-container>
					<li class="breadcrumb-item active" aria-current="page">
						Connections
					</li>
				</app-breadcrumb>
				<header class="d-flex">
					<h1 class="h1">Connections</h1>
					<app-button class="active" routerLink="new">New Connection</app-button>
				</header>

				<app-flash-messages></app-flash-messages>

				<app-connections-component [organisation]="organisation"></app-connections-component>

			</ng-container>
		</main>

	</div>
</div>
