import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { MembersService } from '../members/members.service';
import { Branch } from '../../models/branch';
import { UserRole } from '../members/members';
import { environment } from '../../../environments/environment';
import { Organisation } from '../../models/organisation';
import { BranchesService } from '../branches/branches.service';
import { OrganisationsService } from '../organisations/organisations.service';
import { FlashMessageService } from '../../services/flash-message.service';
import { MenuService } from '../../services/menu.service';
import { CaseService } from '../cases/cases.service';
import { CaseStats } from '../../models/case-stats';
import { AttachmentService } from '../../services/attachment.service';

@Component({
    selector: 'app-member-profile',
    templateUrl: './member-profile.html',
    styleUrls: ['./member-profile.scss']
})
export class MemberProfilePage implements OnDestroy, OnInit, AfterViewInit {

    public branches: Branch[] = [];
    public organisations: Organisation[] = [];
    public userOrganisationID: string = null;
    public organisation: Organisation = null;
    public branch: Branch = null;
    public organisationId: string = null;
    public branchId: string = null;
    public userOrganisation: string = null;
    public userBranch: string[] = null;
    private currentUser: User = null;
    public caseStats: CaseStats = null;

    public isLoading = false;
    public memberProfile: User;

    private isDestroyed: Subject<void> = new Subject<void>();

    public notificationVisible = false;
    public notificationType: string = null;
    public notificationClass: string = null;

    private actionSheet: any;
    @ViewChild('imageInput', {static: false}) public imageUploadRef: ElementRef<HTMLInputElement>;
    @ViewChild('closeModal', {static: false}) public closeModal: ElementRef;
    private attachment: File = null;
    public imageInput: HTMLInputElement;

    constructor(
        private attachmentService: AttachmentService,
        private branchService: BranchesService,
        private caseService: CaseService,
        private flashMessageService: FlashMessageService,
        private membersService: MembersService,
        private menuService: MenuService,
        private organisationService: OrganisationsService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.menuService.setActive('members');

        this.organisationService.getOrganisation().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(organisation => {
            this.organisations = [organisation];
            this.organisation = organisation;
        });

        this.branchService.getBranch().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(branch => {
            this.branch = branch;
        });

        this.caseService.getCaseStats().pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(stats => this.caseStats = stats);

        this.userService.getMe().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(me => {
            if (me && me !== this.currentUser) {
                this.currentUser = me;
                if (this.currentUser.organisation_id) {
                    this.userOrganisation = this.currentUser.organisation_id;
                }
            }
        });

        this.branchService.getAllBranches().pipe(
            takeUntil(this.isDestroyed),
        ).subscribe(branches => {
            this.branches = branches;
        });

        this.route.params.pipe(
            takeUntil(this.isDestroyed)
        ).subscribe(params => {
            if (params.hasOwnProperty('organisation_id') && params.hasOwnProperty('branch_id')) {
                this.branchId = params.branch_id;
                this.organisationId = params.organisation_id;
                this.branchService.fetchBranch(this.organisationId, this.branchId);
                this.branchService.fetchAllBranches(this.organisationId);
            } else if (params.hasOwnProperty('organisation_id')) {
                this.organisationId = params.organisation_id;
            }

            if (params.hasOwnProperty('member_id')) {
                this.membersService.fetchMemberBranches(params.member_id).then(branches => {
                    this.userBranch = branches;
                });
                this.membersService.fetchMember(params.member_id).then(member => {
                    this.memberProfile = member;

                    if (this.memberProfile.organisation_id) {
                        this.organisationService.fetchOrganisation(this.memberProfile.organisation_id);
                        this.branchService.fetchAllBranches(this.memberProfile.organisation_id);

                        this.caseService.searchCases(1, {
                            organisationId: this.memberProfile.organisation_id,
                            userId: this.memberProfile.id,
                            caseStatus: 'all',
                            type: 'all',
                        });
                    }
                }).finally(() => this.isLoading = false);
            }
        });
    }

    public ngOnDestroy(): void {
        this.menuService.setActive(null);
        this.isDestroyed.next();
    }

    public removeMember() {
        this.userService.removeMember(this.memberProfile.id).then(response => {
            if (response.deleted) {
                this.flashMessageService.addMessage('success', 'Member successfully removed!');
                this.router.navigate(['members']);
            } else {
                this.flashMessageService.showMessage('error', 'Error occurred deleting the member.');
            }
        });
    }

    public async resetPassword() {
        try {
            const response = await this.userService.resetPassword(this.memberProfile.id);
            if (response.success) {
                this.flashMessageService.showMessage('success', 'A temporary password has been emailed to the user.');
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred issuing the temporary password.');
        }
    }

    public async saveChanges() {
        this.userService.editMember(this.memberProfile.id, this.memberProfile, this.userBranch).then(response => {
            if (response.error) {
                this.flashMessageService.showMessage('error', 'An error occurred while updating this user.');
                return;
            }

            this.flashMessageService.showMessage('success', 'Member successfully updated!');
        });
    }

    public toggleAssignCases() {
        this.memberProfile.can_assign_cases = !this.memberProfile.can_assign_cases;
    }

    public toggleViewCaseInfo() {
        this.memberProfile.has_full_case_access = !this.memberProfile.has_full_case_access;
    }

    public toggleIsActive() {
        if (this.memberProfile.isActive) {
            this.memberProfile.state = 'suspended';
        } else {
            this.memberProfile.state = 'active';
        }
    }

    public get userRoles(): UserRole[] {
        const roles: UserRole[] = [];

        if (this.currentUser) {
            for (const role of environment.user_roles) {
                if (this.currentUser.can('seeUserRole', role)) {
                    // tslint:disable-next-line:max-line-length
                    if ((!this.memberProfile.organisation_id && role.level >= 40) || (this.memberProfile.organisation_id && role.level < 40)) {
                        roles.push(role);
                    }
                }
            }
        }

        return roles;
    }

    public goToPage(url: any) {
        this.router.navigate([url]);
    }

    public get isReady() {
        if (!this.memberProfile) {
            return false;
        }

        if (!!this.branchId && !!this.memberProfile.organisation_id) {
            return !!this.memberProfile && !!this.organisation && !!this.branch;
        } else {
            return !!this.memberProfile;
        }
    }

    public get userRole(): UserRole {
        return this.userRoles.find(role => role.key === this.memberProfile?.role);
    }

    public isMedicComs(): boolean {
        if (this.userRole?.level >= 40) {
            return true;
        }
        return false;
    }

    public isEndUser() {
        return !this.isMedicComs();
    }

    public async onImageAdded() {
        if (this.actionSheet) {
            this.actionSheet.dismiss();
        }
        this.attachment = this.imageInput.files[0];

        try {
            this.memberProfile.image = await this.attachmentService.uploadImage(this.attachment);
            if (this.closeModal.nativeElement) {
                this.closeModal.nativeElement.click();
            }
        } catch (e) {
            this.flashMessageService.showMessage('error', 'An error occurred uploading the image.');
        }
    }

    public async clickImageUpload() {
        this.selectPhoto();
    }

    private selectPhoto() {
        this.imageInput.click();
    }

    public ngAfterViewInit() {
        this.imageInput = this.imageUploadRef.nativeElement;
    }
}
