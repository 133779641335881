import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPage } from './pages/login/login';
import { DashboardPage } from './pages/dashboard/dashboard';
import { BranchesPage } from './pages/branches/branches';
import { BranchPage } from './pages/branch/branch';
import { ConnectionPage } from './pages/connection/connection';

import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectionsPage } from './pages/connections/connections';
import { CasesPage } from './pages/cases/cases';
import { CasePage } from './pages/case/case';
import { MessagesPage } from './pages/messages/messages';
import { ApiClientModule } from '@scaffold/mediccoms-api-client';
import { PipesModule } from './pipes/pipes.module';
import { ServicesModule } from './services/services.module';
import { FormsModule } from '@angular/forms';
import { AddBranchPage } from './pages/add-branch/add-branch';
import { AccountPage } from './pages/account/account';
import { MembersModule } from './pages/members/members.module';
import { ComponentsModule } from './components/components.module';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password';
import { DirectivesModule } from './directives/directives.module';
import { OrganisationsPage } from './pages/organisations/organisations';
import { OrganisationsViewPage } from './pages/organisations/view/organisations-view';
import { OrganisationsCreatePage } from './pages/organisations/create/organisations-create';
import { ReportFiltersComponent } from './components/report-filters/report-filters.component';
import { ReportStatsComponent } from './components/report-stats/report-stats.component';
import { ReportCaseTableComponent } from './components/report-case-table/report-case-table.component';
import { ReportCasesComponent } from './components/report-cases/report-cases.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { InvitePage } from './pages/invite/invite';
import { ConnectionOrganisationBranchComponent } from './components/connection-organisation-branch/connection-organisation-branch.component';
import { AddConnectionPage } from './pages/add-connection/add-connection';
import { InviteSendPage } from './pages/invite-send/invite-send';
import { ConnectionFilterComponent } from './components/connection-filter/connection-filter.component';
import { CaseMessengerComponent } from './components/case-messenger/case-messenger.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
    declarations: [
        AppComponent,
        LoginPage,
        ForgotPasswordPage,
        DashboardPage,
        BranchesPage,
        BranchPage,
        ConnectionPage,
        ConnectionsPage,
        AddBranchPage,
        AccountPage,
        CasesPage,
        CasePage,
        MessagesPage,
        OrganisationsPage,
        OrganisationsViewPage,
        OrganisationsCreatePage,
        InvitePage,
        InviteSendPage,
        AddConnectionPage,
        ReportFiltersComponent,
        ReportStatsComponent,
        ReportCaseTableComponent,
        ReportCasesComponent,
        ConnectionsComponent,
        ConnectionOrganisationBranchComponent,
        ConnectionFilterComponent,
        CaseMessengerComponent,
    ],
    imports: [
        BrowserModule,
        ApiClientModule,
        AppRoutingModule,
        ChartsModule,
        ComponentsModule,
        DirectivesModule,
        BrowserAnimationsModule,
        MatTabsModule,
        NgSelectModule,
        PipesModule,
        ServicesModule,
        FormsModule,
        MembersModule,
        NgxGoogleAnalyticsModule.forRoot('G-K2X733W61C'),
        NgxGoogleAnalyticsRouterModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
