import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPage } from './pages/login/login';
import { DashboardPage } from './pages/dashboard/dashboard';
import { MembersPage } from './pages/members/members';
import { MemberProfilePage } from './pages/member-profile/member-profile';
import { AddMemberPage } from './pages/add-member/add-member';
import { BranchesPage } from './pages/branches/branches';
import { BranchPage } from './pages/branch/branch';
import { ConnectionPage } from './pages/connection/connection';
import { ConnectionsPage } from './pages/connections/connections';
import { CasesPage } from './pages/cases/cases';
import { CasePage } from './pages/case/case';
import { MessagesPage } from './pages/messages/messages';
import { AddBranchPage } from './pages/add-branch/add-branch';
import { AccountPage } from './pages/account/account';
import { GuestGuard } from './guards/guest.guard';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password';
import { OrganisationsPage } from './pages/organisations/organisations';
import { OrganisationsViewPage } from './pages/organisations/view/organisations-view';
import { OrganisationsCreatePage } from './pages/organisations/create/organisations-create';
import { AdminGuard } from './guards/admin.guard';
import { SystemUserGuard } from './guards/system-user.guard';
import { InvitePage } from './pages/invite/invite';
import { AddConnectionPage } from './pages/add-connection/add-connection';
import { InviteSendPage } from './pages/invite-send/invite-send';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        component: LoginPage,
        canActivate: [GuestGuard],
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        canActivate: [GuestGuard],
    },
    {
        path: 'dashboard',
        component: DashboardPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations',
        component: OrganisationsPage,
        canActivate: [AuthGuard, AdminGuard, SystemUserGuard],
    },
    {
        path: 'organisations/new',
        component: OrganisationsCreatePage,
        canActivate: [AuthGuard, AdminGuard, SystemUserGuard],
    },
    {
        path: 'organisations/:organisation_id',
        component: OrganisationsViewPage,
        canActivate: [AuthGuard, AdminGuard, SystemUserGuard],
    },
    {
        path: 'connections/invites/:invite_id/:token',
        component: InvitePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections',
        component: ConnectionsPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/new',
        component: AddConnectionPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/invites',
        redirectTo: 'organisations/:organisation_id/connections',
        canActivate: [AuthGuard, AdminGuard],
        pathMatch: 'full'
    },
    {
        path: 'organisations/:organisation_id/connections/invites/:invite_id',
        component: InvitePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/invites/:invite_id/send',
        component: InviteSendPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/invites/:invite_id/:token',
        component: InvitePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/invites/:invite_id/:token/send',
        component: InviteSendPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/connections/:connection_id',
        component: ConnectionPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/members/new',
        component: AddMemberPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/members/:member_id',
        component: MemberProfilePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches',
        component: BranchesPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/new',
        component: AddBranchPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id',
        component: BranchPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    // {
    //     path: 'organisations/:organisation_id/branches/:branch_id/connections',
    //     component: ConnectionsPage,
    //     canActivate: [AuthGuard, AdminGuard],
    // },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/connections/new',
        component: AddConnectionPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/connections/:connection_id',
        component: ConnectionPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/connections/invites/:invite_id/:token/send',
        component: InviteSendPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/connections/invites/:invite_id/send',
        component: InviteSendPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/members/new',
        component: AddMemberPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/members/:member_id',
        component: MemberProfilePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/branches/:branch_id/cases/:case_id',
        component: CasePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'members',
        component: MembersPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'members/new',
        component: AddMemberPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'members/:member_id',
        component: MemberProfilePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'account',
        component: AccountPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/cases',
        component: CasesPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/cases/:case_id',
        component: CasePage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'messages',
        component: MessagesPage,
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: 'organisations/:organisation_id/messages',
        component: MessagesPage,
        canActivate: [AuthGuard, AdminGuard, SystemUserGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        AdminGuard,
        AuthGuard,
        GuestGuard,
        SystemUserGuard,
    ]
})
export class AppRoutingModule {
}
